import { useQueryClient } from "@tanstack/react-query"
import Spinner from "components/Spinner/Spinner"
import { EhancedFile } from "components/UploadField/UploadFieldDrop"
import { useUploadFile } from "core/sharepoint/query-hooks/useFiles"
import {
  useCountFilesInFolder,
  useListFoldersFromParent,
} from "core/sharepoint/query-hooks/useFolders"
import { CreateSharepointFolder } from "features/sharepoint/CreateSharepointFolder"
import { SharepointBreadcrumb } from "features/sharepoint/SharepointBreadcrumb"
import { SharepointItemCard } from "features/sharepoint/SharepointItemCard"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FaRegFolder } from "react-icons/fa"
import handleFileUpload from "utils/uploadFiles"

export interface IOperationShareSpaceProps {
  sharepointFolderId: string
}

export default function OperationShareSpace(props: IOperationShareSpaceProps) {
  const { sharepointFolderId } = props
  const [currentFolderId, setCurrentFolderId] = useState<string>(
    sharepointFolderId!,
  )
  const mutation = useUploadFile()
  const [myFiles, setMyFiles] = useState<EhancedFile[]>([])
  const queryClient = useQueryClient()
  const [breadcrumb, setBreadcrumb] = useState<
    { id: string; name: React.ReactNode }[]
  >([{ id: sharepointFolderId!, name: <FaRegFolder /> }])

  const {
    data: sharepointItems,
    isLoading,
    error,
  } = useListFoldersFromParent(currentFolderId)

  const {
    data: totalFileInFolder,
    isLoading: isFileCountLoading,
    isError: isFileCountError,
  } = useCountFilesInFolder(currentFolderId)

  const handleChangeCurrentFolderId = (id: string, name: string) => {
    setCurrentFolderId(id)
    setBreadcrumb((prev) => [...prev, { id, name }])
  }

  const handleBreadcrumbClick = (index: number) => {
    const selectedFolder = breadcrumb[index]
    setCurrentFolderId(selectedFolder.id)
    setBreadcrumb(breadcrumb.slice(0, index + 1))
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setMyFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
    noDragEventsBubbling: true,
  })

  useEffect(() => {
    if (myFiles.length > 0) {
      const onSuccess = () => {
        setMyFiles([])
      }

      handleFileUpload(
        myFiles,
        currentFolderId,
        mutation,
        queryClient,
        t,
        onSuccess,
      )
    }
  }, [myFiles])

  if (error) {
    return (
      <div className="flex items-center justify-between p-2">
        {t("error-loading-sharepoint-items")}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{
          height: "400px",
        }}
      >
        <Spinner />
      </div>
    )
  }

  if (sharepointItems && sharepointItems.length > 0) {
    return (
      <>
        <div className="flex items-center justify-between mb-3 rounded-lg p-2 bg-sky-50">
          <div className="flex item-center">
            <SharepointBreadcrumb
              breadcrumb={breadcrumb}
              onBreadcrumbClick={handleBreadcrumbClick}
            />
            {breadcrumb.length > 1 && (
              <span className="pl-4">
                {isFileCountError && (
                  <span className="text-danger">
                    {t("file-count-in-folder-error")}
                  </span>
                )}
                {isFileCountLoading ? <Spinner /> : ` (${totalFileInFolder})`}
              </span>
            )}
          </div>
          {breadcrumb.length >= 3 && (
            <CreateSharepointFolder currentFolderId={currentFolderId} />
          )}
        </div>
        <div>
          {sharepointItems.map((item) => (
            <SharepointItemCard
              key={item.id}
              item={item}
              handleChangeCurrentFolderId={() =>
                handleChangeCurrentFolderId(item.id, item.name)
              }
              currentFolderId={currentFolderId}
              sharepointFolderId={sharepointFolderId}
              isDroppable={breadcrumb && breadcrumb.length >= 2}
              isDraggable={breadcrumb && breadcrumb.length >= 3}
            />
          ))}
        </div>
        {breadcrumb.length >= 3 && (
          <div
            className="flex items-center justify-center p-2 border-solid border-2 border-grey-500"
            {...getRootProps({
              onClick: (e) => {
                e.preventDefault()
                e.stopPropagation()
              },
            })}
            style={{
              transition: "background-color 0.5s ease-in-out",
              backgroundColor: isDragActive ? "#B0C02A" : `transparent`,
              height: "100px",
            }}
          >
            <input {...getInputProps()} />
            <p>{t("file-add")}</p>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <div className="flex item-center">
          <SharepointBreadcrumb
            breadcrumb={breadcrumb}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
          {breadcrumb.length > 1 && (
            <>
              {isFileCountError && (
                <span className="text-danger">
                  {t("file-count-in-folder-error")}
                </span>
              )}
              {isFileCountLoading ? (
                <Spinner />
              ) : (
                <span className="pl-4">{` (${totalFileInFolder})`}</span>
              )}
            </>
          )}
        </div>
        {breadcrumb.length >= 3 && (
          <CreateSharepointFolder currentFolderId={currentFolderId} />
        )}
      </div>

      <div
        className="flex items-center justify-center p-2"
        {...getRootProps({
          onClick: (e) => {
            e.preventDefault()
            e.stopPropagation()
          },
        })}
        style={{
          transition: "background-color 0.5s ease-in-out",
          backgroundColor: isDragActive ? "#B0C02A" : `transparent`,
          height: "400px",
        }}
      >
        <input {...getInputProps()} />
        <p>{t("empty-folder")}</p>
      </div>
    </>
  )
}
