import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import {
  useListBuildingPermitDocuments,
  useListBuildingPermitLabel,
  useListBuildingPermits,
  useListBuildingPermitStep,
} from "core/query-hooks/useBuildingPermits"
import { fr } from "date-fns/locale"
import { BuildingPermitRow } from "features/buildingPermit/BuildingPermitRow"
import { t } from "i18next"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import { RiCompasses2Line } from "react-icons/ri"
import { TIdLabel } from "shared/types/global.type"
import { TChildOperation } from "shared/types/operation.type"
import AddModifyingPermit from "./AddModifyingPermit"
import { EditBuildingPermitDate } from "./EditBuildingPermitDate"

registerLocale("fr", fr)

interface TOperationChildItemBuildingPermitProps {
  childOperation: TChildOperation
}

export default function OperationChildrenItemBuildingPermit(
  props: TOperationChildItemBuildingPermitProps,
) {
  const { childOperation } = props
  const [displayLabel, setDisplayLabel] = useState<TIdLabel[]>([])

  const {
    data: permits,
    error: errorPermits,
    isLoading: isLoadingPermits,
  } = useListBuildingPermits(childOperation.id)

  const {
    data: buildingPermitSteps,
    error,
    isLoading,
  } = useListBuildingPermitStep()

  const {
    data: buildingPermitLabels,
    error: errorPermitsLabel,
    isLoading: isLoadingPermitLabel,
  } = useListBuildingPermitLabel()

  const { data: permitsDocuments } = useListBuildingPermitDocuments(
    childOperation.id,
  )

  if (isLoadingPermits || isLoading || isLoadingPermitLabel) return <Spinner />

  if (errorPermits || error || errorPermitsLabel)
    return <p>{t("error-loading-date")}</p>

  return (
    <div>
      <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-2">
        <div className="col-span-4">
          <Box classNames="p-0 overflow-auto">
            <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
              <div className="flex items-center text-cyan-900 mr-1">
                <RiCompasses2Line size={24} />
                <h3 className="text-xs uppercase font-medium pl-1">
                  {t("permit-title")}
                </h3>
              </div>
              <AddModifyingPermit
                childOpId={childOperation.id}
                setDisplayLabel={setDisplayLabel}
                permits={permits ?? []}
              />
            </div>

            <table className="w-max">
              <thead>
                <tr className="text-sm text-primary-light font-semibold">
                  <th className="px-4 py-4 w-72"> </th>
                  {displayLabel.map((label) => (
                    <th key={label.id} className="text-start w-40 px-2 py-4">
                      {label.label}
                    </th>
                  ))}
                  <th className="text-start px-2 py-4">
                    {t("supporting-documents")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {buildingPermitSteps && buildingPermitSteps.length > 0
                  ? buildingPermitSteps.map((step) => {
                      const documents = permitsDocuments?.find(
                        (doc) => doc.buildingPermitStepId === step.id,
                      )

                      return (
                        <tr key={step.id}>
                          <td className="text-start pl-3 pb-3">{step.label}</td>
                          {displayLabel.map((label) => {
                            const value = permits?.find(
                              (item) =>
                                item.buildingPermitLabel.id === label.id &&
                                item.buildingPermitStep.label === step.label,
                            )

                            const buildingPermitLabel =
                              buildingPermitLabels?.find(
                                (item) => item.id === label.id,
                              )
                            return (
                              <td key={label.id} className="group">
                                {buildingPermitLabel && (
                                  <EditBuildingPermitDate
                                    buildingPermit={value}
                                    buildingPermitLabel={buildingPermitLabel}
                                    buildingPermitStep={step}
                                    childOperationId={childOperation.id}
                                  />
                                )}
                              </td>
                            )
                          })}
                          {step && (
                            <GedAxiosInterceptor>
                              <BuildingPermitRow
                                buildingPermitStep={step}
                                childOperation={childOperation}
                                buildingPermitDocuments={documents}
                              />
                            </GedAxiosInterceptor>
                          )}
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </table>
          </Box>
        </div>
      </div>
    </div>
  )
}
