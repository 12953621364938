import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useGetAppelDefondList } from "core/spo/query-hooks/useAppelDeFond"
import { t } from "i18next"
import TooltipWarning from "components/Tooltip/TooltipWaring"
import { IoDocumentText } from "react-icons/io5"
import useModal from "components/Modal/useModal"
import { RiErrorWarningFill } from "react-icons/ri"
import { useGetDocumentsByType } from "core/ged/query-hooks/useGed"
import { useState } from "react"
import { TGedUploadResponse } from "shared/types/ged.type"
import {
  MAX_GLOBAL_SUPPORTING_DOCUMENTS,
  SUPPORTING_DOCUMENTS_DOCUMENT_TYPE,
} from "shared/resources/fundRaisingAppeal.resources"
import { useParams } from "react-router-dom"
import useDeleteDocument from "core/query-hooks/useDocuments"
import { toast } from "react-toastify"
import { useQueryClient } from "@tanstack/react-query"
import { TChildOperation } from "shared/types/operation.type"
import AddProductionDocumentsModal from "features/ged/AddProductionDocumentsModal"
import { ProductionTrackingRow } from "./ProductionTrackingRow"

export interface IProductionTrackingTableProps {
  trancheTravaux: string
  lessor: string | null
  childOperation: TChildOperation
}

export function ProductionTrackingTable(props: IProductionTrackingTableProps) {
  const { id } = useParams()
  const { trancheTravaux, lessor, childOperation } = props
  const { toggle, isShowing } = useModal()
  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])
  const deleteDocument = useDeleteDocument()
  const queryClient = useQueryClient()

  const {
    data: appelsDeFond,
    isLoading,
    error,
  } = useGetAppelDefondList(trancheTravaux, lessor ?? "")

  const { data: documents } = useGetDocumentsByType(
    id!,
    SUPPORTING_DOCUMENTS_DOCUMENT_TYPE.label,
  )

  const hasSupportingDocumentWarning = appelsDeFond?.some(
    (appelDeFond) => appelDeFond.isSupportingDocument === true,
  )

  const headers = [
    `${t("production-task-name")}`,
    `${t("production-end-date")}`,
    `${t("production-certificates")}`,
    `${t("production-supporting-documents")}`,
    `${t("production-call-for-funds")}`,
  ]

  if (isLoading) {
    return (
      <div className="w-full flex justify-center py-4">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-48">
        <p>{t("an-error-has-occurred")}</p>
      </div>
    )
  }

  if (!appelsDeFond || appelsDeFond.length === 0) {
    return (
      <div className="flex items-center justify-center h-48">
        <p>{t("no-data")}</p>
      </div>
    )
  }

  const handleDeleteDocument = async (documentId: number) => {
    deleteDocument.mutateAsync(documentId, {
      onSuccess() {
        setFormFiles((prevFiles) => {
          return prevFiles.filter((file) => file.id !== documentId)
        })
        queryClient.invalidateQueries({
          queryKey: [
            "getDocumentsByType",
            id!,
            SUPPORTING_DOCUMENTS_DOCUMENT_TYPE.label,
          ],
        })
        toast.success(t("file-deleted"))
      },
      onError() {
        toast.error(t("file-deleted-error"))
      },
    })
  }

  return (
    <div className="Light_Table__Wrapper">
      <table className="table-auto Table">
        <thead>
          <tr className="text-left">
            {headers.map((header, index) => (
              <th
                className={classNames(
                  "Table__Head Table__Top_Border w-36 text-left",
                  index === 1 && "w-10 text-left",
                )}
                key={header}
              >
                <div className="flex items-center">
                  {header}
                  {index === 3 && hasSupportingDocumentWarning && (
                    <TooltipWarning
                      message={`${t("suporting-document-ask")}`}
                      position="center"
                    >
                      <RiErrorWarningFill
                        className="ml-2"
                        color="red"
                        size={20}
                      />
                    </TooltipWarning>
                  )}
                  {index === 3 && (
                    <IoDocumentText
                      size={25}
                      color="#0B6CFF"
                      role="button"
                      onClick={() => toggle()}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {appelsDeFond.map((appelDeFond) => (
            <GedAxiosInterceptor>
              <ProductionTrackingRow
                fundRaisingAppeal={appelDeFond}
                trancheTravauxId={trancheTravaux}
                lessor={lessor}
              />
            </GedAxiosInterceptor>
          ))}
        </tbody>
      </table>
      <AddProductionDocumentsModal
        titleModal={t("production-supporting-documents")}
        documentTypes={[SUPPORTING_DOCUMENTS_DOCUMENT_TYPE.label]}
        filesToDisplay={documents}
        handleDeleteDocument={handleDeleteDocument}
        isModalShowed={isShowing}
        toggleModal={toggle}
        setFiles={setFormFiles}
        files={formFiles}
        registrationNumber={childOperation.registrationNumber}
        maxFiles={MAX_GLOBAL_SUPPORTING_DOCUMENTS}
      />
    </div>
  )
}
