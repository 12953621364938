import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useCreateTask, useDeleteTask } from "core/query-hooks/useTasks"
import { format } from "date-fns"
import { t } from "i18next"
import { useState } from "react"
import { FaChevronDown, FaChevronUp, FaRegBell } from "react-icons/fa"
import { MdCalendarToday } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TTask } from "shared/types/task.type"
import AddTaskModal from "./AddTaskModal"
import EditTaskModal from "./EditTaskModal"

export interface ITasksListProps {
  tasks: TTask[]
}

export function TasksList(props: ITasksListProps) {
  const { tasks } = props
  const { agreementId } = useParams()
  const addTaskMutation = useCreateTask()
  const deleteTaskMutation = useDeleteTask()
  const queryClient = useQueryClient()
  const [displayTasks, setDisplayyTasks] = useState<boolean>(false)

  function handleChangeDisplay() {
    setDisplayyTasks(!displayTasks)
  }

  const onCreateTask = (
    label: string,
    deadline: Date,
    isDefinitive: boolean,
    comment?: string,
    reminder?: Date,
  ) => {
    addTaskMutation.mutate(
      {
        label,
        comment,
        reminder,
        deadline,
        isDefinitive,
        agreementId: +agreementId!,
      },
      {
        onSuccess: () => {
          toast.success(`${t("toast-task-create-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listTasksByAgreementId", agreementId],
          })
        },
        onError: (err) =>
          toast.error(
            `${t("toast-task-create-error")},
            ${err.response?.data.error.message}`,
          ),
      },
    )
  }

  const deleteTask = (id: number) => {
    deleteTaskMutation.mutate(
      {
        id,
      },
      {
        onSuccess: () => {
          toast.success(`${t("toast-task-delete-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listTasksByAgreementId", agreementId],
          })
        },
        onError: (err) =>
          toast.error(
            `${t("toast-task-delete-error")},
            ${err.response?.data.error.message}`,
          ),
      },
    )
  }

  const refetchList = () => {
    queryClient.invalidateQueries({
      queryKey: ["listTasksByAgreementId", agreementId],
    })
  }

  return (
    <Box classNames="py-4 px-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Header size="h2" classNames="pt-2 mb-2">
            {`${t("tasks")}`}
          </Header>
          <div
            className="cursor-pointer ml-4"
            onClick={() => handleChangeDisplay()}
          >
            {displayTasks ? (
              <div className="flex items-center">
                <ButtonIcon>
                  <div className="mr-1 text-primary-dark text-sm">
                    {t("hide")}
                  </div>
                  <FaChevronUp />
                </ButtonIcon>
              </div>
            ) : (
              <ButtonIcon>
                <div className="flex items-center">
                  <div className="mr-1 text-primary-dark text-sm">
                    {t("show")}
                  </div>
                  <FaChevronDown />
                </div>
              </ButtonIcon>
            )}
          </div>
        </div>

        <QueryBoundaries loadingComponent={<Spinner />}>
          <PermissionsGuard requiredRoles={["create:task"]}>
            <AddTaskModal
              addTask={(label, isDefinitive, deadline, comment, reminder) =>
                onCreateTask(label, deadline!, isDefinitive, comment, reminder!)
              }
            />
          </PermissionsGuard>
        </QueryBoundaries>
      </div>

      {displayTasks ? (
        <>
          {tasks && tasks.length > 0 ? (
            tasks?.map((task: TTask) => (
              <Box classNames="mb-1 py-4 px-6" key={`project-task-${task.id}`}>
                <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
                  <div>
                    <EditTaskModal
                      task={task}
                      deleteTask={(id: number) => deleteTask(id)}
                      refetch={() => refetchList()}
                    />
                  </div>
                  <div className="flex items-center flex-wrap md:flex-nowrap flex-col md:flex-row">
                    <div className="flex items-center w-24">
                      <TooltipWithHover message={`${t("reminder-date")}`}>
                        <FaRegBell size={16} color="#00304d" className="mr-1" />
                      </TooltipWithHover>
                      <p className="text-sm">
                        {task?.reminder
                          ? format(new Date(task.reminder), "dd/MM/yyyy")
                          : "-"}
                      </p>
                    </div>

                    <div className="flex items-center md:ml-4 w-24">
                      <TooltipWithHover message={`${t("deadline")}`}>
                        <MdCalendarToday
                          color="#00304d"
                          size={16}
                          className="mr-1"
                        />
                      </TooltipWithHover>
                      <p>
                        <DateFormat
                          isDefinitive={task.isDefinitive}
                          date={task.deadline}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-xs">{task?.comment}</p>
                </div>
              </Box>
            ))
          ) : (
            <p> {`${t("agreements-show-noTaskFoundMessage")}`}</p>
          )}
        </>
      ) : null}
    </Box>
  )
}
