import { API } from "core/api/axios"
import {
  TChildOperation,
  TCommunicationType,
  TCreateChildOperation,
  TOperation,
  TOperationChildRecords,
  TOperationData,
  TOperationIds,
  TOperationStep,
  TOperationStepHistory,
  TOperationsList,
  TOperationsNextThreeMonthsList,
  TRegisterProject,
  TUpdateChildOperation,
} from "shared/types/operation.type"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"
import { buildProjectUrl } from "./projects"

async function registerProject(
  projectId: number,
  data: TRegisterProject,
): Promise<TChildOperation> {
  const res = await API.post(`/projects/${projectId}/registration`, data)
  return res.data
}

async function listOperations(
  params?: URLSearchParams,
): Promise<TOperationsList> {
  const URL = buildProjectUrl(
    `${process.env.REACT_APP_JARVIS_URL}/operations`,
    params,
  )
  const res = await API.get(URL)
  return res.data
}

async function listOperationsIds(
  params?: URLSearchParams,
): Promise<TOperationIds> {
  const URL = buildProjectUrl(
    `${process.env.REACT_APP_JARVIS_URL}/operations/search/ids`,
    params,
  )
  const res = await API.get(URL)
  return res.data
}

async function listChildRecords(
  operationId?: string,
): Promise<TOperationChildRecords> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/operations/${operationId}/child-records`).then(
        (res) => res.data,
      )
}

async function getOperationById(id: number): Promise<TOperation> {
  const res = await API.get(`/operations/${id}`)
  return res.data
}

async function createChildOperation(
  childOperation: TCreateChildOperation,
): Promise<TOperation> {
  const res = await API.post(`/child-operation`, childOperation)
  return res.data
}

async function updateChildOperation(
  id: number,
  childOperation: Partial<TUpdateChildOperation>,
): Promise<TChildOperation> {
  const res = await API.patch(`/child-operation/${id}`, childOperation)
  return res.data
}

async function listChildOperations(
  operationId: number,
): Promise<TChildOperation[]> {
  const res = await API.get(`/child-operations?operation=${operationId}`)
  return res.data
}

async function listSuspensiveConditionsByOperationId(
  operationId: string,
): Promise<TSuspensiveCondition[]> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.post(
        `/suspensive-conditions?operation=${operationId}`,
        operationId,
      ).then((res) => res.data)
}

async function updateOperation(
  data: Partial<TOperationData>,
  operationId: number,
): Promise<TOperation> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.patch(`/operations/${operationId}/data`, data).then((res) => res.data)
}

async function updateOperationStep(
  data: TOperationStep,
  operationId: number,
): Promise<TOperation> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.patch(`/operation/${operationId}/step`, data).then((res) => res.data)
}

async function listCommunicationTypes(): Promise<TCommunicationType[]> {
  const res = await API.get(`/child-operations/communication-types`)
  return res.data
}

async function getOperationStepHistory(
  operationId: number,
): Promise<TOperationStepHistory[]> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.get(`/operations/${operationId}/step-history`).then((res) => res.data)
}

async function listOperationsDataNextThreeMonths(
  businessUnitId?: number,
): Promise<TOperationsNextThreeMonthsList> {
  return API.get(
    `/operations/data/next-three-months${
      businessUnitId ? `?businessUnit=${businessUnitId}` : ""
    }`,
  ).then((res) => res.data)
}

async function listOperationsCommitteesNextThreeMonths(
  businessUnitId?: number,
): Promise<TOperationsNextThreeMonthsList> {
  return API.get(
    `/operations/committees/next-three-months${
      businessUnitId ? `?businessUnit=${businessUnitId}` : ""
    }`,
  ).then((res) => res.data)
}

async function listOperationsDpcNextThreeMonths(
  businessUnitId?: number,
  dpcDate?: Date,
): Promise<TOperationsNextThreeMonthsList> {
  return API.get(
    `/operations/dpc/next-three-months?isDpcDateDefinitive=false${
      businessUnitId ? `&businessUnit=${businessUnitId}` : ""
    }${dpcDate ? `&dpcDate=${dpcDate}` : ""}`,
  ).then((res) => res.data)
}

async function getChildOperation(
  childOperationId: number,
): Promise<TChildOperation> {
  const res = await API.get(`/child-operations/${childOperationId}`)
  return res.data
}

async function sendCertificateDepositMail(
  operationId: number,
  termLabel: string,
): Promise<void> {
  return typeof operationId === "undefined"
    ? Promise.reject(new Error("Invalid id"))
    : API.post(`/operations/${operationId}/send-certificate-deposit-mail`, {
        termLabel,
      }).then((res) => res.data)
}

export {
  createChildOperation,
  getChildOperation,
  getOperationById,
  getOperationStepHistory,
  listChildOperations,
  listChildRecords,
  listCommunicationTypes,
  listOperations,
  listOperationsCommitteesNextThreeMonths,
  listOperationsDataNextThreeMonths,
  listOperationsDpcNextThreeMonths,
  listOperationsIds,
  listSuspensiveConditionsByOperationId,
  registerProject,
  updateChildOperation,
  updateOperation,
  updateOperationStep,
  sendCertificateDepositMail,
}
