import { QueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"

const handleFileUpload = async (
  acceptedFiles: File[],
  folderId: string,
  mutation: any,
  queryClient: QueryClient,
  t: (key: string) => string,
  onSuccess: () => void,
) => {
  const MAX_SIZED_BYTES = 100000000
  const UNAUTHORIZED_EXTENSIONS = ["exe", "bat", "sh"]
  const authorizedFiles: File[] = []

  acceptedFiles.forEach((file) => {
    if (file.size > MAX_SIZED_BYTES) {
      toast.error(`${t("file-too-big-error")} : ${file.name}`)
      return
    }

    const fileExtension = file.name.split(".").pop() ?? ""
    if (UNAUTHORIZED_EXTENSIONS.includes(fileExtension)) {
      toast.error(`${t("unauthorized-file-extension")} : ${file.name}`)
      return
    }

    authorizedFiles.push(file)
  })

  if (authorizedFiles.length > 0) {
    try {
      await Promise.all(
        authorizedFiles.map(async (file) => {
          const data = {
            fileName: file.name,
            fileContent: file,
            folderId,
          }

          await mutation.mutateAsync(data, {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ["listFoldersFromParent", folderId],
              })
              queryClient.invalidateQueries({
                queryKey: ["countFilesInFolder", folderId],
              })
              toast.success(t("sharepoint-add-document-succes"))
            },
            onError: (error: { response: { status: number } }) => {
              if (error.response?.status === 409) {
                toast.error(`${t("file-already-exists-error")} : ${file.name}`)
              } else {
                toast.error(t("sharepoint-add-document-error"))
              }
            },
          })
        }),
      )
      onSuccess()
    } catch (error) {
      toast.error(t("sharepoint-add-document-error"))
    }
  }
}

export default handleFileUpload
