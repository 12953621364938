import classNames from "classnames"
import { Dispatch, SetStateAction } from "react"
import "./Toggle.scss"

interface IToggleStandAloneProps {
  className?: string
  leftlabel: string
  rightlabel: string
  checked?: boolean
  onChange: Dispatch<SetStateAction<boolean | string>>
}

export default function ToggleStandAlone(props: IToggleStandAloneProps) {
  const { leftlabel, rightlabel, className, checked, onChange } = props
  return (
    <div className="Toggle__Container">
      <div className="Toggle__Input_Container">
        <input
          id="radio_left"
          aria-label={leftlabel}
          className={classNames(["Text__Field", className])}
          {...props}
          type="radio"
          checked={checked}
          value={leftlabel}
          onChange={(e) => {
            onChange(e.target.value)
          }}
        />
        <label className="Left__Radio" htmlFor="radio_left">
          <span>{leftlabel}</span>
        </label>
      </div>
      <div className="Toggle__Input_Container">
        <input
          id="radio_right"
          value={rightlabel}
          className={classNames(["Text__Field Right__Radio", className])}
          {...props}
          type="radio"
          checked={!checked}
          onChange={(e) => {
            onChange(e.target.value)
          }}
        />
        <label className="Right__Radio" htmlFor="radio_right">
          <span>{rightlabel}</span>
        </label>
      </div>
    </div>
  )
}
