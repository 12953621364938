import classNames from "classnames"
import Container from "components/Container/Container"
import ArrowLeft from "components/Icons/ArrowLeft"
import Committee from "components/Icons/Committee"
import Info from "components/Icons/Info"
import { LinkCustom } from "components/Link/Link"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import SpoAxiosInterceptor from "core/interceptors/SpoInterceptor"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useGetOperationById,
  useListChildOperations,
} from "core/query-hooks/useOperations"
import { CreateOperationChildModal } from "features/operations/CreateOperationChildModal"
import OperationsNavigation from "features/operations/navigation/OperationsNavigation"
import PinProject from "features/project/PinProject"
import { SncSccv } from "features/spo/SncSccv"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiHighlight } from "react-icons/bi"
import { FaBuilding, FaPencilRuler, FaRegFolderOpen } from "react-icons/fa"
import { MdBalance, MdEuroSymbol, MdSell } from "react-icons/md"
import { TbCrane } from "react-icons/tb"
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { TOperationsList } from "shared/types/operation.type"
import { OperationOverviewModal } from "./OperationOverviewModal"
import AbandonOperation from "./abandon/AbandonOperation"

export default function OperationPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const { data: operationData, isLoading, error } = useGetOperationById(+id!)
  const [stickyClass, setStickyClass] = useState("")
  const location = useLocation()
  const operations = location.state?.operations as TOperationsList[]
  const { data: operationsChildren } = useListChildOperations(+id!)
  const backUrl = localStorage.getItem("url")

  const businessUnitTab: string[] = operationsChildren
    ? Array.from(
        new Set(
          operationsChildren.map((childOpe) => childOpe.businessUnit?.label),
        ),
      )
    : []

  const businessUnitStr: string = businessUnitTab.join(" - ")

  const stickNavbar = () => {
    if (window) {
      const windowHeight = window.scrollY
      const maxHeightScroll =
        document.documentElement.scrollHeight - window.innerHeight
      if (
        windowHeight > 0 &&
        maxHeightScroll > ref.current?.clientHeight! &&
        maxHeightScroll > 180
      ) {
        setStickyClass(
          "fixed top-15 mt-3 z-20 Background__White w-screen Max_Width",
        )
      } else {
        setStickyClass("relative")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar)
    return () => window.removeEventListener("scroll", stickNavbar)
  }, [])

  useEffect(() => {
    if (operationData?.operationBase.intranetUrlSci5) {
      window.location.href = operationData.operationBase.intranetUrlSci5
    }
  }, [operationData])

  return (
    <div style={{ width: "auto" }}>
      {isLoading && <Spinner />}
      {operationData && operationData.operationBase && (
        <QueryBoundaries loadingComponent={<Spinner />}>
          <div ref={ref}>
            <Container
              backgroundColor="white"
              classNames={classNames("Border__Bottom_LightGrey", stickyClass)}
            >
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <LinkCustom
                  classNames="Padding__Left_16 pt-2"
                  icon={ArrowLeft}
                  iconSize={16}
                  iconPosition="left"
                  onClick={() => navigate(`/programs/search?${backUrl}`)}
                >
                  {t("back-to-research")}
                </LinkCustom>
              </div>

              <div className="p-1 flex flex-wrap justify-center items-center overflow-auto">
                {operationData.operationBase.registrationNumbers?.map(
                  (immat) => (
                    <span
                      className="py-1 px-2 rounded-full bg-white text-primary-dark border border-gray-500 text-sm m-0.5"
                      key={immat}
                    >
                      {immat}
                    </span>
                  ),
                )}

                <PermissionsGuard requiredRoles={["create:child-operation"]}>
                  <div className="flex items-center m-0.5">
                    <CreateOperationChildModal
                      projectId={+id!}
                      businessUnitId={
                        operationData.operationBase?.businessUnit.id
                      }
                    />
                  </div>
                </PermissionsGuard>
              </div>

              <div className="flex md:flex-row flex-col-reverse justify-center md:items-center items-center w-full pb-3">
                <div className="flex items-center">
                  <h2 className="text-primary-font text-center font-semibold text-md sm:text-2xl relative p-1">
                    <div className="flex flex-col sm:flex-row items-center">
                      <div>
                        <span className="text-pierreval-green">
                          {`${businessUnitStr}`}
                        </span>
                        {` / ${operationData.operationBase.address} - ${operationData.operationBase.city}`}
                      </div>
                      <div className="flex flex-row items-center">
                        <div>
                          <AbandonOperation operation={operationData} />
                        </div>
                        <div>
                          <PinProject isOperation />
                        </div>
                        <div className="ml-2">
                          <OperationOverviewModal
                            operation={operationData}
                            childOperations={operationsChildren!}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="absolute bottom-0 left-1/4 w-1/2 h-1 border-b-4 border-pierreval-green mt-2 transform translate-y-2" />
                  </h2>
                </div>
                {operations && <OperationsNavigation />}
              </div>

              {operationsChildren && (
                <div>
                  <SpoAxiosInterceptor>
                    <SncSccv operationsChildren={operationsChildren} />
                  </SpoAxiosInterceptor>
                </div>
              )}

              <ul className="Tab__menu">
                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to=""
                    end
                  >
                    <Info width={20} height={20} color="#00304D" />
                    <span className="Tab__Icon">{`${t("steering")}`}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="documents"
                  >
                    <FaRegFolderOpen size={20} color="#00304D" />
                    <span className="Tab__Icon">{`${t("documents")}`}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="committees"
                  >
                    <div className="mb-0.5">
                      <Committee width={20} height={20} color="#00304D" />
                    </div>
                    <span className="Tab__Icon">{`${t("committees")}`}</span>
                  </NavLink>{" "}
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="study"
                  >
                    <div className="mr-1">
                      <FaPencilRuler size={15} color="#00304D" />
                    </div>
                    <span className="Tab__Icon">{`${t("study")}`}</span>
                  </NavLink>
                </li>

                <PermissionsGuard requiredRoles={["read:agreement"]}>
                  <li>
                    <NavLink
                      preventScrollReset
                      className={({ isActive }) =>
                        isActive
                          ? "Nav__Item Nav__Active"
                          : "Nav__Item Nav__Inactive"
                      }
                      state={{ operations }}
                      to="agreements"
                    >
                      <BiHighlight size={20} color="#00304D" />
                      <span className="Tab__Icon">{`${t("agreements")}`}</span>
                    </NavLink>
                  </li>
                </PermissionsGuard>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="program"
                  >
                    <div className="mr-1">
                      <FaBuilding size={15} color="#00304D" />
                    </div>
                    <span className="Tab__Icon">{`${t("program")}`}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="commercialization"
                  >
                    <MdSell size={18} color="#00304D" />
                    <span className="Tab__Icon">
                      {`${t("commercialization")}`}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="technical"
                  >
                    <TbCrane size={18} color="#00304D" />
                    <span className="Tab__Icon">{`${t("technical")}`}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    preventScrollReset
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    state={{ operations }}
                    to="financial"
                  >
                    <MdEuroSymbol size={20} color="#00304D" />
                    <span className="Tab__Icon">{`${t("financial")}`}</span>
                  </NavLink>
                </li>

                <PermissionsGuard requiredRoles={["read:operation.company"]}>
                  <li>
                    <NavLink
                      preventScrollReset
                      className={({ isActive }) =>
                        isActive
                          ? "Nav__Item Nav__Active"
                          : "Nav__Item Nav__Inactive"
                      }
                      state={{ operations }}
                      to="legal"
                    >
                      <MdBalance size={20} color="#00304D" />
                      <span className="Tab__Icon">{`${t("legal")}`}</span>
                    </NavLink>
                  </li>
                </PermissionsGuard>
              </ul>
            </Container>
          </div>
          <Outlet />
        </QueryBoundaries>
      )}
      {error && (
        <p>
          {error.code} {error.message}
        </p>
      )}
    </div>
  )
}
