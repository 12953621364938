import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import LightTable from "components/Table/LightTable"
import Toggle3States from "components/Toggle3States/Toggle3States"
import { t } from "i18next"
import { Fragment, useEffect, useState } from "react"
import { MdCalendarToday } from "react-icons/md"
import { TSuspensiveCondition } from "shared/types/suspensiveCondition.type"

function DisplayDate({
  date,
  isDefinitive,
}: {
  date: Date
  isDefinitive: boolean
}) {
  return (
    <div className="col-span-1">
      {date ? (
        <div className="flex items-center">
          <MdCalendarToday className="mr-1" />
          <DateFormat date={date} isDefinitive={isDefinitive} />
        </div>
      ) : (
        <div className="flex items-center">
          <MdCalendarToday className="mr-1" />
          <p className="ml-3">-</p>
        </div>
      )}
    </div>
  )
}

interface ISuspensiveConditionsList {
  data: TSuspensiveCondition[]
}

export default function SuspensiveConditionsList(
  props: ISuspensiveConditionsList,
) {
  const { data } = props
  const [filteredSuspensiveConditions, setFilteredSuspensiveConditions] =
    useState<TSuspensiveCondition[]>(data)
  const headers = [
    () => t("agreement"),
    () => t("suspensive-condition"),
    () => t("date"),
  ]

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("date")}`, value: ["date"] },
    { key: `${t("suspensive-condition")}`, value: ["label"] },
    { key: `${t("agreement")}`, value: ["agreement", "name"] },
  ]

  useEffect(() => {
    setFilteredSuspensiveConditions(data)
  }, [data])

  return (
    <Box classNames="p-0">
      <div className="sm:flex items-center justify-between border-gray-200 px-3.5 py-2">
        <div className="flex items-center text-cyan-900 font-medium">
          <h3 className="text-xs uppercase">
            {t("suspensive-conditions-soon-and-late")}
          </h3>
        </div>
        <Toggle3States
          name="test"
          leftLabel={t("suspensive-conditions-soon-message")}
          rightLabel={t("late-message")}
          onChange={(value) => {
            if (value === t("late-message")) {
              setFilteredSuspensiveConditions(
                data.filter((i) => i.date && new Date(i.date) < new Date()),
              )
            } else if (value === t("suspensive-conditions-soon-message")) {
              setFilteredSuspensiveConditions(
                data.filter((i) => i.date && new Date(i.date) > new Date()),
              )
            } else {
              setFilteredSuspensiveConditions(data)
            }
          }}
        />
      </div>
      <div className="Light_Table__Wrapper Table__Wrapper_NoTop">
        <LightTable
          showTotal={false}
          className="table-auto"
          items={filteredSuspensiveConditions}
          headers={headers}
          sortableFields={sortableFields}
          renderRow={(item) => (
            <Fragment key={item.id}>
              <td className="font-semibold">{item.agreement?.name}</td>
              <td>{item.label}</td>
              <td>
                {item.date ? (
                  <DisplayDate
                    isDefinitive={item.isDefinitive}
                    date={item.date}
                  />
                ) : (
                  "-"
                )}
              </td>
            </Fragment>
          )}
        />
      </div>
    </Box>
  )
}
