import { API } from "core/api/axios"
import {
  TCallOfTender,
  TUpsertCallOfTenders,
} from "shared/types/call-of-tender.type"

async function listCallOfTendersByOperation(
  operationId: number,
): Promise<TCallOfTender[]> {
  const res = await API.get(`/call-of-tenders?operationId=${operationId}`)
  return res.data
}

async function upsertCallOfTender(
  data: TUpsertCallOfTenders,
): Promise<TCallOfTender> {
  const res = await API.post(`/call-of-tender`, data)
  return res.data
}

export { listCallOfTendersByOperation, upsertCallOfTender }
