import { Button } from "components/Button/Button"
import { t } from "i18next"

interface DeleteFileFormProps {
  itemName: string
  onCancel: () => void
  onConfirm: () => void
}

function DeleteFileForm({
  itemName,
  onCancel,
  onConfirm,
}: DeleteFileFormProps) {
  return (
    <div>
      {`${t("delete-file-confirmation")} ${itemName}?`}
      <div className="flex justify-end gap-2 mt-2">
        <Button
          type="button"
          onClick={() => onCancel()}
          size="medium"
          mode="secondary"
        >
          {t("cancel")}
        </Button>
        <Button type="button" onClick={onConfirm} size="medium" mode="danger">
          {t("confirm")}
        </Button>
      </div>
    </div>
  )
}

export default DeleteFileForm
