import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import { useGetDocumentsByStudyRequestTypeAndProjectId } from "core/ged/query-hooks/useGed"
import { useGetInfographyDocuments } from "core/query-hooks/useInfographies"
import { useUpdateStatus } from "core/query-hooks/useStudyRequests"
import GedCategories from "features/ged/GedCategories"
import { t } from "i18next"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TMutateInfographyDocuments } from "shared/types/infography.type"
import * as yup from "yup"
import DeleteStudyRequestDraft from "../DeleteStudyRequestDraft"
import {
  STATUS_CREATED,
  TYPE_INFOGRAPHY,
  sortByRequiredFields,
} from "../studyRequest.resources"
import { InfographyContext } from "./InfographyProvider"

const schema = yup.object({
  list: yup.array().of(
    yup.object({
      documentId: yup.number(),
      documentCategoryId: yup.number(),
    }),
  ),
})

export type TInfographyCreateDocumentsStepForm = {
  list: {
    documentIds?: number[]
    documentCategoryId?: number
  }[]
}

export interface IStepDocumentsInfography {
  routeName: string
}

export function StepDocumentsInfography(props: IStepDocumentsInfography) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setStudyRequestInfo, setCurrentId } = useContext(InfographyContext)
  const { id, studyRequestId } = useParams()
  const { routeName } = props

  if (studyRequestId === "undefined")
    return (
      <Navigate to={`/${routeName}/${id}/studies/infography/new`} replace />
    )
  const redirectEditInfog = () =>
    navigate(`/${routeName}/${id}/studies/infography/edit/${studyRequestId}`)
  const { handleSubmit, control } = useForm<TInfographyCreateDocumentsStepForm>(
    {
      resolver: yupResolver(
        schema as yup.ObjectSchema<TInfographyCreateDocumentsStepForm>,
      ),
    },
  )

  const { data: tree } = useGetInfographyDocuments(TYPE_INFOGRAPHY)
  const { data: files, isLoading: isLoadingFiles } =
    useGetDocumentsByStudyRequestTypeAndProjectId(+id!, TYPE_INFOGRAPHY)

  const updateStudyRequest = useUpdateStatus(+studyRequestId!)

  const onSubmit = (data: TInfographyCreateDocumentsStepForm) => {
    setStudyRequestInfo((previsousValue) => {
      return {
        ...previsousValue,
      }
    })

    const documents: TMutateInfographyDocuments = {
      categories: [],
    }

    data?.list?.filter((doc) => {
      if (
        doc.documentCategoryId &&
        doc.documentIds &&
        doc.documentIds.length > 0
      ) {
        documents.categories.push({
          categoryId: doc.documentCategoryId,
          gedDocuments: doc.documentIds!,
        })
      }
      return false
    })

    updateStudyRequest.mutate(
      { statusId: STATUS_CREATED },
      {
        onSuccess() {
          setCurrentId(undefined)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequests", +id!],
          })
          toast.success(t("studyRequest-successMsg"))
          navigate(`/${routeName}/${id}/studies`)
        },
        onError(error) {
          toast.error(error.response?.data.message)
        },
      },
    )
  }

  const { isShowing: isDeleteModalShowed, toggle: toggleDeleteModal } =
    useModal()

  return (
    <div className="px-4 sm:px-16">
      <form>
        <div className="divide-y divide-slate-100">
          <div className="pt-6">
            <div>
              <p className="Color__Primary font-semibold text-lg">
                {`${t("documents")}`}
              </p>
              <p className="Color__Primary mt-2 text-sm">
                {`${t("studyRequest-documentsHelperMsg")}`}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-4 pt-6">
              {sortByRequiredFields(tree)?.map((elm) => (
                <Box
                  key={elm.frenchLabel}
                  classNames="flex flex-col mb-8 md:p-6"
                >
                  <div>
                    {isLoadingFiles ? <Spinner /> : null}

                    {elm.documentTypes.length > 0 ? (
                      files?.documentCategories
                        .filter((category) => elm.id === category.category.id)
                        .map((category) => (
                          <GedCategories
                            key={elm.id}
                            category={category}
                            element={elm}
                            isLoading={isLoadingFiles}
                            control={control}
                            isProjectStudyRequestRelated={
                              routeName === "project"
                            }
                          />
                        ))
                    ) : (
                      <p className="Color__Primary">
                        {`${t("studyRequest-notAvailableCategoryMsg")}`}
                      </p>
                    )}
                  </div>
                </Box>
              ))}
            </div>
          </div>
        </div>
      </form>
      <div className="flex items-center pt-4 pb-8 float-left">
        <DeleteStudyRequestDraft
          isDeleteModalShowed={isDeleteModalShowed}
          toggleDeleteModal={toggleDeleteModal}
          routeName={routeName}
          isPending={updateStudyRequest.isPending}
          studyRequestType={t("this-infography")}
        />
      </div>
      <div className="flex items-center justify-end pt-4 pb-8">
        <div>
          <Button
            isLoading={updateStudyRequest.isPending}
            onClick={() => redirectEditInfog()}
            size="small"
            mode="secondary"
          >
            {`${t("details-draft")}`}
          </Button>
          <Button
            onClick={handleSubmit((data) => onSubmit(data))}
            size="small"
            mode="primary"
            marginLeft={12}
            isDisabled={updateStudyRequest.isPending}
          >
            {`${t("studyRequest-submitStudyRequestButton")}`}
          </Button>
        </div>
      </div>
    </div>
  )
}
