import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Box from "components/Box/Box"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useUpdateChildOperation } from "core/query-hooks/useOperations"
import { t } from "i18next"
import { FormEvent } from "react"
import { useForm } from "react-hook-form"
import { RiPencilFill } from "react-icons/ri"
import { VscMegaphone } from "react-icons/vsc"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TChildOperation } from "shared/types/operation.type"
import * as yup from "yup"

export interface ICommunicationTypeProps {
  childOperation: TChildOperation
}

type FormValues = {
  tradeName?: string | null
}

export function TradeName(props: ICommunicationTypeProps) {
  const { childOperation } = props
  const updateChildOperation = useUpdateChildOperation(childOperation.id)
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const validationSchema = yup.object().shape({
    tradeName: yup.string().nullable(),
  })

  const { register, handleSubmit } = useForm<FormValues>({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tradeName: childOperation.tradeName,
    },
  })

  const submitForm = async (data: FormValues) => {
    updateChildOperation.mutate(
      {
        tradeName: data.tradeName ?? null,
      },
      {
        onSuccess: () => {
          toast.success(`${t("trade-name-update-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listChildOperations", +id!],
          })
        },
        onError: (err) =>
          toast.error(
            `${t("trade-name-update-error")}, ${
              err.response?.data.error.message
            }`,
          ),
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
    toggleModal()
  }

  return (
    <>
      <Box classNames="p-0">
        <div className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between">
          <div className="flex items-center text-cyan-900 mr-1">
            <VscMegaphone size={22} />
            <h3 className="text-xs uppercase font-medium pl-1">
              {t("trade-name")}
            </h3>
          </div>
        </div>

        <div className="mx-2 my-2 flex justify-center items-center">
          <p className="text-sm Color__Primary">{childOperation.tradeName}</p>
          <PermissionsGuard requiredRoles={["update:child-operation"]}>
            <button
              type="button"
              onClick={toggleModal}
              className="ml-4 text-cyan-800 rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
            >
              <RiPencilFill size={20} />
            </button>
          </PermissionsGuard>
        </div>
      </Box>
      {isModalShowed && (
        <Modal
          isShowing={isModalShowed}
          closeModal={toggleModal}
          title={t("update-trade-name")}
        >
          <form onSubmit={onSubmit}>
            <div className="grid grid-cols-1 gap-4 mt-3 mb-4">
              <div className="Text__Field_Container w-full">
                <label htmlFor="trade-name">
                  {`${t("trade-name")}`}
                  <input
                    id="trade-name"
                    className="Text__Field"
                    {...register("tradeName")}
                  />
                </label>
              </div>
            </div>
            <div className="Modal__Footer">
              <Button
                size="medium"
                mode="secondary"
                isLoading={false}
                onClick={toggleModal}
              >
                {`${t("cancel")}`}
              </Button>
              <Button type="submit" size="small" mode="primary" marginLeft={24}>
                {t("update")}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}
