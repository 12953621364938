import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import ButtonIcon from "components/Button/ButtonIcon"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useUpdateStudyRequest } from "core/query-hooks/useStudyRequests"
import {
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { MdCancel, MdCheck, MdEdit } from "react-icons/md"
import { toast } from "react-toastify"
import { GROUP } from "shared/resources/groups.resources"
import { TStudyRequestDashboardItemWithTypeLabel } from "shared/types/study.type"
import * as yup from "yup"

export type Params = {
  businessUnits: number[]
  departments: string[]
  types: number[]
  users: string[]
  tab: string
  status: number[]
  showAllReturned?: boolean
}

export interface IEditStudyRequestPriorisationProps {
  studyRequest: TStudyRequestDashboardItemWithTypeLabel
  params: Params
}

const editableStatuses = [
  STATUS_VALIDATED,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_RESTITUTION_STANDBY,
]

type FormValues = {
  priorisation?: string | null
}

export default function EditStudyRequestPriorisation(
  props: IEditStudyRequestPriorisationProps,
) {
  const { studyRequest, params } = props
  const [displayForm, setDisplayForm] = useState<boolean>(false)
  const isEditable = editableStatuses.includes(studyRequest.statusId)

  const validationSchema = yup.object().shape({
    priorisation: yup.string().nullable(),
  })

  const { register, handleSubmit, reset, setValue } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      priorisation: studyRequest.priorisation,
    },
  })

  useEffect(() => {
    setValue("priorisation", studyRequest.priorisation)
  }, [studyRequest])

  const queryClient = useQueryClient()
  const updateStudyRequest = useUpdateStudyRequest(studyRequest.id)

  const submitForm = (data: { priorisation?: string | null }) => {
    updateStudyRequest.mutate(
      {
        priorisation: data.priorisation || null,
      },
      {
        onSuccess: () => {
          toast.success(`${t("studyRequest-toast-priorisationUpdated")}`)
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequestsDashboard", params],
          })
          reset()
          setDisplayForm(false)
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest-toast-priorisationError")} ${
              err.response?.data.message
            }`,
          ),
      },
    )
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <div className="flex items-center group">
      {displayForm ? (
        <form
          onSubmit={onSubmit}
          className="absolute flex flex-row space-x-0.5 items-center"
        >
          <input
            type="text"
            className="p-1 border rounded-md w-12"
            {...register("priorisation")}
          />
          <div className="flex flex-row space-x-0.5">
            <ButtonIcon
              className="border-10 border-green-600 rounded-full bg-white hover:bg-green-100 shadow-sm"
              type="submit"
            >
              <MdCheck size={16} className="text-green-600" />
            </ButtonIcon>
            <ButtonIcon
              className="border-10 border-red-600 rounded-full bg-white mr-1 hover:bg-red-100 shadow-sm"
              onClick={() => setDisplayForm(false)}
            >
              <MdCancel size={16} className="text-red-600" />
            </ButtonIcon>
          </div>
        </form>
      ) : (
        <>
          {studyRequest.priorisation !== undefined && (
            <span>{studyRequest.priorisation}</span>
          )}

          <GroupsGuard
            requiredGroups={[
              GROUP.CONCEPTION_ECONOMISTE_RESPONSABLE,
              GROUP.CONCEPTION_INFOGRAPHISTE_RESPONSABLE,
              GROUP.CONCEPTION_CONCEPTEUR_RESPONSABLE,
            ]}
          >
            {isEditable && (
              <ButtonIcon
                className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 border-2 border-cyan-800 shadow-sm rounded-full p-1"
                type="button"
                onClick={() => setDisplayForm(true)}
              >
                <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
              </ButtonIcon>
            )}
          </GroupsGuard>
        </>
      )}
    </div>
  )
}
