interface TabContentProps {
  /* eslint-disable react/no-unused-prop-types */
  title: string
  children: React.ReactElement
  value?: string
}

export default function TabContent({ children }: TabContentProps) {
  return <div className="Tab__Content">{children}</div>
}
