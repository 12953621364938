import i18n from "i18next"
import * as yup from "yup"

const updateAgreementSchema = yup.object().shape({
  name: yup.string().required(() => i18n.t("agreements-form-nameValidation")),
  suspensiveConditions: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      agreementId: yup.number(),
      date: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr)),
      isDefault: yup.boolean(),
    }),
  ),
  injonctionDeFaire: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  notaryId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  signature: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  immoCompensationPrice: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  immoCompensationCurrency: yup.string(),
  immoCompensationStatus: yup.string().nullable(),
  immoCompensationDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  termDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  cashPrice: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  cashCurrency: yup.string(),
  dation: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  dationDetails: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  injonctionDeFaireDetails: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
})

const createAgreementSchema = yup.object().shape({
  name: yup
    .string()
    .required(() => i18n.t("agreements-form-nameValidation"))
    .transform((curr, orig) => (orig === "" ? "" : curr)),
  injonctionDeFaire: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  notaryId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  signature: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  immoCompensationPrice: yup.string(),
  immoCompensationCurrency: yup.string(),
  immoCompensationStatus: yup.string().nullable(),
  immoCompensationDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  termDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  cashPrice: yup.string(),
  cashCurrency: yup.string(),
  dation: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  dationDetails: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  injonctionDeFaireDetails: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
})

export { createAgreementSchema, updateAgreementSchema }
