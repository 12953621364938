import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { CustomAxiosError } from "shared/types/axios.type"
import { TDateJalon } from "shared/types/spo/datesPlanning.type"
import {
  TCollectifProductionDate,
  TLotissementsProductionDate,
} from "shared/types/spo/production.type"
import {
  getCollectifsProductionDates,
  getLotissementsProductionDates,
  updateJalonDate,
} from "../api/production"

function useLotissementsProductionDates(registrationNumber: string) {
  return useQuery<TLotissementsProductionDate[], AxiosError>({
    queryKey: ["getLotissementsProductionDates", registrationNumber],
    queryFn: () => getLotissementsProductionDates(registrationNumber),
  })
}

function useCollectifsProductionDates(registrationNumber: string) {
  return useQuery<TCollectifProductionDate[], AxiosError>({
    queryKey: ["getCollectifsProductionDates", registrationNumber],
    queryFn: () => getCollectifsProductionDates(registrationNumber),
  })
}

function useUpdateJalon(idJalon: string) {
  return useMutation<void, CustomAxiosError, TDateJalon>({
    mutationFn: (dateJalon) => updateJalonDate(idJalon, dateJalon),
  })
}

export {
  useCollectifsProductionDates,
  useLotissementsProductionDates,
  useUpdateJalon,
}
