import classNames from "classnames"
import ArrowLeft from "components/Icons/ArrowLeft"
import { LinkCustom } from "components/Link/Link"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiHighlight, BiSolidErrorAlt } from "react-icons/bi"
import { FaPencilRuler } from "react-icons/fa"
import { ImLeaf } from "react-icons/im"
import { MdBolt, MdOutlineAdminPanelSettings } from "react-icons/md"
import { Link, NavLink, Outlet, useNavigate, useParams } from "react-router-dom"
import Container from "../../components/Container/Container"
import Committee from "../../components/Icons/Committee"
import Info from "../../components/Icons/Info"
import Layer from "../../components/Icons/Layer"
import Spinner from "../../components/Spinner/Spinner"
import { useProject } from "../../core/query-hooks/useProjects"
import EditProjectNameAndAddressHeader from "./EditProjectNameAndAddressHeader"

function ProjectPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const { data: projectData, isLoading, error } = useProject(id!)
  const [stickyClass, setStickyClass] = useState("")
  const backUrl = localStorage.getItem("url")

  const stickNavbar = () => {
    if (window) {
      const windowHeight = window.scrollY
      const maxHeightScroll =
        document.documentElement.scrollHeight - window.innerHeight
      if (
        windowHeight > 0 &&
        maxHeightScroll > ref.current?.clientHeight! &&
        maxHeightScroll > 180
      ) {
        setStickyClass(
          "fixed top-15 mt-3 z-20 Background__White w-screen Max_Width",
        )
      } else {
        setStickyClass("relative")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar)
    return () => window.removeEventListener("scroll", stickNavbar)
  }, [])

  function displayErrorMessage() {
    if (
      error &&
      error.response?.status &&
      (error.response.status === 403 || error.response.status === 404)
    ) {
      return (
        <div className="flex items-center justify-center h-80 px-4 text-indigo-950">
          <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-100">
            <div className="flex justify-center mb-4">
              <MdOutlineAdminPanelSettings size={100} />
            </div>
            <div>
              <p className="text-lg text-center text-gray-800">
                {t("unsufficient-rights-for-this-page")}
              </p>
              <div className="text-center mt-3">
                <Link
                  className="text-sm transition-colors ease-in-out delay-50 text-white hover:bg-primary-light bg-primary-dark rounded-lg px-4 py-2"
                  to="/"
                >
                  {`${t("back-to-home")}`}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="flex items-center justify-center h-80 px-4 text-indigo-950">
        <div className="bg-white p-8 rounded-lg shadow-xl border border-gray-100">
          <div className="flex justify-center mb-4">
            <BiSolidErrorAlt size={100} />
          </div>
          <div>
            <p className="text-lg text-center text-gray-800">
              {t("an-error-has-occurred")}.
            </p>
            <p className="text-lg text-center text-gray-800">
              {t("please-contact-support")}
            </p>
            {error?.message ? (
              <span className="text-center text-xs text-gray-500">
                ({error.message} / {error.response?.data?.message})
              </span>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: "auto" }}>
      {isLoading && (
        <div className="flex items-center justify-center pt-8 w-full">
          <Spinner />
        </div>
      )}
      {projectData && !error && (
        <QueryBoundaries loadingComponent={<Spinner />}>
          <div ref={ref}>
            <Container
              backgroundColor="white"
              classNames={classNames("Border__Bottom_LightGrey", stickyClass)}
            >
              <div className="flex">
                <LinkCustom
                  classNames="Padding__Left_16 Padding__Top_16"
                  icon={ArrowLeft}
                  iconSize={16}
                  iconPosition="left"
                  onClick={() => navigate(`/programs/search?${backUrl}`)}
                >
                  {t("back-to-research")}
                </LinkCustom>
              </div>
              <EditProjectNameAndAddressHeader
                name={projectData.name}
                address={projectData.address}
                city={projectData.city}
              />
              <ul className="Tab__menu">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to=""
                    end
                  >
                    <Info width={20} height={20} color="#00304D" />
                    <span className="Tab__Icon">
                      {`${t("general-informations")}`}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to="plots"
                  >
                    <Layer width={20} height={20} color="#00304D" />
                    <span className="Tab__Icon">{`${t("plot")}`}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to="studies"
                  >
                    <div className="mr-1 mb-0.5">
                      <FaPencilRuler size={14} color="#00304D" />
                    </div>
                    <span className="Tab__Icon">{`${t("study")}`}</span>
                  </NavLink>
                </li>
                <PermissionsGuard requiredRoles={["read:agreement"]}>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "Nav__Item Nav__Active"
                          : "Nav__Item Nav__Inactive"
                      }
                      to="agreements"
                    >
                      <BiHighlight size={20} color="#00304D" />
                      <span className="Tab__Icon">{`${t("agreements")}`}</span>
                    </NavLink>
                  </li>
                </PermissionsGuard>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to="committees"
                  >
                    <div className="mb-0.5">
                      <Committee width={20} height={20} color="#00304D" />
                    </div>
                    <span className="Tab__Icon">{`${t("committees")}`}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to="actions"
                  >
                    <MdBolt width={30} height={30} color="#00304D" />
                    <span className="Tab__Icon">{`${t("actions")}`}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "Nav__Item Nav__Active"
                        : "Nav__Item Nav__Inactive"
                    }
                    to="environment"
                  >
                    <ImLeaf width={30} height={30} color="#00304D" />
                    <span className="Tab__Icon">{`${t("environment")}`}</span>
                  </NavLink>
                </li>
              </ul>
            </Container>
          </div>
          <Outlet />
        </QueryBoundaries>
      )}
      {error && displayErrorMessage()}
    </div>
  )
}

export default ProjectPage
