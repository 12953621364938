import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useCreateAmendment,
  useDeleteAmendment,
} from "core/query-hooks/useAmendments"
import { t } from "i18next"
import { MdCalendarToday } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TAmendment } from "shared/types/amendment.type"
import AddAmendmentModal from "./AddAmendmentModal"
import EditAmendmentModal from "./EditAmendmentModal"

export interface IAmendmentsListProps {
  amendments: TAmendment[]
}

export function AmendmentsList(props: IAmendmentsListProps) {
  const { amendments } = props
  const { agreementId } = useParams()
  const createAmendment = useCreateAmendment()
  const deleteAmendment = useDeleteAmendment()
  const queryClient = useQueryClient()

  const onCreateAmendment = async (label: string, date: Date) => {
    await createAmendment.mutateAsync(
      {
        label,
        date,
        agreementId: parseInt(agreementId!),
      },
      {
        onSuccess: () => {
          toast.success(`${t("toast-amendment-create-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listAmendmentsByAgreementId", agreementId],
          })
        },
        onError: (err) => {
          toast.error(
            `${t("toast-amendment-create-error")}, ${
              err.response?.data.error.message
            }`,
          )
        },
      },
    )
  }

  const onDeleteAmendment = async (id: number) => {
    await deleteAmendment.mutateAsync(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["listAmendmentsByAgreementId", agreementId],
        })
        toast.success(`${t("toast-amendment-delete-success")}`)
      },
      onError: (err) => {
        toast.error(
          `${t("toast-amendment-delete-error")} : ${
            err.response?.data.error.message
          }, ${id}`,
        )
      },
    })
  }

  const refetchList = () => {
    queryClient.invalidateQueries({
      queryKey: ["listAmendmentsByAgreementId", agreementId],
    })
  }

  return (
    <Box classNames="py-4 px-6">
      <div className="flex items-center justify-between mb-4">
        <Header size="h2" classNames="pt-2 mb-2">
          {`${t("amendments")}`}
        </Header>

        <QueryBoundaries loadingComponent={<Spinner />}>
          <PermissionsGuard requiredRoles={["create:amendment"]}>
            <AddAmendmentModal
              amendment={undefined}
              addAmendment={(label, date) => onCreateAmendment(label, date!)}
            />
          </PermissionsGuard>
        </QueryBoundaries>
      </div>

      {amendments && amendments.length > 0 ? (
        amendments?.map((amendment) => (
          <Box
            classNames="mb-1 py-4 px-6"
            key={`project-amendment-${amendment.id}`}
          >
            <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
              <div>
                <EditAmendmentModal
                  deleteAmendment={(id: number) => onDeleteAmendment(id)}
                  amendment={amendment}
                  refetch={() => refetchList()}
                />
              </div>
              <div className="flex items-center flex-wrap md:flex-nowrap flex-col md:flex-row  w-24">
                <div className="flex items-center Color__Primary">
                  <MdCalendarToday className="mr-1" />
                  <p>
                    {amendment?.date ? (
                      <DateFormat date={amendment.date!} isDefinitive />
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Box>
        ))
      ) : (
        <p> {`${t("agreements-show-noAmendmentFoundMessage")}`}</p>
      )}
    </Box>
  )
}
