import { FaBuildingUser } from "react-icons/fa6"
import { TLessor } from "shared/types/spo/tranche.type"

export interface ILessorsFilterProps {
  lessors: TLessor[] | null
  selectedLessor: string | null
  setSelectedLessor: (selectedLessor: string | null) => void
}

export function LessorsFilter(props: ILessorsFilterProps) {
  const { lessors, selectedLessor, setSelectedLessor } = props
  return (
    <div className="flex space-x-2 py-3">
      <button
        key="pierreval"
        type="button"
        onClick={() => setSelectedLessor(null)}
        className={`px-4 py-2 rounded-full ${
          selectedLessor === null
            ? "bg-primary-light text-white"
            : "bg-gray-200"
        }`}
      >
        Pierreval
      </button>
      {lessors?.map((lessor) => (
        <button
          key={lessor.code}
          type="button"
          onClick={() => setSelectedLessor(lessor.code)}
          className={`px-4 py-2 rounded-full ${
            selectedLessor === lessor.code
              ? "bg-primary-light text-white"
              : "bg-gray-200"
          }`}
        >
          <FaBuildingUser className="inline-block mr-2" />
          {lessor.label}
        </button>
      ))}
    </div>
  )
}
