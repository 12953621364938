import { TCreateFolder, TSendFolderToGed } from "shared/types/sharepoint"
import SHAREPOINT_API from "../axios"

async function listFoldersFromParent(parentId: string) {
  const res = await SHAREPOINT_API.get(`/folders/parent/${parentId}`)
  return res.data
}

async function countFilesInFolder(parentId: string) {
  const res = await SHAREPOINT_API.get(`/folder/count-file/${parentId}`)
  return res.data
}

async function createFolder(folderInfos: TCreateFolder): Promise<void> {
  return typeof folderInfos === "undefined"
    ? Promise.reject(new Error("Invalid folder"))
    : SHAREPOINT_API.post("/folder", folderInfos).then(
        (response) => response.data,
      )
}

async function sendFolderToGed(data: TSendFolderToGed): Promise<void> {
  const storedGedCookie = localStorage.getItem("gedCookie")
  if (!storedGedCookie) Promise.reject(new Error("Invalid ged authentication"))
  const dataWithAuth = { ...data, authCookie: storedGedCookie }

  return typeof data === "undefined"
    ? Promise.reject(new Error("Invalid query data"))
    : SHAREPOINT_API.post("/send-to-ged", dataWithAuth).then(
        (response) => response.data,
      )
}

export {
  countFilesInFolder,
  createFolder,
  listFoldersFromParent,
  sendFolderToGed,
}
