import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import Spinner from "components/Spinner/Spinner"
import { useMoveFile } from "core/sharepoint/query-hooks/useFiles"
import { useListFoldersFromParent } from "core/sharepoint/query-hooks/useFolders"
import { t } from "i18next"
import { DateFormatter } from "pages/dashboards/components/DateFormatter"
import { useEffect, useState } from "react"
import { FaRegFolder } from "react-icons/fa"
import { toast } from "react-toastify"
import getFileIcon from "utils/getFileIcon"
import { SharepointBreadcrumb } from "./SharepointBreadcrumb"

export interface TMovedFileFormProps {
  toggle: () => void
  sharepointFolderId: string
  itemId: string
}

export default function MoveSharePointFile({
  toggle,
  sharepointFolderId,
  itemId,
}: TMovedFileFormProps) {
  const [currentFolderId, setCurrentFolderId] =
    useState<string>(sharepointFolderId)

  const [breadcrumb, setBreadcrumb] = useState<
    { id: string; name: React.ReactNode }[]
  >([{ id: sharepointFolderId!, name: <FaRegFolder /> }])

  const {
    data: sharepointItems,
    isLoading,
    error,
  } = useListFoldersFromParent(currentFolderId)

  const mutation = useMoveFile()
  const queryClient = useQueryClient()

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await mutation.mutateAsync(
      {
        newParentId: currentFolderId,
        fileId: itemId,
      },
      {
        onSuccess: () => {
          toggle()
          queryClient.invalidateQueries({
            queryKey: ["listFoldersFromParent"],
          })
          queryClient.invalidateQueries({
            queryKey: ["countFilesInFolder", currentFolderId],
          })
          toast.success(`${t("move-file-success")}`)
        },
        onError: () => {
          toast.error(`${t("move-file-error")}`)
        },
      },
    )
  }

  const handleBreadcrumbClick = (index: number) => {
    const selectedFolder = breadcrumb[index]
    setCurrentFolderId(selectedFolder.id)
    setBreadcrumb(breadcrumb.slice(0, index + 1))
  }

  const handleClick = (item: any) => {
    if (item.type === "folder") {
      setBreadcrumb((prev) => [...prev, { id: item.id, name: item.name }])
      setCurrentFolderId(item.id)
    }
  }

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["listFoldersFromParent", currentFolderId],
    })
  }, [currentFolderId])

  if (error) {
    return (
      <div className="flex items-center justify-between p-2">
        {t("error-loading-sharepoint-items")}
      </div>
    )
  }

  if (isLoading) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{
          height: "400px",
        }}
      >
        <Spinner />
      </div>
    )
  }

  return (
    <form onSubmit={submitForm} className="Text__Field_Container mt-4">
      <div className="flex items-center justify-between mb-3">
        <SharepointBreadcrumb
          breadcrumb={breadcrumb}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      {sharepointItems && sharepointItems.length > 0 ? (
        sharepointItems.map((item) => (
          <div
            key={item.id}
            className="flex items-center cursor-pointer hover:bg-gray-100 rounded-lg p-2 dropzone-container"
            onClick={() => handleClick(item)}
          >
            <div className="flex items-center flex-1 min-w-0">
              <div className="mr-2">
                {item.type === "folder" ? (
                  <FaRegFolder color="#2563eb" />
                ) : (
                  getFileIcon(item.name.split(".").pop()?.toLowerCase() || "")
                )}
              </div>
              <div className="truncate">{item.name}</div>
            </div>
            {item.type === "file" && (
              <div className="flex items-center">
                <span className="text-sm text-gray-400 pr-3">
                  <DateFormatter
                    date={new Date(item.lastModifiedDateTime)}
                    withHours
                  />
                </span>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center p-2">
          <p>{t("empty-folder")}</p>
        </div>
      )}

      <div className="Modal__Footer mt-4">
        <div className="flex items-center justify-end">
          <Button
            size="medium"
            mode="secondary"
            isLoading={false}
            onClick={toggle}
          >
            {`${t("cancel")}`}
          </Button>
          <Button
            type="submit"
            marginLeft={24}
            size="medium"
            mode="primary"
            isLoading={mutation.isPending}
          >
            {`${t("move")}`}
          </Button>
        </div>
      </div>
    </form>
  )
}
