import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Box from "components/Box/Box"
import Committee from "components/Icons/Committee"
import { SearchInput } from "components/SearchInput/SearchInput"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import {
  useGetActionsByProjectId,
  useValidateAction,
} from "core/query-hooks/useActions"
import ActionRow from "features/actions/ActionRow"
import AddActionModal from "features/actions/AddActionModal"
import IHOCLoader from "hoc/HocLoader"
import { t } from "i18next"
import { debounce, orderBy } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { MdCheckCircle, MdOutlineFiberManualRecord } from "react-icons/md"
import { TbTrendingUp } from "react-icons/tb"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TAction } from "shared/types/action.type"
import { useDebouncedCallback } from "use-debounce"
import useIsMobile from "utils/isMobile"

export default function OperationActions() {
  const { id } = useParams()
  const [filterNotDone, setFilterNotDone] = useState<boolean>(false)
  const [filterCopil, setFilterCopil] = useState<boolean>(false)
  const [collapsed, setCollapse] = useState<boolean>(true)
  const [take, setTake] = useState<number | undefined>(5)
  const [sortedActions, setSortedActions] = useState<TAction[]>()
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")
  const [sortColumn, setSortColumn] = useState<string>()
  const queryClient = useQueryClient()
  const validate = useValidateAction()
  const [searchText, setSearchText] = useState<string>()
  const isMobile = useIsMobile()

  const {
    data: fiveLastUncompletedActions,
    error: fiveLastUncompletedActionsError,
    isLoading: fiveLastUncompletedActionsIsLoading,
    isError: fiveLastUncompletedActionsIsError,
  } = useGetActionsByProjectId(
    +id!,
    searchText,
    0,
    take,
    "desc",
    "createdAt",
    false,
  )

  const {
    data: allActions,
    error: allActionsError,
    isLoading: allActionsIsLoading,
    isError: allActionsIsError,
  } = useGetActionsByProjectId(
    +id!,
    searchText,
    0,
    undefined,
    "desc",
    "createdAt",
  )

  const error = fiveLastUncompletedActionsError || allActionsError
  const isLoading = fiveLastUncompletedActionsIsLoading || allActionsIsLoading
  const isError = fiveLastUncompletedActionsIsError || allActionsIsError

  const actions = collapsed
    ? fiveLastUncompletedActions?.data
    : allActions?.data

  useEffect(() => {
    let filteredActions = actions

    if (filterNotDone) {
      filteredActions = filteredActions?.filter(
        (action) => action.isDefinitive === false,
      )
    }

    if (filterCopil) {
      filteredActions = filteredActions?.filter(
        (action) => action.isCopil === true,
      )
    }
    setSortedActions(filteredActions)
  }, [actions, filterNotDone, filterCopil])

  const sortTable = (field: string) => {
    const sorted = orderBy([...(sortedActions ?? [])], field, [sortOrder])
    setSortedActions(sorted)
  }

  const handleSort = (header: string, field: string) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    setSortColumn(header)
    sortTable(field)
  }

  const handleFilterNotDoneActions = () => {
    setFilterNotDone(!filterNotDone)
  }

  const handleFilterCopilActions = () => {
    setFilterCopil(!filterCopil)
  }

  const toggleValidateAction = (actionId: number, isDefinitive: boolean) => {
    validate.mutate(
      { id: actionId, isDefinitive },
      {
        onSuccess: (data) => {
          data.isDefinitive
            ? toast.success(`${t("toast-action-validated")}`)
            : toast.success(`${t("toast-action-invalidated")}`)
          queryClient.invalidateQueries({
            queryKey: ["getActionsByProjectId", +id!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getActionByActionId", data.id],
          })
        },
        onError: (err) => {
          const errorMessage =
            err.response?.status === 403
              ? `${t("toast-action-update-error")} ${t("only-creator-and-target-can-validate")}`
              : t("toast-action-update-error")

          toast.error(errorMessage)
        },
      },
    )
  }

  const handleCollapseButton = () => {
    setCollapse(!collapsed)
    take ? setTake(undefined) : setTake(5)
  }

  const displaySortOrder = (header: string) => {
    if (sortOrder && header === sortColumn) {
      return sortOrder === "asc" ? "↑" : "↓"
    }

    return null
  }

  const handleTextChange = useDebouncedCallback(
    useCallback(
      (value: string) => {
        setSearchText(value)
      },
      [searchText],
    ),
    500,
  )

  return (
    <Box classNames="p-0">
      <div className="flex items-center justify-between border-gray-200 px-4 py-3">
        <div className="flex items-center text-cyan-900 font-medium">
          <TbTrendingUp />
          <h3 className="text-xs uppercase pl-2">{t("actions")}</h3>
        </div>
      </div>
      <div className="flex">
        <div className="Progress__Table w-full">
          <div className="px-2 pt-2 pr-1 md:pr-4 flex justify-between items-center flex-wrap bg-fixed">
            <div className={!isMobile ? "w-4/12" : "w-10/12"}>
              <SearchInput
                searchLabel={`${t("action-search")}`}
                handleChange={debounce(handleTextChange, 600)}
              />
            </div>
            <div className="w-2/12 pl-2">
              <AddActionModal isOperation />
            </div>
            <div
              className={`flex items-center ${!isMobile ? "w-6/12 justify-end" : "w-full justify-start"}`}
            >
              {allActions && allActions.data.length > 0 && (
                <div className="flex flex-nowrap items-center justify-start w-full md:w-auto space-y-0 md:space-y-0 space-x-2">
                  <div className="flex flex-nowrap items-center justify-start w-full md:w-auto space-y-0 md:space-y-0 space-x-2">
                    <label
                      className="flex items-center justify-start mx-1 text-sm Color__Primary"
                      htmlFor="actions-toggle-copil-btn"
                    >
                      <div className="flex items-center">
                        <div className="pr-1">
                          <Committee width={20} height={20} color="#B2C229" />
                        </div>
                        <ToggleSwitch
                          id="actions-toggle-copil-btn"
                          name="toggle-copil-actions"
                          disabled={false}
                          checked={filterCopil}
                          onChange={handleFilterCopilActions}
                        />
                      </div>
                    </label>

                    <label
                      className="flex items-center justify-start mx-1 text-sm Color__Primary"
                      htmlFor="actions-toggle-done-btn"
                    >
                      <div className="flex items-center">
                        <div className="pr-1 text-xs">
                          {!isMobile
                            ? `${t("toggle-done-button")}`
                            : `${t("toggle-done-button-short")}`}
                        </div>
                        <ToggleSwitch
                          id="actions-toggle-done-btn"
                          name="toggle-not-done-actions"
                          disabled={false}
                          checked={filterNotDone}
                          onChange={handleFilterNotDoneActions}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            {allActions && allActions.data.length > 0 ? (
              <div className="overflow-x-auto">
                <IHOCLoader
                  error={error}
                  isLoading={isLoading}
                  isError={isError}
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="text-left Table__Head"> </th>
                        <th
                          className={classNames("text-left Table__Head", {
                            Table__Sticky_Column: !isMobile,
                          })}
                          onClick={() => handleSort("date", "createdAt")}
                        >
                          {`${t("date")}`} {displaySortOrder("date")}
                        </th>
                        <th
                          className={classNames("text-left Table__Head", {
                            Table__Sticky_Column: !isMobile,
                          })}
                          onClick={() =>
                            handleSort("description", "description")
                          }
                        >
                          {`${t("description")}`}{" "}
                          {displaySortOrder("description")}
                        </th>
                        <th
                          className="text-center Table__Head"
                          onClick={() =>
                            handleSort("issuer", "creator.firstName")
                          }
                        >
                          {`${t("issuer")}`} {displaySortOrder("issuer")}
                        </th>
                        <th
                          className="text-center Table__Head"
                          onClick={() =>
                            handleSort(
                              "assigned-to",
                              "personInCharge.firstName",
                            )
                          }
                        >
                          {`${t("assigned-to")}`}{" "}
                          {displaySortOrder("assigned-to")}
                        </th>
                        <th
                          className="text-left Table__Head"
                          onClick={() => handleSort("deadline", "deadline")}
                        >
                          {`${t("deadline")}`} {displaySortOrder("deadline")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedActions?.length && sortedActions?.length > 0 ? (
                        <>
                          {sortedActions?.map((action, index) => (
                            <tr
                              className="Table__Row"
                              key={`action-item-${action.id}`}
                            >
                              <td
                                className={classNames("Table__TD w-12", {
                                  "border-none":
                                    index === sortedActions.length - 1,
                                  Table__Border_Bottom:
                                    index !== sortedActions.length - 1,
                                  Table__Sticky_Column: !isMobile,
                                })}
                              >
                                <span
                                  onClick={() =>
                                    toggleValidateAction(
                                      action.id,
                                      !action.isDefinitive,
                                    )
                                  }
                                  className="cursor-pointer items-center flex justify-center"
                                >
                                  {action.isDefinitive ? (
                                    <MdCheckCircle size={20} color="#0CB28B" />
                                  ) : (
                                    <MdOutlineFiberManualRecord
                                      className="text-slate-200 hover:text-emerald-600 hover:opacity-40"
                                      size={26}
                                    />
                                  )}
                                </span>
                              </td>
                              <ActionRow
                                action={action}
                                border={
                                  index !== sortedActions.length - 1 &&
                                  index !== sortedActions.length - 2
                                }
                                isOperation
                              />
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <p className="Color__Primary text-sm flex items-center justify-center ">
                              {t("all-actions-over")}
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </IHOCLoader>
                {actions &&
                  allActions &&
                  allActions.data.length &&
                  (allActions.data.length > actions.length || !collapsed) && (
                    <div className="Color__Primary mx-4 my-2 flex justify-center md:justify-end">
                      {collapsed ? (
                        <button
                          onClick={() => handleCollapseButton()}
                          className="rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
                          type="button"
                        >
                          <FaChevronDown cursor="pointer" />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleCollapseButton()}
                          className="rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
                          type="button"
                        >
                          <FaChevronUp cursor="pointer" />
                        </button>
                      )}
                    </div>
                  )}
              </div>
            ) : (
              <p className="Color__Primary text-sm mx-4 my-4">
                {t("no-data-found-message")}
              </p>
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}
