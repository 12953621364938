import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { useEffect } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"

registerLocale("fr", fr)

const createValidationSchema = (requiredDate: boolean) =>
  yup.object().shape({
    date: requiredDate
      ? yup.date().required(() => `${t("required-date")}`)
      : yup.date().nullable(),
    isDateDefinitive: yup.boolean().required(),
  })

interface EditDateModalProps {
  isModalShowed: boolean
  toggleModal: () => void
  initialDate: Date | null | undefined
  isDefinitive: boolean
  onSubmit: (date: Date | null | undefined, isDefinitive: boolean) => void
  isPending: boolean
  title: string
  labelDate: string
  requiredDate?: boolean
  hasToggle?: boolean
}

export default function EditDateModal(props: EditDateModalProps) {
  const {
    isModalShowed,
    toggleModal,
    initialDate,
    isDefinitive,
    onSubmit,
    isPending,
    title,
    labelDate,
    requiredDate = false,
    hasToggle = true,
  } = props

  const validationSchema = createValidationSchema(requiredDate)

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: initialDate,
      isDateDefinitive: isDefinitive,
    },
  })

  useEffect(() => {
    setValue("date", initialDate)
    setValue("isDateDefinitive", isDefinitive)
  }, [initialDate, isDefinitive, setValue])

  const handleFormSubmit = (data: {
    date?: Date | null
    isDateDefinitive: boolean
  }) => {
    onSubmit(data.date ?? null, data.isDateDefinitive)
  }

  return (
    <Modal isShowing={isModalShowed} closeModal={toggleModal} title={title}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className=" Text__Field_Container"
      >
        <div className="mt-4 flex flex-col">
          <label htmlFor="date">{labelDate}</label>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <CustomDatePicker
                id="date"
                locale={fr}
                className="Text__Field"
                placeholderText={labelDate}
                selected={field.value}
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
          {errors.date && (
            <p className="text-red-600 text-sm">{errors.date.message}</p>
          )}
        </div>

        {hasToggle && (
          <div className="mt-2 mb-6">
            <label htmlFor="isDateDefinitive">
              {`${t("date-is-definitive")}`}
              <div className="flex items-center justify-start">
                <Controller
                  name="isDateDefinitive"
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      id="isDateDefinitive"
                      {...register("isDateDefinitive")}
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={false}
                    />
                  )}
                />
              </div>
            </label>
          </div>
        )}

        <div className="Modal__Footer flex justify-between mt-3">
          <Button
            onClick={toggleModal}
            type="button"
            size="medium"
            mode="secondary"
          >
            {t("cancel")}
          </Button>
          <Button
            marginLeft={24}
            size="medium"
            mode="primary"
            type="submit"
            isLoading={isPending}
          >
            {t("update")}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
