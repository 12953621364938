import DateFormat from "components/Date/DateFormat"
import DisplayError from "components/Display/DisplayError"
import DisplayLoading from "components/Display/DisplayLoading"
import LightTable from "components/Table/LightTable"
import { useListStudyRequestsDashboard } from "core/query-hooks/useStudyRequests"
import ActionAvatarCell from "features/actions/ActionAvatarCell"
import {
  STATUS_CANCELLED,
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "features/studies/studyRequest.resources"
import { getStudyRequestLabel } from "features/studies/StudyRequestsList"
import { StudyStatus } from "features/studies/StudyStatus"
import { t } from "i18next"
import { Fragment } from "react"
import { Link } from "react-router-dom"
import {
  TStudyRequestDashboardItemWithTypeLabel,
  TStudyType,
} from "shared/types/study.type"
import getStudyRequestUrl from "utils/getStudyRequestUrl"
import { DateFormatter } from "../components/DateFormatter"
import EditStudyRequestPriorisation from "./EditStudyRequestPriorisation"

const headers = [
  t("project-title"),
  t("department"),
  t("number-of-lots"),
  t("progress"),
  t("creator"),
  t("validated-on"),
  t("attributed-on"),
  t("version"),
  t("attributed-users"),
  t("restitution-date"),
  t("priorisation"),
]

const sortableFields: { key: string; value: string[] }[] = [
  { key: `${t("project-title")}`, value: ["city"] },
  { key: `${t("department")}`, value: ["department"] },
  { key: `${t("number-of-lots")}`, value: ["numberOfLots"] },
  { key: `${t("progress")}`, value: ["statusOrder"] },
  { key: `${t("creator")}`, value: ["creator", "firstName"] },
  { key: `${t("validated-on")}`, value: ["validatedOn"] },
  { key: `${t("attributed-on")}`, value: ["attributedOn"] },
  { key: `${t("version")}`, value: ["version"] },
  { key: `${t("attributed-users")}`, value: ["assignedUsers", "firstName"] },
  { key: `${t("restitution-date")}`, value: ["scheduledOn"] },
  { key: `${t("priorisation")}`, value: ["priorisation"] },
]

export interface ISearchProps {
  businessUnits: number[]
  departments: string[]
  types: number[]
  users: string[]
  tab: string
  status: number[]
  showAllReturned?: boolean
}

export default function OverviewStudyRequests(props: ISearchProps) {
  const {
    businessUnits,
    departments,
    types,
    users,
    tab,
    status,
    showAllReturned,
  } = props

  const {
    data: studies,
    isLoading,
    error,
  } = useListStudyRequestsDashboard({
    businessUnits,
    departments,
    types,
    users,
    tab,
    status,
    showAllReturned,
  })

  const getOrderStatus = (statusId: number) => {
    switch (statusId) {
      case STATUS_DRAFT:
        return "1"
      case STATUS_CREATED:
        return "2"
      case STATUS_REJECTED:
        return "3"
      case STATUS_VALIDATED:
        return "4"
      case STATUS_PRIORIZATION_STANDBY:
        return "5"
      case STATUS_PRIORIZED_AND_ASSIGNED:
        return "6"
      case STATUS_RESTITUTION_STANDBY:
        return "7"
      case STATUS_RETURNED:
        return "8"
      case STATUS_CANCELLED:
        return "9"
      default:
        return null
    }
  }

  let enhancedStudies: TStudyRequestDashboardItemWithTypeLabel[] = []
  if (studies) {
    enhancedStudies = studies.map((item) => {
      return {
        ...item,
        typeLabel: getStudyRequestLabel(item.type),
        statusOrder: getOrderStatus(item.statusId),
      }
    })
  }

  const projetName = "project"
  const opeName = "operation"

  const separator = tab === "bu" ? "businessUnitLabel" : "type"

  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-4">
      <div className="col-span-6 Light_Table__Wrapper Table__Wrapper_NoTop">
        {isLoading && !error && <DisplayLoading isLoading={isLoading} />}
        {error && !isLoading && (
          <DisplayError errorMessage={t("data-error")} error={error} />
        )}
        {!isLoading && !error && enhancedStudies && (
          <LightTable<TStudyRequestDashboardItemWithTypeLabel>
            showTotal={false}
            className="table-auto"
            items={enhancedStudies}
            headers={headers}
            sortableFields={sortableFields}
            separator={separator}
            renderRow={(item, index) => (
              <Fragment key={item.id}>
                <td className="py-6">
                  {" "}
                  <div className="flex items-center">
                    <Link
                      className="text-blue-700 hover:text-blue-600 transition-colors ease-in-out delay-75 hover:underline"
                      to={getStudyRequestUrl(
                        item.id,
                        item.idProject,
                        item.isRegistered ? opeName : projetName,
                        item.type as TStudyType,
                      )}
                    >{`${item.city}, ${item.address}`}</Link>
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    {item.postcode?.slice(0, 2)}
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    {item.numberOfLots ? item.numberOfLots : "-"}
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    <StudyStatus requestStatusId={item.statusId} />
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center justify-center">
                    <ActionAvatarCell
                      userAzureId={item.creator?.azureId!}
                      isLast={index === enhancedStudies.length - 1}
                    />
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    <DateFormatter date={item.validatedOn} />
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    <DateFormatter date={item.attributedOn} />
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">{item.version}</div>
                </td>
                <td className="py-4">
                  <div className="flex items-center justify-center">
                    {item.assignedUsers.map((u) => (
                      <ActionAvatarCell
                        key={u.azureId}
                        userAzureId={u.azureId}
                        isLast={index === enhancedStudies.length - 1}
                      />
                    ))}
                  </div>
                </td>
                <td className="py-4">
                  <div className="flex items-center">
                    {item.restitutionDate ? (
                      <DateFormat
                        date={item.restitutionDate}
                        isDefinitive={item.statusId === STATUS_RETURNED}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </td>
                <td className="py-6 min-h-84">
                  <EditStudyRequestPriorisation
                    studyRequest={item}
                    params={{
                      businessUnits,
                      departments,
                      types,
                      users,
                      tab,
                      status,
                      showAllReturned,
                    }}
                  />
                </td>
              </Fragment>
            )}
            renderSeparator={(item) => (
              <span>
                {separator === "type"
                  ? getStudyRequestLabel(item.type)
                  : `${item.businessUnitLabel}`}
              </span>
            )}
          />
        )}
      </div>
    </div>
  )
}
