import { yupResolver } from "@hookform/resolvers/yup"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { FormTextField } from "components/Form/FormTextField"
import { Header } from "components/Header/Header"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import {
  agreementDetailQuery,
  useUpdateAgreement,
} from "core/query-hooks/useAgreements"
import currency from "currency.js"
import { fr } from "date-fns/locale"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useState } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TMutateAgreement } from "shared/types/agreement.type"
import { TCurrency } from "shared/types/global.type"
import * as yup from "yup"
import AmendmentsBox from "./amendments/AmendmentsBox"
import { updateAgreementSchema } from "./formSchemas"
import PlotBox from "./plots/PlotsBox"
import SuspensiveConditionsBox from "./suspensive-conditions/SuspensiveConditionsBox"
import TasksBox from "./tasks/TasksBox"

registerLocale("fr", fr)

export type UpdateAgreementForm = {
  name: string
  notaryId: number | null
  signature: Date
  termDate: Date
  immoCompensationPrice?: number
  immoCompensationCurrency: string
  immoCompensationStatus: string
  immoCompensationDate: Date
  cashPrice?: number
  cashCurrency: TCurrency
  dation: number
  dationDetails: string
  injonctionDeFaire: number
  injonctionDeFaireDetails: string
  projectId: number
}

export interface IAgreementUpdateProps {
  projectContext: boolean
}

export function AgreementUpdate(props: IAgreementUpdateProps) {
  const { projectContext } = props
  const redirect = projectContext ? "project" : "operation"
  const { t } = useTranslation()
  const { id, agreementId } = useParams()
  const { data: agreementData } = agreementDetailQuery(+agreementId!)

  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [cashPriceValue, setCashPriceValue] = useState<{
    value?: number
    unit: TCurrency
  }>({
    value: agreementData?.cashPrice
      ? parseFloat(agreementData.cashPrice)
      : undefined,
    unit: agreementData?.cashCurrency ?? "EUR",
  })
  const [immoCompensationPriceValue, setImmoCompensationValue] = useState<{
    value?: number
    unit: TCurrency
  }>({
    value: agreementData?.immoCompensationPrice
      ? parseFloat(agreementData.immoCompensationPrice)
      : undefined,
    unit: agreementData?.immoCompensationCurrency ?? "EUR",
  })
  const [injonctionDeFaire, setInjonctionDeFaire] = useState<{
    value?: number
    unit: TCurrency
  }>({
    value: agreementData?.injonctionDeFaire ?? undefined,
    unit: "EUR",
  })
  const [dation, setDation] = useState<{
    value?: number
    unit: TCurrency
  }>({
    value: agreementData?.dation ?? undefined,
    unit: "EUR",
  })
  const [signatureDate, setSignatureDate] = useState<null | Date | undefined>(
    agreementData?.signature ? new Date(agreementData.signature) : null,
  )
  const [termDate, setTermDate] = useState<null | Date | undefined>(
    agreementData?.termDate ? new Date(agreementData.termDate) : null,
  )
  const [immoCompensationDate, setImmoCompensationDate] = useState<
    null | Date | undefined
  >(
    agreementData?.immoCompensationDate
      ? new Date(agreementData.immoCompensationDate)
      : null,
  )
  const updateAgreement = useUpdateAgreement(Number(agreementId))
  const navigate = useNavigate()
  const redirectToAgreements = () => {
    navigate(`/${redirect}/${id}/agreements/${agreementId}`)
    return toggleModal
  }

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TMutateAgreement>({
    resolver: yupResolver(
      updateAgreementSchema as unknown as yup.ObjectSchema<TMutateAgreement>,
    ),
    defaultValues: {
      name: agreementData?.name,
      notaryId: agreementData?.notary?.id,
      immoCompensationPrice: Number(immoCompensationPriceValue.value),
      immoCompensationCurrency: immoCompensationPriceValue.unit,
      immoCompensationStatus: agreementData?.immoCompensationStatus,
      cashPrice: Number(cashPriceValue.value),
      cashCurrency: cashPriceValue.unit,
      dation: dation.value,
      dationDetails: agreementData?.dationDetails,
      injonctionDeFaire: injonctionDeFaire.value,
      injonctionDeFaireDetails: agreementData?.injonctionDeFaireDetails,
      projectId: agreementData?.project.id,
    },
  })

  const redirectToAgreementsBtn = () =>
    navigate(`/${redirect}/${id}/agreements/${agreementId}`)

  const onSubmit = async (data: TMutateAgreement) => {
    await updateAgreement.mutateAsync(
      {
        ...data,
        immoCompensationPrice: immoCompensationPriceValue.value
          ? Number(currency(immoCompensationPriceValue.value))
          : null,
        notaryId: data.notaryId ? data.notaryId : null,
        cashPrice: cashPriceValue.value
          ? Number(currency(cashPriceValue.value))
          : null,
        cashCurrency: cashPriceValue.unit,
        immoCompensationCurrency: immoCompensationPriceValue.unit,
        dation: dation.value ? Number(currency(dation.value)) : null,
        injonctionDeFaire: injonctionDeFaire.value
          ? Number(currency(injonctionDeFaire.value))
          : null,
      },
      {
        onSuccess: () => {
          toast.success(`${t("agreements-updateMessage")}`)
        },
        onError: (error) => {
          toast.error(
            `${t("agreements-updateErrorMessage")} : ${
              error.response?.data.error.message
            }`,
          )
        },
      },
    )
    navigate(`/${redirect}/${id}/agreements/${agreementId}`, { replace: true })
  }

  return agreementData ? (
    <>
      <DivStickyMenu />
      <div className="px-6 pb-12">
        <Header classNames="pt-4 pb-4">{agreementData?.name}</Header>
        <div className="lg:grid grid-rows-1 lg:grid-cols-1 gap-4 grid-flow-row-dense">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box classNames="px-6 sm:mb-2 md:mb-4 mb-4">
              <Header size="h2" classNames="pt-2">
                {`${t("agreements-formSectionTitle-general")}`}
              </Header>
              <FormTextField
                id="name"
                className="Text__Field_Container my-2"
                errors={errors}
                name="name"
                register={register}
                label={`${t("entitled")} *`}
                type="text"
              />
              <Header size="h2" classNames="pt-2">
                {`${t("price")}`}
              </Header>
              <div className="flex flex-wrap items-end gap-x-8 w-full">
                <div className="flex-1">
                  <FormTextField
                    id="cashPrice"
                    errors={errors}
                    name="cashPrice"
                    setPriceValue={setCashPriceValue}
                    priceValue={cashPriceValue}
                    className="Text__Field_Container my-2"
                    register={register}
                    label={`${t("agreements-cashPrice")}`}
                    type="currency"
                  />
                </div>
                <div className="flex-1">
                  <div>
                    <FormTextField
                      id="dation"
                      errors={errors}
                      name="dation"
                      setPriceValue={setDation}
                      priceValue={dation}
                      className="Text__Field_Container my-2"
                      register={register}
                      label={`${t("agreements-gift")}`}
                      type="currency"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <FormTextField
                    id="injonctionDeFaire"
                    errors={errors}
                    name="injonctionDeFaire"
                    setPriceValue={setInjonctionDeFaire}
                    priceValue={injonctionDeFaire}
                    className="Text__Field_Container my-2"
                    register={register}
                    label={`${t("agreements-mustDo")}`}
                    type="currency"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                <div className="md:col-end-3 w-full">
                  <FormTextField
                    id="dationDetails"
                    control={control}
                    errors={errors}
                    name="dationDetails"
                    className="Text__Field_Container my-2  flex-1"
                    register={register}
                    label={`${t("details")}`}
                    type="text"
                  />
                </div>
                <div className="md:col-end-4 w-full">
                  <FormTextField
                    id="injonctionDeFaireDetails"
                    control={control}
                    errors={errors}
                    name="injonctionDeFaireDetails"
                    className="Text__Field_Container my-2  flex-1"
                    register={register}
                    label={`${t("details")}`}
                    type="text"
                  />
                </div>
              </div>
              <Header size="h2" classNames="pt-2">
                {`${t("agreements-formSectionTitle-immoCompensation")}`}
              </Header>
              <div className="flex flex-wrap items-end gap-x-8 w-full">
                <FormTextField
                  id="immoCompensationPrice"
                  errors={errors}
                  setPriceValue={setImmoCompensationValue}
                  priceValue={immoCompensationPriceValue}
                  name="immoCompensationPrice"
                  className="Text__Field_Container my-2"
                  register={register}
                  label={`${t("amount")}`}
                  type="currency"
                />

                <FormTextField
                  id="immoCompensationStatus"
                  control={control}
                  errors={errors}
                  name="immoCompensationStatus"
                  className="Text__Field_Container my-2  flex-1"
                  register={register}
                  leftLabel="A virer"
                  rightLabel="A cautionner"
                  label={`${t("status")}`}
                  type="checkbox"
                />
                <div className="Text__Field_Container my-2  flex-1 relative mb-4">
                  <div className="flex flex-col flex-1">
                    <label htmlFor="immoCompensationDate">{`${t(
                      "before-the",
                    )}`}</label>
                    <Controller
                      control={control}
                      name="immoCompensationDate"
                      render={({ field }) => (
                        <CustomDatePicker
                          id="immoCompensationDate"
                          dateFormat="dd/MM/yyyy"
                          locale={fr}
                          className="Text__Field"
                          selected={immoCompensationDate}
                          placeholderText={`${t("datePlaceholder")}`}
                          onChange={(date) => {
                            setImmoCompensationDate(date)
                            field.onChange(date)
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <Header size="h2" classNames="pt-2">
                {`${t("timing")}`}
              </Header>
              <div className="flex flex-wrap gap-8 mt-2">
                <div className="Text__Field_Container w-full md:flex-1 mb-4">
                  <label
                    htmlFor="signature"
                    className="mr-3"
                  >{`${t("signature-date")}`}</label>
                  <Controller
                    control={control}
                    name="signature"
                    render={({ field }) => (
                      <CustomDatePicker
                        id="signature"
                        dateFormat="dd/MM/yyyy"
                        locale={fr}
                        className="Text__Field"
                        selected={signatureDate}
                        placeholderText={`${t("datePlaceholder")}`}
                        onChange={(date) => {
                          setSignatureDate(date)
                          field.onChange(date)
                        }}
                      />
                    )}
                  />
                </div>
                <div className="Text__Field_Container w-full md:flex-1 mb-4">
                  <label
                    htmlFor="termDate"
                    className="mr-3"
                  >{`${t("term-date")}`}</label>
                  <Controller
                    control={control}
                    name="termDate"
                    render={({ field }) => (
                      <CustomDatePicker
                        id="termDate"
                        dateFormat="dd/MM/yyyy"
                        locale={fr}
                        className="Text__Field"
                        selected={termDate}
                        placeholderText={`${t("datePlaceholder")}`}
                        onChange={(date) => {
                          setTermDate(date)
                          field.onChange(date)
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center mt-4 Padding__Top_24">
                <Button
                  onClick={() => redirectToAgreementsBtn()}
                  size="medium"
                  mode="secondary"
                  isLoading={false}
                >
                  {`${t("cancel")}`}
                </Button>
                <div style={{ marginLeft: 16 }}>
                  <Button
                    type="submit"
                    size="medium"
                    mode="primary"
                    isLoading={false}
                  >
                    {`${t("update")}`}
                  </Button>
                </div>
              </div>
            </Box>
          </form>

          <SuspensiveConditionsBox />
          <PlotBox routeName={redirect} />
          <TasksBox />
          <AmendmentsBox />
        </div>
      </div>
      <Modal
        description={`${t("agreements-delete-description")}`}
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("cancelModal-title")}`}
      >
        <div className="Modal__Footer">
          <Button
            size="medium"
            mode="secondary"
            isLoading={false}
            onClick={() => toggleModal}
          >
            {`${t("cancel")}`}
          </Button>
          <Button
            marginLeft={24}
            size="medium"
            mode="danger"
            isLoading={false}
            onClick={() => redirectToAgreements()}
          >
            {`${t("confirm")}`}
          </Button>
        </div>
      </Modal>
    </>
  ) : (
    <Spinner />
  )
}
