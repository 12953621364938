import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components/Button/Button"
import { Header } from "components/Header/Header"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import WatchChangesBar from "components/WatchChangesBar/WatchChangesBar"
import { useCreateAgreement } from "core/query-hooks/useAgreements"
import FormCreate from "features/agreements/form/FormCreate"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TCurrency } from "shared/types/global.type"
import { TPlot } from "shared/types/plot.type"
import * as yup from "yup"
import { createAgreementSchema } from "./formSchemas"

export type CreateAgreementForm = {
  name: string
  notaryId: number
  signature: Date
  plots: TPlot[]
  termDate: Date
  immoCompensationPrice: number
  immoCompensationCurrency: string
  immoCompensationStatus?: string
  immoCompensationDate: Date
  cashPrice: number
  cashCurrency: string
  dation: number
  dationDetails?: string
  injonctionDeFaire: number
  injonctionDeFaireDetails?: string
  projectId: number
}

export interface IAgreementCreateProps {
  projectContext: boolean
}

export function AgreementCreate({ ...props }: IAgreementCreateProps) {
  const { projectContext } = props
  const redirect = projectContext ? "project" : "operation"
  const { t } = useTranslation()
  const { id } = useParams()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [cashPriceValue, setCashPriceValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const [dationValue, setDationValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const [injonctionDeFaireValue, setInjonctionDeFaireValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const [immoCompensationPriceValue, setImmoCompensationValue] = useState<{
    value?: number
    unit: TCurrency
  }>({ unit: "EUR" })

  const createAgreement = useCreateAgreement()
  const navigate = useNavigate()
  const redirectToAgreements = () => {
    navigate(`/${redirect}/${id}/agreements`)
    return toggleModal
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<CreateAgreementForm>({
    resolver: yupResolver(
      createAgreementSchema as unknown as yup.ObjectSchema<CreateAgreementForm>,
    ),
    defaultValues: {
      projectId: parseInt(id!),
      immoCompensationStatus: undefined,
    },
  })

  const redirectToAgreementsBtn = () => {
    if (isDirty) return toggleModal()
    return navigate(`/${redirect}/${id}/agreements`)
  }

  const onSubmitAgreementAdd = (data: CreateAgreementForm) => {
    createAgreement.mutate(
      {
        ...data,
        cashPrice: Number(cashPriceValue.value),
        dation: Number(dationValue.value),
        injonctionDeFaire: Number(injonctionDeFaireValue.value),
        cashCurrency: cashPriceValue.unit,
        immoCompensationPrice: Number(immoCompensationPriceValue.value),
        immoCompensationCurrency: immoCompensationPriceValue.unit,
      },
      {
        onSuccess: (agreement) => {
          toast.success(`${t("agreements-createMessage")}`)
          navigate(`/${redirect}/${id}/agreements/${agreement.id}`)
        },
        onError: (err) => {
          toast.error(
            `${t("agreements-createErrorMessage")} : ${
              err.response?.data.message
            }`,
          )
        },
      },
    )
  }

  const onFormSubmit = (data: CreateAgreementForm) => {
    onSubmitAgreementAdd(data)
  }

  return (
    <>
      <DivStickyMenu />
      {isDirty && (
        <WatchChangesBar
          onSaveAction={{
            onAction() {
              handleSubmit((data: CreateAgreementForm) => {
                onSubmitAgreementAdd(data)
              })()
            },
            content: t("create"),
            isLoading: false,
          }}
          onDiscardAction={{
            onAction(): void {
              toggleModal()
            },
            content: `${t("cancel")}`,
          }}
          message={`${t("agreements-create-watchBar-message")}`}
        />
      )}
      <div className="px-4 pb-4">
        <Header classNames="pt-4 pb-4 text-primary-font">
          {t("agreements-create-title")}
        </Header>
        <FormCreate
          setCashPriceValue={setCashPriceValue}
          cashPriceValue={cashPriceValue}
          setImmoCompensationValue={setImmoCompensationValue}
          immoCompensationValue={immoCompensationPriceValue}
          setDationValue={setDationValue}
          dationValue={dationValue}
          setInjonctionDeFaireValue={setInjonctionDeFaireValue}
          injonctionDeFaireValue={injonctionDeFaireValue}
          handleSubmit={handleSubmit}
          register={register}
          onceSubmited={(data) => onFormSubmit(data)}
          errors={errors}
          control={control}
          isCreating={createAgreement.isPending}
        >
          <Button
            onClick={() => redirectToAgreementsBtn()}
            size="medium"
            mode="secondary"
            isLoading={false}
          >
            {`${t("cancel")}`}
          </Button>
        </FormCreate>
      </div>
      <Modal
        description={`${t("agreements-delete-description")}`}
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("cancelModal-title")}`}
      >
        <div className="Modal__Footer">
          <Button
            size="medium"
            mode="secondary"
            isLoading={false}
            onClick={() => toggleModal}
          >
            {`${t("cancel")}`}
          </Button>
          <Button
            marginLeft={24}
            size="medium"
            mode="danger"
            isLoading={false}
            onClick={() => redirectToAgreements()}
          >
            {`${t("confirm")}`}
          </Button>
        </div>
      </Modal>
    </>
  )
}
