import DateFormat from "components/Date/DateFormat"

export interface IDateFormatterProps {
  date: Date | string | null | undefined
  withHours?: boolean
}

export function DateFormatter(props: IDateFormatterProps) {
  const { date, withHours } = props
  return date ? (
    <DateFormat date={new Date(date)} isDefinitive withHours={withHours} />
  ) : null
}
