import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import {
  useUpdateStatus,
  useUpdateStudyRequest,
} from "core/query-hooks/useStudyRequests"
import { t } from "i18next"
import { FormEvent, useState } from "react"
import { toast } from "react-toastify"

export interface IValidationFormProps {
  requestId: number
}

export function ValidationForm(props: IValidationFormProps) {
  const { requestId } = props
  const queryClient = useQueryClient()
  const [comment, setComment] = useState<string | undefined>()
  const updateStudyRequestStatus = useUpdateStatus(requestId!)
  const updateValidationComment = useUpdateStudyRequest(requestId!)

  const validateRequest = (statusId: number) => {
    updateStudyRequestStatus.mutate(
      {
        statusId,
      },
      {
        onSuccess: () => {
          statusId === 3
            ? toast.success(`${t("studyRequest-toast-validationSuccess")}`)
            : toast.success(`${t("studyRequest-toast-refusalSuccess")}`)
          queryClient.invalidateQueries({
            queryKey: ["getFeasibility", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getCostOfWork", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getInfography", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getFeasibilityHistory", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getCostOfWorkHistory", requestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getInfographyHistory", requestId!],
          })
          queryClient.invalidateQueries({ queryKey: ["listStudyRequests"] })
        },
        onError: (err) =>
          statusId === 3
            ? toast.error(
                `${t("studyRequest-toast-validationError")} ${
                  err.response?.data.message
                }`,
              )
            : toast.error(
                `${t("studyRequest-toast-refusalError")} ${
                  err.response?.data.message
                }`,
              ),
      },
    )
  }

  const updateStudyRequest = (statusId: number, validationComment?: string) => {
    updateValidationComment.mutate(
      {
        validationComment,
      },
      {
        onSuccess: () => {
          validateRequest(statusId)
        },
        onError: (err) =>
          statusId === 3
            ? toast.error(
                `${t("studyRequest-toast-validationError")} ${
                  err.response?.data.message
                }`,
              )
            : toast.error(
                `${t("studyRequest-toast-refusalError")} ${
                  err.response?.data.message
                }`,
              ),
      },
    )
  }

  const onSubmitValidation = (status: number) => {
    updateStudyRequest(status, comment)
    setComment(undefined)
  }

  function onSubmit(e: FormEvent<HTMLFormElement>, status: number) {
    e.stopPropagation()
    e.preventDefault()
    onSubmitValidation(status)
  }

  return (
    <div>
      <div className="Text__Field_Container">
        <label htmlFor="comment">
          {`${t("comment")}`}
          <textarea
            id="comment"
            className="Textarea__Field"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={`${t("comment")}`}
          />
        </label>
      </div>
      <div className="flex">
        <form
          className="Text__Field_Container mt-2"
          onSubmit={(e) => onSubmit(e, 4)}
        >
          <Button type="submit" size="small" mode="primary">
            {`${t("decline")}`}
          </Button>
        </form>
        <form
          className="Text__Field_Container mt-2"
          onSubmit={(e) => onSubmit(e, 3)}
        >
          <Button
            type="submit"
            marginLeft={24}
            size="small"
            mode="validation"
            isDisabled={updateStudyRequestStatus.isPending}
          >
            {`${t("submit")}`}
          </Button>
        </form>
      </div>
    </div>
  )
}
