import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import EditDateModal from "components/Modal/EditDateModal"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import {
  useGetOperationById,
  useUpdateOperation,
} from "core/query-hooks/useOperations"
import { t } from "i18next"
import { BsBank2 } from "react-icons/bs"
import { MdAdd, MdEdit } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { AddBankModal } from "./AddBank"
import ListBank from "./ListBank"

export default function ObtainingLoans() {
  const { id } = useParams()
  const { data: operation } = useGetOperationById(+id!)
  const {
    isShowing: isDepositDateModalShowed,
    toggle: toggleDepositDateModal,
  } = useModal()

  const {
    isShowing: isBankApprovalModalShowed,
    toggle: toggleBankApprovalModal,
  } = useModal()

  const { isShowing: isAddBankModalShowed, toggle: toggleBank } = useModal()
  const queryClient = useQueryClient()
  const mutateOperation = useUpdateOperation(+id!)

  async function handleSubmitDepositDate(
    depositDate: Date | null | undefined,
    isDepositDateDefinitive: boolean | null,
  ) {
    mutateOperation.mutateAsync(
      { depositDate, isDepositDateDefinitive },
      {
        onSuccess() {
          toast.success(t("success-mutate-deposit-date"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
          toggleDepositDateModal()
        },
        onError() {
          toast.error(t("error-mutate-deposit-date"))
        },
      },
    )
  }

  async function handleSubmitBankApproval(
    bankApproval: Date | null | undefined,
    isBankApprovalDefinitive: boolean | null,
  ) {
    mutateOperation.mutateAsync(
      { bankApproval, isBankApprovalDefinitive },
      {
        onSuccess() {
          toast.success(t("success-mutate-bank-approval-date"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
          toggleBankApprovalModal()
        },
        onError() {
          toast.error(t("error-mutate-bank-approval-date"))
        },
      },
    )
  }

  return (
    <Box classNames="p-0 h-full">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-3 h-10">
        <BsBank2 className="text-cyan-900 mr-1" />
        <h3 className="text-xs uppercase text-cyan-900 font-medium">
          {t("obtaining-loans")}
        </h3>
      </div>
      <div className="Text__Field_Container px-4 mt-4 flex items-center justify-between">
        <PermissionsGuard requiredRoles={["read:bank"]}>
          <ListBank operationBank={operation?.operationData.bank} />
        </PermissionsGuard>
        <PermissionsGuard
          excludedRoles={["read:bank"]}
          requiredRoles={["read:operation"]}
        >
          <div className="w-full flex items-center justify-between text-primary-dark">
            <span className="text-xs">{t("bank")}</span>
            <span className="text-sm">
              {operation?.operationData?.bank?.label}
            </span>
          </div>
        </PermissionsGuard>
        <PermissionsGuard requiredRoles={["create:bank"]}>
          <ButtonIcon className="ml-2 -mt-1" type="button" onClick={toggleBank}>
            <MdAdd size={22} cursor="pointer" className="text-cyan-800" />
          </ButtonIcon>
          {isAddBankModalShowed && (
            <AddBankModal
              isAddBankModalShowed={isAddBankModalShowed}
              toggleBankModal={toggleBank}
            />
          )}
        </PermissionsGuard>
      </div>

      <ul className="py-2 pt-3 pb-4">
        <li className="px-4 flex items-center justify-between pb-1">
          <p className="text-body text-cyan-950">{t("file-submission")}</p>
          <span className="flex items-center">
            {operation?.operationData?.depositDate ? (
              <DateFormat
                isDefinitive={
                  operation?.operationData?.isDepositDateDefinitive!
                }
                date={new Date(operation?.operationData?.depositDate)}
              />
            ) : (
              "-"
            )}

            <ButtonIcon
              className="ml-2"
              type="button"
              onClick={toggleDepositDateModal}
            >
              <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          </span>
        </li>
        <li className="px-4 flex items-center justify-between pt-1">
          <p className="text-body text-cyan-950">{t("bank-agreement")}</p>
          <span className="flex items-center">
            {operation?.operationData?.bankApproval ? (
              <DateFormat
                isDefinitive={
                  operation?.operationData?.isBankApprovalDefinitive!
                }
                date={new Date(operation?.operationData?.bankApproval)}
              />
            ) : (
              "-"
            )}

            <ButtonIcon
              className="ml-2"
              type="button"
              onClick={toggleBankApprovalModal}
            >
              <MdEdit size={22} cursor="pointer" className="text-cyan-800" />
            </ButtonIcon>
          </span>
        </li>
      </ul>
      {isDepositDateModalShowed && (
        <EditDateModal
          isModalShowed={isDepositDateModalShowed}
          toggleModal={toggleDepositDateModal}
          initialDate={operation?.operationData?.depositDate}
          isDefinitive={
            operation?.operationData?.isDepositDateDefinitive || false
          }
          onSubmit={(depositDate, isDepositDateDefinitive) =>
            handleSubmitDepositDate(depositDate, isDepositDateDefinitive)
          }
          isPending={mutateOperation.isPending}
          title={t("edit-bank-deposit-date")}
          labelDate={t("label-bank-deposit-date")}
        />
      )}

      {isBankApprovalModalShowed && (
        <EditDateModal
          isModalShowed={isBankApprovalModalShowed}
          toggleModal={toggleBankApprovalModal}
          initialDate={operation?.operationData?.bankApproval}
          isDefinitive={
            operation?.operationData?.isBankApprovalDefinitive || false
          }
          onSubmit={(bankApproval, isBankApprovalDefinitive) =>
            handleSubmitBankApproval(bankApproval, isBankApprovalDefinitive)
          }
          isPending={mutateOperation.isPending}
          title={`${t("edit-bank-approval-date")}`}
          labelDate={t("label-bank-approval-date")}
        />
      )}
    </Box>
  )
}
