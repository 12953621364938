import Box from "components/Box/Box"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import { useListChildOperations } from "core/query-hooks/useOperations"
import GedTree from "features/ged/GedTree"
import OperationChildrenItemBuildingPermit from "features/operations/building-permits/OperationChildItemBuildingPermit"
import i18next, { t } from "i18next"
import { useParams } from "react-router-dom"
import { OPERATION_PROGRAM_VIEWTAG } from "shared/resources/view-tags.resources"
import DivStickyMenu from "./DivStickyMenu"

export default function OperationProgram() {
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  const registrationNumbers = operationsChildren?.map(
    (oc) => oc.registrationNumber,
  )

  if (isLoading) return <Spinner />

  if (error)
    return (
      <p>
        {`${i18next.t("error")}`} : {error.message}
      </p>
    )

  return (
    <div className="p-4">
      <DivStickyMenu />
      <Box>
        <Tabs isOperationChildren>
          {operationsChildren!.map((operation) => {
            return (
              <TabContent
                title={operation.registrationNumber}
                key={operation.registrationNumber}
              >
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="w-full md:w-3/4 md:mr-4 order-2 md:order-1">
                    <OperationChildrenItemBuildingPermit
                      childOperation={operation}
                    />
                  </div>
                  <div className="w-full md:w-1/4 order-1 md:order-2 mb-4 pt-2 md:mb-0">
                    {registrationNumbers && (
                      <>
                        <div className="mb-2">
                          <Header>{`${t("documents")}`}</Header>
                        </div>
                        <Box>
                          <GedTree
                            view={OPERATION_PROGRAM_VIEWTAG}
                            registrationNumbers={[operation.registrationNumber]}
                          />
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </TabContent>
            )
          })}
        </Tabs>
      </Box>
    </div>
  )
}
