import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TBuildingPermit,
  TBuildingPermitDate,
  TBuildingPermitDoc,
  TBuildingPermitLabel,
  TBuildingPermitStep,
  TMutateBuildingPermitDate,
  TMutateBuildingPermitDoc,
  TPermitsByOperation,
  TUpdateBuildingPermitDate,
} from "shared/types/building-permit.type"
import {
  addBuildingPermitDate,
  addBuildingPermitDateMod,
  addBuildingPermitDocument,
  listBuildingPermitDocuments,
  listBuildingPermitLabel,
  listBuildingPermits,
  listBuildingPermitsByOperation,
  listBuildingPermitStep,
  updateBuildingPermitDate,
} from "../api/buildingPermit"

function useListBuildingPermits(childOperationId: number) {
  return useQuery<TBuildingPermit[], CustomAxiosError>({
    queryKey: ["listBuildingPermits", childOperationId],
    queryFn: () => listBuildingPermits(childOperationId),
  })
}

function useListBuildingPermitsByOperation(operationId: number) {
  return useQuery<TPermitsByOperation[], CustomAxiosError>({
    queryKey: ["listBuildingPermitsByOperation", operationId],
    queryFn: () => listBuildingPermitsByOperation(operationId),
  })
}

function useListBuildingPermitStep() {
  return useQuery<TBuildingPermitStep[], CustomAxiosError>({
    queryKey: ["listBuildingPermitStep"],
    queryFn: () => listBuildingPermitStep(),
  })
}

function useListBuildingPermitLabel() {
  return useQuery<TBuildingPermitLabel[], CustomAxiosError>({
    queryKey: ["listBuildingPermitLabel"],
    queryFn: () => listBuildingPermitLabel(),
  })
}

function useListBuildingPermitDocuments(childOperationId: number) {
  return useQuery<TBuildingPermitDoc[], CustomAxiosError>({
    queryKey: ["listBuildingPermitDocuments", childOperationId],
    queryFn: () => listBuildingPermitDocuments(childOperationId),
  })
}

function useAddBuildingPermitDocument() {
  return useMutation<
    TBuildingPermitDoc,
    CustomAxiosError,
    TMutateBuildingPermitDoc
  >({
    mutationFn: (buildingPermitDac: TMutateBuildingPermitDoc) =>
      addBuildingPermitDocument(buildingPermitDac),
  })
}

function useAddBuildingPermitDate() {
  return useMutation<
    TBuildingPermitDate,
    CustomAxiosError,
    TMutateBuildingPermitDate
  >({
    mutationFn: (buildingPermitDate: TMutateBuildingPermitDate) =>
      addBuildingPermitDate(buildingPermitDate),
  })
}

function useAddBuildingPermitDateMod() {
  return useMutation<
    TBuildingPermitDate,
    CustomAxiosError,
    TMutateBuildingPermitDate
  >({
    mutationFn: (buildingPermitDate: TMutateBuildingPermitDate) =>
      addBuildingPermitDateMod(buildingPermitDate),
  })
}

function useUpdateBuildingPermitDate(buildingPermitDateId: number) {
  return useMutation<
    TBuildingPermitDate,
    CustomAxiosError,
    TUpdateBuildingPermitDate
  >({
    mutationFn: (buildingPermitDate: TUpdateBuildingPermitDate) =>
      updateBuildingPermitDate(buildingPermitDateId, buildingPermitDate),
  })
}

export {
  useAddBuildingPermitDate,
  useAddBuildingPermitDateMod,
  useAddBuildingPermitDocument,
  useListBuildingPermitDocuments,
  useListBuildingPermitLabel,
  useListBuildingPermits,
  useListBuildingPermitsByOperation,
  useListBuildingPermitStep,
  useUpdateBuildingPermitDate,
}
