import classNames from "classnames"
import ButtonIcon from "components/Button/ButtonIcon"
import Spinner from "components/Spinner/Spinner"
import useGetSubfolders from "core/ged/query-hooks/useMetadata"
import { t } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { RiFolderAddFill } from "react-icons/ri"
import { useDebouncedCallback } from "use-debounce"

export interface ISubfolder2FieldProps {
  subfolder1: string | undefined
  onSelectSubfolder2: (selectedSubfolder: string) => void
  registrationNumbers: string[]
}

export function Subfolder2Field(props: ISubfolder2FieldProps) {
  const { subfolder1, onSelectSubfolder2, registrationNumbers } = props
  const [selectedSubfolder, setSelectedSubfolder] = useState<string>("")
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [canAddNewFolder, setCanAddNewFolder] = useState<boolean>(false)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setSelectedSubfolder(selectedValue)
    onSelectSubfolder2(selectedValue)
  }

  const {
    data: subfolders2,
    isLoading,
    error,
  } = useGetSubfolders("SOUS_DOSSIER_2", subfolder1!, registrationNumbers)

  useEffect(() => {
    setSelectedSubfolder("")
    onSelectSubfolder2("")
    setIsDisabled(false)
    if (error) setIsDisabled(true)
  }, [subfolder1])

  const onHandleCreate = useDebouncedCallback(
    useCallback(
      (value: string) => {
        setSelectedSubfolder(value)
        onSelectSubfolder2(value)
      },
      [selectedSubfolder],
    ),
    500,
  )

  return (
    <div>
      <label htmlFor="subfolder-2-select">
        <div className="flex items-center py-1">
          {`${t("subfolder")} 2 :`}
          {!!subfolder1 && (
            <ButtonIcon
              onClick={() => setCanAddNewFolder(!canAddNewFolder)}
              className="ml-2 -mt-1"
              type="button"
            >
              <RiFolderAddFill
                size={16}
                cursor="pointer"
                className="Text__Pierreval_Green"
              />
            </ButtonIcon>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <select
            id="subfolder-2-select"
            name="subfolder-2"
            value={selectedSubfolder}
            onChange={handleSelectChange}
            disabled={
              isDisabled || !subfolders2 || subfolders2.subfolders.length === 0
            }
            className={classNames(
              "Select__Field Text__Field flex items-center justify-center disabled:opacity-40 Select__Wrapper",
            )}
          >
            <option value="">{`${t("no-subfolder-selected")}`}</option>
            {subfolders2 && subfolders2.subfolders.length > 0
              ? subfolders2?.subfolders.map((subfolder) => (
                  <option key={subfolder} value={subfolder}>
                    {subfolder}
                  </option>
                ))
              : null}
          </select>
        )}
      </label>
      {canAddNewFolder && !!subfolder1 && (
        <div className="Text__Field_Container">
          <input
            id="sf2"
            className="Text__Field"
            onChange={(e) => onHandleCreate(e.target.value)}
            type="text"
            placeholder={`${t("add-subfoler")} 2`}
          />
        </div>
      )}
    </div>
  )
}
