import Box from "components/Box/Box"
import DateFormat from "components/Date/DateFormat"
import Spinner from "components/Spinner/Spinner"
import { useListBuildingPermitsByOperation } from "core/query-hooks/useBuildingPermits"
import { t } from "i18next"
import { RiCompasses2Line } from "react-icons/ri"
import { NavLink } from "react-router-dom"

export interface IBuildingPermitSteeringTableProps {
  operationId: number
}

export function BuildingPermitSteeringTable(
  props: IBuildingPermitSteeringTableProps,
) {
  const { operationId } = props
  const {
    data: buildingPermitsData,
    isLoading,
    error,
  } = useListBuildingPermitsByOperation(operationId)

  return (
    <Box classNames="pt-0 px-0 pb-1">
      <NavLink
        className="flex items-center border-b border-gray-200 px-3.5 py-2 justify-between h-10"
        to="program"
        type="button"
      >
        <div className="flex items-center text-cyan-900 mr-1">
          <RiCompasses2Line size={22} />
          <h3 className="text-xs uppercase font-medium pl-1">
            {t("assembly")}
          </h3>
        </div>
      </NavLink>
      <div className="w-full px-2 pb-2 overflow-auto">
        {isLoading && (
          <div className="flex items-center justify-center py-8">
            <Spinner />
          </div>
        )}
        {error && (
          <div className="flex items-center justify-center py-8">
            <p className="text-sm text-red-500">
              {`${t("error-loading-dates-planning")}`}
            </p>
          </div>
        )}
        {buildingPermitsData && buildingPermitsData.length > 0 ? (
          <table className="w-full text-center">
            <thead>
              <tr>
                <th />
                <th />
                <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                  {t("deposit")}
                </th>
                <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                  {t("obtain")}
                </th>
                <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                  {t("display-1")}
                </th>
                <th className="p-2 text-sm leading-5 text-primary-light font-semibold">
                  {t("purge")}
                </th>
                <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                  {t("appeal")}
                </th>
                <th className="p-3 text-sm leading-5 text-primary-light font-semibold">
                  {t("lifting")}
                </th>
              </tr>
            </thead>
            <tbody>
              {buildingPermitsData.map((permit, permitIndex) => (
                <>
                  {permit.permitsDates.map((permitDate, index) => (
                    <tr
                      key={`${permit.childOperationId}-${permitDate.buildingPermitLabel.id}`}
                    >
                      {index === 0 ? (
                        <td className="px-1 py-2 text-primary-dark font-semibold">
                          {permit.searchableRegistrationNumber}
                        </td>
                      ) : (
                        <td />
                      )}
                      <td className="px-1 py-2">
                        {permitDate.buildingPermitLabel.label}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.depot?.date ? (
                          <DateFormat
                            date={new Date(permitDate.depot.date)}
                            isDefinitive={permitDate.depot.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.obtention?.date ? (
                          <DateFormat
                            date={new Date(permitDate.obtention.date)}
                            isDefinitive={permitDate.obtention.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.affichage1?.date ? (
                          <DateFormat
                            date={new Date(permitDate.affichage1.date)}
                            isDefinitive={permitDate.affichage1.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.purge?.date ? (
                          <DateFormat
                            date={new Date(permitDate.purge.date)}
                            isDefinitive={permitDate.purge.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.recours?.date ? (
                          <DateFormat
                            date={new Date(permitDate.recours.date)}
                            isDefinitive={permitDate.recours.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {permitDate.levee?.date ? (
                          <DateFormat
                            date={new Date(permitDate.levee.date)}
                            isDefinitive={permitDate.levee.isDefinitive}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                  {permitIndex < buildingPermitsData.length - 1 && (
                    <tr>
                      <td colSpan={8}>
                        <hr className="border-t border-gray-300" />
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center py-8">
            {!error && (
              <p className="text-sm text-primary-dark">{`${t("no-permit-date")}`}</p>
            )}
          </div>
        )}
      </div>
    </Box>
  )
}
