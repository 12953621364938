import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import { useListTranchesTravauxWithBailleurs } from "core/spo/query-hooks/useTranches"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { MdDateRange } from "react-icons/md"
import { TChildOperation } from "shared/types/operation.type"
import { LessorsFilter } from "./LessorsFilter"
import { ProductionTrackingTable } from "./ProductionTrackingTable"

export interface IProductionTrackingProps {
  childOperation: TChildOperation
}

export function ProductionTracking(props: IProductionTrackingProps) {
  const { childOperation } = props
  const {
    data: tranchesTravaux,
    isLoading,
    error,
  } = useListTranchesTravauxWithBailleurs(childOperation.registrationNumber)
  const [selectedTrancheTravaux, setSelectedTrancheTravaux] = useState<
    string | null
  >(
    tranchesTravaux && tranchesTravaux.length > 0
      ? tranchesTravaux[0].code
      : null,
  )
  const [selectedLessor, setSelectedLessor] = useState<string | null>(null)

  const handleTabChange = (selectedTabId: string) => {
    setSelectedTrancheTravaux(selectedTabId)
    setSelectedLessor(null)
  }

  useEffect(() => {
    if (tranchesTravaux && tranchesTravaux.length > 0) {
      setSelectedTrancheTravaux(tranchesTravaux[0].code)
    }
  }, [tranchesTravaux])

  return (
    <Box classNames="mt-4 p-0">
      <div className="flex items-center justify-start border-b border-gray-200 px-4 py-3">
        <div className="flex items-center text-cyan-900 font-medium">
          <MdDateRange />
          <h3 className="text-xs uppercase pl-2">
            {t("production-follow-up")}
          </h3>
        </div>
      </div>
      {isLoading && (
        <div className="w-full flex justify-center py-4">
          <Spinner />
        </div>
      )}
      {tranchesTravaux && tranchesTravaux.length > 0 ? (
        <div className="p-1">
          <Tabs onTabChange={handleTabChange}>
            {tranchesTravaux.map((trancheT) => {
              return (
                <TabContent
                  title={trancheT.label}
                  key={trancheT.code}
                  value={trancheT.code}
                >
                  <>
                    <LessorsFilter
                      lessors={trancheT.lessors}
                      selectedLessor={selectedLessor}
                      setSelectedLessor={setSelectedLessor}
                    />
                    {selectedTrancheTravaux !== null && (
                      <ProductionTrackingTable
                        trancheTravaux={selectedTrancheTravaux}
                        lessor={selectedLessor}
                        childOperation={childOperation}
                      />
                    )}
                  </>
                </TabContent>
              )
            })}
          </Tabs>
        </div>
      ) : (
        <div className="w-full flex justify-center py-4 text-primary-dark">
          {t("no-tranche-travaux-found")} {error && `(${error.code})`}
        </div>
      )}
    </Box>
  )
}
