import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { DeleteModal } from "components/DeleteModal/DeleteModal"
import useDeleteModal from "components/DeleteModal/useDeleteModal"
import Map from "components/Map/Map"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useDeleteProject, useProject } from "core/query-hooks/useProjects"
import { CreateOperationModal } from "features/operations/CreateOperationModal"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useTranslation } from "react-i18next"
import { TbMapPin } from "react-icons/tb"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { getDepartmentFromPostalCode } from "utils/departments"
import { ContributorsPreview } from "../contributors/ContributorsPreview"
import FormatProjectChildren from "./FormatProjectChildren"
import { ProjectBuSector } from "./bu-sector/ProjectBuSector"
import ProjectStepSelect from "./steps/ProjectStepSelect"

function ProjectHome() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isShowing, toggle } = useModal()
  const deleteProject = useDeleteProject(id!)
  const queryClient = useQueryClient()
  const { data: projectData } = useProject(id!)
  const { isShowing: isModalShowed, toggle: toggleModal } = useDeleteModal()
  const onDeleteProject = async () => {
    await deleteProject.mutateAsync(id!, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["listProjects"] })
        toast.success(`${t("project-delete-toast")}`)
      },
      onError: (err) => {
        toast.error(`${err.response?.data.error.message}, ${id}`)
      },
    })
    toggleModal()
    navigate(`/programs/search`)
  }

  if (!id) {
    return <p>{`${t("project-noProjectFound")}`}</p>
  }

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="grid grid-rows-1 md:grid-cols-4 md:grid-flow-col md:gap-4 p-4 grid-flow-row-dense">
        <div className="col-span-3">
          <Box classNames="p-0 overflow-hidden">
            <div className="flex items-center border-b border-gray-200 px-3.5 py-2">
              <TbMapPin size={20} className="text-cyan-900 mr-1" />
              <h3 className="text-xs uppercase text-cyan-900 font-medium">
                {`${t("map")}`}
              </h3>
            </div>
            {projectData?.address &&
              projectData?.postcode &&
              projectData?.city && (
                <div>
                  <Map
                    location={`${projectData?.address} ${projectData?.postcode} ${projectData?.city}`}
                  />
                </div>
              )}
          </Box>
        </div>
        <div className="col-span-1">
          <ProjectStepSelect />
          {projectData?.postcode && (
            <div className="mt-2">
              <ProjectBuSector
                departmentId={getDepartmentFromPostalCode(projectData.postcode)}
                businessUnitId={projectData.businessUnit.id}
              />
            </div>
          )}
          <div className="mt-2">
            <ContributorsPreview projectId={+id} />
          </div>
          <div className="flex items-center mt-4 justify-between">
            <PermissionsGuard requiredRoles={["create:project.registration"]}>
              <div>
                <Button onClick={toggle} size="medium" mode="primary">
                  {t("register-operation")}
                </Button>
                {isShowing && (
                  <CreateOperationModal
                    projectId={+id}
                    isShowing={isShowing}
                    toggleModal={() => toggle()}
                    businessUnitId={projectData?.businessUnit.id!}
                  />
                )}
              </div>
            </PermissionsGuard>
            <PermissionsGuard requiredRoles={["delete:project"]}>
              <div>
                <Button onClick={toggleModal} size="medium" mode="secondary">
                  {`${t("delete")}`}
                </Button>
              </div>
            </PermissionsGuard>
          </div>
        </div>

        <DeleteModal
          isShowing={isModalShowed}
          closeModal={toggleModal}
          title={`${t("project-delete-title")}`}
          deleteItem={() => onDeleteProject()}
          loading={deleteProject.isPending}
        >
          <FormatProjectChildren projectId={id} />
        </DeleteModal>
      </div>
    </>
  )
}

export default ProjectHome
