import Spinner from "components/Spinner/Spinner"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { t } from "i18next"
import { TBusinessUnit } from "shared/types/business-unit.type"
import DropdownFilters from "../DropdownFilter"
import useProgramsSearch from "../useProgramsSearch"

export default function RegionsFilter() {
  const {
    data: businessUnitsData,
    isLoading: isLoadingBu,
    error: errorBu,
  } = useListBusinessUnits()

  const { state, dispatch } = useProgramsSearch()
  const { regions } = state.filters

  if (isLoadingBu) return <Spinner />

  if (errorBu || !businessUnitsData)
    return <p>{errorBu?.response?.data.message}</p>

  const allBusinessUnitsOption = {
    id: -1,
    label: t("select-all"),
  }

  const optionsWithAll = [allBusinessUnitsOption, ...businessUnitsData]

  const handleBuClick = (
    region: Omit<TBusinessUnit, "active" | "countryId">,
  ) => {
    if (region.id === -1) {
      const shouldSelectAll = regions.length !== businessUnitsData.length
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...state.filters,
          regions: shouldSelectAll
            ? businessUnitsData.map((item) => item.id)
            : [],
          skip: 0,
          page: 1,
        },
      })
    } else {
      const isRegionSelected = (state.filters.regions as number[])?.includes(
        region.id,
      )

      if (isRegionSelected) {
        dispatch({
          type: "SET_FILTERS",
          payload: {
            ...state.filters,
            regions: state.filters.regions?.filter(
              (regionId) => regionId !== region.id,
            ),
            skip: 0,
            page: 1,
          },
        })
      } else {
        dispatch({
          type: "SET_FILTERS",
          payload: {
            ...state.filters,
            regions: [...state.filters.regions, region.id],
            skip: 0,
            page: 1,
          },
        })
      }
    }
  }

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full w-300">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("bu")}
              {regions.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-pink-800 bg-pink-100 rounded-full">
                  {regions.length}
                </div>
              )}
            </div>
          )}
          options={optionsWithAll}
          renderOption={(region, index) => (
            <div role="menu">
              <li
                onClick={() => handleBuClick(region)}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                key={`menu-item-${index}`}
              >
                <input
                  aria-label="Select region"
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    region.id === -1
                      ? businessUnitsData.length === regions.length
                      : !!state.filters.regions?.find(
                          (regionId) => regionId === region.id,
                        )
                  }
                />
                {region.label}
              </li>
            </div>
          )}
          keyExtractor={(region) => region.id}
        />
      </div>
    </div>
  )
}
