import classnames from "classnames"
import Spinner from "components/Spinner/Spinner"
import { useListOperationsDcNextThreeMonths } from "core/query-hooks/useOperations"
import { addMonths } from "date-fns"
import { t } from "i18next"
import CountUp from "react-countup"
import { RiTaskLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

export interface INextThreeMonthsSpoBlockProps {
  businessUnitId?: string
}

export function NextThreeMonthsBuildingPermits(
  props: INextThreeMonthsSpoBlockProps,
) {
  const { businessUnitId } = props
  const inThreeMonths = addMonths(new Date(), 3)

  const navigate = useNavigate()
  const {
    data: nextThreeMonthsInfos,
    isPending,
    error,
  } = useListOperationsDcNextThreeMonths(
    businessUnitId ? +businessUnitId : undefined,
    inThreeMonths,
  )

  return (
    <div
      className={classnames(
        "border rounded-lg shadow-lg border-gray-200 px-3.5 py-2 h-28 bg-white relative flex flex-col justify-between cursor-pointer",
      )}
      onClick={() =>
        !error
          ? navigate(
              `/next-three-months/dpc${
                businessUnitId ? `/${businessUnitId}` : ""
              }`,
              {
                state: {
                  operations: nextThreeMonthsInfos,
                  title: t("depots-pc"),
                },
              },
            )
          : null
      }
    >
      <h3
        className={classnames(
          "text-xs uppercase text-cyan-950 font-medium flex items-start justify-start",
        )}
      >
        <div className="h-5 w-5">
          <RiTaskLine size={18} />
        </div>
        <p className="ml-2">{`${t("depots-pc")}`}</p>
      </h3>
      {isPending ? (
        <div className="flex flex-col items-center mt-auto pb-4">
          <Spinner />
        </div>
      ) : null}
      {error ? (
        <div className="flex flex-col items-center mt-auto pb-4">
          <p className="text-xs text-red-500">{t("data-error")}</p>
        </div>
      ) : null}
      <p className={classnames("text-5xl text-center Color__Secondary")}>
        {nextThreeMonthsInfos &&
        nextThreeMonthsInfos.metadata.maxTotalHits !== 0 ? (
          <CountUp
            end={nextThreeMonthsInfos.metadata.maxTotalHits}
            duration={1.5}
          />
        ) : (
          <>{!isPending && !error ? 0 : null}</>
        )}
      </p>
    </div>
  )
}
