import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components/Button/Button"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import WatchChangesBar from "components/WatchChangesBar/WatchChangesBar"
import { useCreatePlot, useGetPlots } from "core/query-hooks/usePlots"
import currency from "currency.js"
import { format } from "date-fns"
import FormCreate from "features/plots/Form/FormCreate"
import i18n from "i18next"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import PLOT_EXISTING_ERROR from "shared/resources/plot.resources"
import { TCurrency } from "shared/types/global.type"
import { TMutatePlot } from "shared/types/plot.type"
import * as yup from "yup"

export type TCreatePlotForm = {
  prefix: string
  section: string
  number: string
  area: {
    val: number
    unit: string
  }
  price: {
    val: number
    unit: TCurrency
  }
  projectId: number
  owner?: string
}

interface DuplicatePlotError {
  city: string
  dateOfCreation: string
  developer: {
    accountEnabled: boolean
    firstName: string
    lastName: string
  }
  errorType: string
  message: string
  denunciationDate: Date
  projectName: string
  isNovalysRelated: boolean
}

interface IPlotCreateProps {
  projectContext: boolean
}

export function PlotCreate(props: IPlotCreateProps) {
  const { projectContext } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id, agreementId } = useParams()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const [openModalUniqPlotMigration, setOpenModalUniqPlotMigration] =
    useState<boolean>(false)
  const [openModalUniqPlot, setOpenModalUniqPlot] = useState<boolean>(false)
  const [duplicatePlotErrorMigration, setDuplicatePlotErrorMigration] =
    useState<
      | (DuplicatePlotError & {
          prefix: string
          section: string
          number: string
          inseeCode: string
        })
      | null
    >(null)
  const [duplicatePlotError, setDuplicatePlotError] = useState<
    | (DuplicatePlotError & {
        prefix: string
        section: string
        number: string
        inseeCode: string
      })
    | null
  >(null)

  const redirectToPlots = () => {
    projectContext
      ? navigate(`/project/${id}/plots`)
      : navigate(`/operation/${id}/agreements/${agreementId}/plots`)

    return toggleModal
  }

  const createSchema = yup.object({
    prefix: yup
      .string()
      .matches(/^[0-9]+$/, `${i18n.t("plots-prefixTypeError")}`)
      .required(`${i18n.t("plots-form-prefix")}`)
      .max(3, `${i18n.t("plots-prefixLengthError")}`),
    section: yup
      .string()
      .required(`${i18n.t("plots-form-section")}`)
      .max(3, `${i18n.t("plots-sectionLengthError")}`),
    number: yup
      .string()
      .matches(/^[0-9]+$/, `${i18n.t("plots-numberTypeError")}`)
      .required(`${i18n.t("plots-form-number")}`)
      .max(4, `${i18n.t("plots-numberLengthError")}`),
    area: yup.object().shape({
      val: yup
        .number()
        .transform((e) => (Number.isNaN(e) ? undefined : e))
        .required(`${i18n.t("plots-form-surface")}`)
        .integer(),
    }),
    owner: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
  })
  const createPlot = useCreatePlot()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<TCreatePlotForm>({
    resolver: yupResolver(createSchema as yup.ObjectSchema<TCreatePlotForm>),
  })

  const redirectToPlotsBtn = () => {
    if (isDirty) return toggleModal()
    return projectContext
      ? navigate(`/project/${id}/plots`)
      : navigate(`/operation/${id}/agreements/${agreementId}/plots`)
  }
  const plotFound = useGetPlots(id!)
  const normalizeNumber = (number: string) => number.replace(/^0+/, "")
  const onSubmitPlotAdd = (data: TCreatePlotForm) => {
    createPlot.mutate(
      {
        ...data,
        area: {
          val: data.area.val,
          unit: "m²",
        },
        price: {
          val: Number(currency(data.price.val)),
          unit: "EUR",
        },
        projectId: parseFloat(id!),
      },
      {
        onSuccess: () => {
          projectContext
            ? navigate(`/project/${id}/plots`)
            : navigate(`/operation/${id}/agreements/${agreementId}/plots`)
        },
        onError: (error) => {
          if (error?.response?.statusText === "Conflict") {
            const duplicatePlotErrorObj: DuplicatePlotError & {
              prefix: string
              section: string
              number: string
              inseeCode: string
            } = {
              ...(error?.response?.data as unknown as DuplicatePlotError & {
                inseeCode: string
              }),
              prefix: data.prefix,
              number: data.number,
              isNovalysRelated:
                error?.response?.data?.errorType === PLOT_EXISTING_ERROR,
              section: data.section,
            }

            if (plotFound.data?.data && Array.isArray(plotFound.data.data)) {
              const plots = plotFound.data.data

              const isPlotExists = plots.some(
                (plot) =>
                  plot.prefix === duplicatePlotErrorObj.prefix &&
                  plot.section === duplicatePlotErrorObj.section &&
                  normalizeNumber(plot.number) === duplicatePlotErrorObj.number,
              )

              if (isPlotExists) {
                setDuplicatePlotError(duplicatePlotErrorObj)
                setOpenModalUniqPlot(true)
              } else {
                setDuplicatePlotErrorMigration(duplicatePlotErrorObj)
                setOpenModalUniqPlotMigration(true)
              }
            } else {
              toast.error(
                `${t("an-error-has-occurred")} : ${error?.response?.statusText}`,
              )
            }
          }
        },
      },
    )
  }

  const onHideModalUniqPlotMigration = () => {
    setOpenModalUniqPlotMigration(false)
    setDuplicatePlotErrorMigration(null)
  }

  const onHideModalUniqPlot = () => {
    setOpenModalUniqPlot(false)
    setDuplicatePlotError(null)
  }

  const onFormSubmit = (data: TCreatePlotForm) => {
    onSubmitPlotAdd(data)
  }

  return (
    <>
      {isDirty && (
        <WatchChangesBar
          onSaveAction={{
            onAction() {
              handleSubmit((data: TMutatePlot) => {
                onSubmitPlotAdd(data)
              })()
            },
            content: t("create"),
            isLoading: false,
          }}
          onDiscardAction={{
            onAction(): void {
              toggleModal()
            },
            content: `${t("cancel")}`,
          }}
          message={`${t("plots-form-watchBar-message")}`}
        />
      )}
      <div className="Padding__md_24">
        <FormCreate
          handleSubmit={handleSubmit}
          register={register}
          onceSubmited={(data) => onFormSubmit(data)}
          errors={errors}
          control={control}
        >
          <Button
            onClick={() => redirectToPlotsBtn()}
            size="medium"
            mode="secondary"
            isLoading={false}
          >
            {`${t("cancel")}`}
          </Button>
        </FormCreate>
        <Modal
          description={`${t("plots-delete-description")}`}
          isShowing={isModalShowed}
          closeModal={toggleModal}
          title={`${t("cancelModal-title")}`}
        >
          <div className="Modal__Footer">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => toggleModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              marginLeft={24}
              size="medium"
              mode="danger"
              isLoading={false}
              onClick={() => redirectToPlots()}
            >
              {`${t("confirm")}`}
            </Button>
          </div>
        </Modal>
        <Modal
          title={t("plots-modal-title")}
          description={`${t("plots-modal-description-migration", {
            prefixe: duplicatePlotErrorMigration?.prefix,
            section: duplicatePlotErrorMigration?.section,
            numero: duplicatePlotErrorMigration?.number,
            cityName: duplicatePlotErrorMigration?.city,
          })}`}
          isShowing={openModalUniqPlotMigration}
          closeModal={onHideModalUniqPlotMigration}
        >
          <div className="Modal__Footer">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={onHideModalUniqPlotMigration}
            >
              {`${t("cancel")}`}
            </Button>
          </div>
        </Modal>
        <Modal
          title={t("plots-modal-title")}
          description={
            duplicatePlotError?.isNovalysRelated
              ? `${t("plots-modal-novalys-description", {
                  prefixe: duplicatePlotError?.prefix,
                  section: duplicatePlotError?.section,
                  numero: duplicatePlotError?.number,
                  inseeCode: duplicatePlotError?.inseeCode,
                  denunciationDate: duplicatePlotError?.denunciationDate
                    ? format(
                        new Date(duplicatePlotError?.denunciationDate!),
                        "dd/MM/yyyy",
                      )
                    : "",
                })}`
              : `${t("plots-modal-description", {
                  prefixe: duplicatePlotError?.prefix,
                  section: duplicatePlotError?.section,
                  numero: duplicatePlotError?.number,
                  cityName: duplicatePlotError?.city,
                  devName: `${duplicatePlotError?.developer?.firstName} ${duplicatePlotError?.developer?.lastName}`,
                  isActive: duplicatePlotError?.developer?.accountEnabled
                    ? ""
                    : t("plots-modal-accountEnabled"),
                  createdAt: duplicatePlotError?.dateOfCreation
                    ? format(
                        new Date(duplicatePlotError?.dateOfCreation!),
                        "dd/MM/yyyy",
                      )
                    : "",
                  denunciationDate: duplicatePlotError?.denunciationDate
                    ? format(
                        new Date(duplicatePlotError?.denunciationDate!),
                        "dd/MM/yyyy",
                      )
                    : "",
                  projectName: duplicatePlotError?.projectName,
                })}`
          }
          isShowing={openModalUniqPlot}
          closeModal={onHideModalUniqPlot}
        >
          <div className="Modal__Footer">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={onHideModalUniqPlot}
            >
              {`${t("cancel")}`}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  )
}
