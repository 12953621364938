const PERMIT_STEP = {
  DEPOT: { id: 1, label: "Dépôt", documentTypeId: 598, order: 1 },
  DEPOT_PIECES_COMPLEMENTAIRES: {
    id: 2,
    label: "Dépôt pièces complémentaires",
    documentTypeId: 603,
    order: 2,
  },
  OBTENTION: { id: 3, label: "Obtention", documentTypeId: 604, order: 3 },
  REFUS: { id: 4, label: "Refus", documentTypeId: 772, order: 4 },
  CONSTAT_AFFICHAGE_1: {
    id: 5,
    label: "Constat Affichage 1",
    documentTypeId: 609,
    order: 5,
  },
  CONSTAT_AFFICHAGE_2: {
    id: 6,
    label: "Constat Affichage 2",
    documentTypeId: 609,
    order: 6,
  },
  CONSTAT_AFFICHAGE_3: {
    id: 7,
    label: "Constat Affichage 3",
    documentTypeId: 609,
    order: 7,
  },
  RECOURS_GRACIEUX: {
    id: 8,
    label: "Recours gracieux",
    documentTypeId: 791,
    order: 8,
  },
  RECOURS_CONTENTIEUX: {
    id: 9,
    label: "Recours contentieux",
    documentTypeId: 792,
    order: 10,
  },
  LEVEE: { id: 10, label: "Levée", documentTypeId: 792, order: 11 },
  PURGE: { id: 11, label: "Purge", documentTypeId: 830, order: 12 },
  REJET_RECOURS_GRACIEUX: {
    id: 12,
    label: "Rejet recours gracieux",
    documentTypeId: 791,
    order: 9,
  },
}
export default PERMIT_STEP
