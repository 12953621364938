import classNames from "classnames"
import { forwardRef, useEffect, useState } from "react"
import { NumberFormatBase } from "react-number-format"
import { TCurrency } from "../../shared/types/global.type"
import "./TextField.scss"

type InputType =
  | "text"
  | "email"
  | "number"
  | "currency"
  | "textarea"
  | "currencyTest"
  | "checkbox"
  | "date"
  | "select"

function MyCustomNumberFormat(props: any) {
  const { intlConfig } = props

  const format = (numStr: any) => {
    if (numStr === "") return ""
    return new Intl.NumberFormat(intlConfig.locale, {
      style: "currency",
      currency: intlConfig.currency,
      maximumFractionDigits: 0,
    }).format(numStr)
  }
  return <NumberFormatBase {...props} format={format} />
}

export type TextFieldsProps = {
  label: string
  type: InputType
  ref?: React.Ref<any>
  placeholder?: string
  name: string
  props?: any
  className?: string
  id: string
  value?: string
  onChange?: (value: string) => void
  priceValue?: {
    value?: number
    unit: TCurrency
  }

  setPriceValue?: React.Dispatch<
    React.SetStateAction<{
      value?: number
      unit: TCurrency
    }>
  >
}

const options: { locale: string; currency: TCurrency }[] = [
  {
    locale: "fr-FR",
    currency: "EUR",
  },
  {
    locale: "fr-FR",
    currency: "VND",
  },
]

const TextField = forwardRef<HTMLInputElement, TextFieldsProps>(
  (
    {
      className,
      setPriceValue,
      priceValue,
      label,
      type = "text",
      placeholder,
      name,
      id,
      value,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [intlConfig, setIntlConfig] = useState<{
      locale: string
      currency: TCurrency
    }>(options[0])
    const [, setValuesObj] = useState<{
      formattedValue?: string
      value?: string
      floatValue?: number
    }>({})

    useEffect(() => {
      setValuesObj({ value: `${priceValue?.value}` })
      if (priceValue) {
        const selectedCurrencyIndex = options.findIndex(
          (elm) => elm.currency === priceValue.unit,
        )
        const config = options[Number(selectedCurrencyIndex)]
        if (config) {
          setIntlConfig(config)
        }
      }
    }, [priceValue, intlConfig])

    return type === "currency" ? (
      <div className="Flex Items__Center Gap__8">
        <div style={{ flexGrow: 4 }}>
          <MyCustomNumberFormat
            id={id}
            ref={ref as React.Ref<HTMLInputElement>}
            name={name}
            value={priceValue?.value}
            getInputRef={ref}
            type="text"
            intlConfig={intlConfig}
            className={classNames(["Text__Field", className])}
            aria-label={label}
            placeholder={placeholder}
            onValueChange={(values: any) => {
              setValuesObj(values)
              if (setPriceValue) {
                setPriceValue(() => ({
                  value: values.floatValue,
                  unit: priceValue?.unit ?? "EUR",
                }))
              }
            }}
            {...props}
          />
        </div>
      </div>
    ) : (
      <div>
        <input
          name={name}
          value={value}
          ref={ref as React.Ref<HTMLInputElement>}
          type={type}
          aria-label={label}
          className={classNames(["Text__Field", className])}
          id={id}
          placeholder={placeholder}
          {...props}
        />
      </div>
    )
  },
)

export { TextField }
