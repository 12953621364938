import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Button } from "components/Button/Button"
import { Modal } from "components/Modal/Modal"
import {
  EhancedFile,
  UploadFieldDrop,
} from "components/UploadField/UploadFieldDrop"
import { AuthContext } from "core/auth/AuthProvider"
import { getDocumentsById } from "core/ged/api/documents"
import { usePostDocumentToGed } from "core/ged/query-hooks/useGed"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { t } from "i18next"
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useForm } from "react-hook-form"
import { IoWarningOutline } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TGedUploadResponse } from "shared/types/ged.type"
import * as yup from "yup"
import { SelectFile } from "./SelectFile"
import {
  DOC_DEVELOPEMENT,
  MAX_SIZED_BYTES,
  UNAUTHORIZED_EXTENSIONS,
} from "./ged.resources"

const schema = yup
  .object({
    documentName: yup.string(),
    documentType: yup.string(),
    workflowStatus: yup.string().nullable(),
    documentStatus: yup.string().nullable(),
    numStatus: yup.string().nullable(),
  })
  .required()

export default function AddDocumentsModalWithoutChapters({
  toggleModal,
  isModalShowed,
  documentTypes,
  maxFiles = 10,
  setFiles,
  handleGetDocumentsId,
  isProjectStudyRequestRelated = false,
  numStatus = "0",
  files,
  linkedFiles,
  registrationNumber,
}: {
  isModalShowed: boolean
  toggleModal: () => void
  documentTypes: string[]
  maxFiles?: number
  setFiles?: Dispatch<SetStateAction<TGedUploadResponse[]>>
  handleGetDocumentsId?: (docId: number) => Promise<void>
  isProjectStudyRequestRelated?: boolean
  numStatus?: string
  files?: TGedUploadResponse[]
  linkedFiles?: boolean
  registrationNumber?: string
}) {
  const { getUserInfo } = useContext(AuthContext)
  const userInfo = getUserInfo()
  const { data: user } = useGetUserByAzureId(userInfo.azureId)
  const queryClient = useQueryClient()
  const [myFiles, setMyFiles] = useState<EhancedFile[]>([])
  const [documentName, setDocumentName] = useState<string>()
  const uploadDocumentToGed = usePostDocumentToGed()
  const [isDocumentNameEditable, setIsDocumentNameEditable] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [failedUploads, setFailedUploads] = useState<EhancedFile[]>([])
  const { id } = useParams()

  type FormValues = {
    documentName: string
    documentType: string
    projectId: string
    workflowStatus?: string
    documentStatus?: string
    numStatus?: string
    storedBy?: string
    registrationNumber?: string
  }

  const { register, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  })

  useEffect(() => {
    return reset({
      documentName: myFiles.length
        ? myFiles[0].name.replace(/\.[^/.]+$/, "")
        : undefined,
    })
  }, [myFiles])

  const handleRemoveFile = (index: number) => {
    const updatedFiles = myFiles.filter((_, i) => i !== index)
    setMyFiles(updatedFiles)

    setIsDocumentNameEditable(updatedFiles.length === 1)
  }

  const closeModal = () => {
    reset()
    setDocumentName("")
    setMyFiles([])
    toggleModal()
  }

  async function submitForm(values: FormValues) {
    try {
      setIsSubmitting(true)
      const successfulUploads: EhancedFile[] = []
      const failedUploadsSubmit: EhancedFile[] = []

      await Promise.all(
        myFiles.map(async (file) => {
          const formValues: Omit<FormValues, "projectId"> & {
            file: EhancedFile
          } = {
            file,
            documentType:
              documentTypes.length > 1 ? values.documentType : documentTypes[0],
            documentName:
              myFiles.length === 1
                ? values.documentName
                : file.name.replace(/\.[^/.]+$/, ""),
            ...(id && { projectId: id }),
          }

          formValues.numStatus = numStatus
          formValues.storedBy = user?.docuwareName

          if (registrationNumber) {
            formValues.registrationNumber = registrationNumber
          }

          if (isProjectStudyRequestRelated) {
            formValues.documentStatus = DOC_DEVELOPEMENT
          }

          const isUploadSuccess =
            await uploadDocumentToGed.mutateAsync(formValues)
          if (isUploadSuccess) {
            successfulUploads.push(file)

            if (handleGetDocumentsId) {
              await handleGetDocumentsId(isUploadSuccess.id)
            }

            if (setFiles) {
              const doc = await getDocumentsById(isUploadSuccess.id)
              setFiles((previousFiles) => {
                return [
                  ...previousFiles,
                  {
                    id: doc.id,
                    title: doc.title,
                    chapter: doc.docuwareClassification.chapter!,
                    subChapter: doc.docuwareClassification.subChapter!,
                    documentType: doc.type,
                    projectId: doc.projectId?.toString()!,
                  },
                ]
              })
            }
          } else {
            failedUploadsSubmit.push(file)
          }
        }),
      )

      setFailedUploads(failedUploadsSubmit)
      if (successfulUploads.length > 0) {
        const successMessage =
          successfulUploads.length === 1
            ? t("ged-upload-successMessage")
            : t("ged-upload-successMessagePlural")
        toast.success(successMessage)
        queryClient.invalidateQueries({
          queryKey: ["getDocumentsByStudyRequestTypeAndProjectId"],
        })
        queryClient.invalidateQueries({ queryKey: ["getCostOfWorkDocuments"] })
        queryClient.invalidateQueries({
          queryKey: ["getInfographyGedDocuments"],
        })
        queryClient.invalidateQueries({ queryKey: ["getFeasibilityDocuments"] })
        reset()
        setDocumentName("")
        setMyFiles([])
        if (successfulUploads.length === myFiles.length) {
          toggleModal()
        }
      }
    } catch (error) {
      toast.error(`${t("ged-upload-errorMessage")}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  const handleFileChange = (selectedFiles: File[]): void => {
    const authorizedFiles: File[] = []
    selectedFiles.map((file) => {
      if (file.size > MAX_SIZED_BYTES) {
        return toast.error(`${t("file-too-big-error")} : ${file.name}`)
      }

      if (UNAUTHORIZED_EXTENSIONS.includes(file.name.split(".").pop() ?? "")) {
        return toast.error(`${t("unauthorized-file-extension")} : ${file.name}`)
      }
      return authorizedFiles.push(file)
    })

    setMyFiles(
      authorizedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      }),
    )

    setIsDocumentNameEditable(authorizedFiles.length === 1)
  }

  return (
    <Modal
      isShowing={isModalShowed}
      closeModal={closeModal}
      title={t("ged-addTitlePlural")}
      description={t("ged-addDescriptionPlural")}
      displayCloseIcon
      useOnClickOutsideAlerter={false}
    >
      <UploadFieldDrop
        myFiles={myFiles}
        handleChange={handleFileChange}
        handleRemove={(index: number) => handleRemoveFile(index)}
        maxFiles={maxFiles}
      />

      {failedUploads.length > 0 && (
        <div className="rounded-lg bg-red-200 mt-4 p-3 text-primary-dark">
          <div className="flex items-center mb-1">
            <div className="mr-2 text-red-400">
              <IoWarningOutline size={28} />
            </div>
            <h2>{t("ged-filesNotUploaded")}</h2>
          </div>

          <ul className="list-disc list-inside pl-6 text-sm">
            {failedUploads.map((file) => (
              <li key={`failed-upload-${file.size}`}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <form onSubmit={onSubmit} className="Text__Field_Container mt-4">
        {isDocumentNameEditable ? (
          <label htmlFor="documentName">
            {`${t("ged-documentName")}`}
            <input
              id="documentName"
              {...register("documentName")}
              className={classNames("Text__Field", {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              })}
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              disabled={uploadDocumentToGed.isPending}
              type="text"
              placeholder={`${t("ged-documentName")}`}
            />
          </label>
        ) : null}

        {documentTypes.length > 1 ? (
          <label htmlFor="document-type-select">
            {`${t("ged-documentType")}`}
            <select
              {...register("documentType", { required: true })}
              disabled={uploadDocumentToGed.isPending}
              name="documentType"
              id="document-type-select"
              className={classNames(
                "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
                {
                  "disabled:opacity-75": uploadDocumentToGed.isPending,
                },
              )}
              defaultValue={documentTypes[0]}
            >
              {documentTypes?.map((documentType) => (
                <option
                  key={`document-type-${documentType}`}
                  value={documentType}
                >
                  {documentType}
                </option>
              ))}
            </select>
          </label>
        ) : null}

        <div className="Modal__Footer mt-4">
          <div className="flex justify-between items-center">
            {setFiles && linkedFiles && (
              <SelectFile
                documentTypes={documentTypes}
                setFiles={setFiles}
                files={files}
                closeOutterModal={closeModal}
              />
            )}
            <div className="flex justify-between items-center">
              <Button
                size="medium"
                mode="secondary"
                classNames="col-end-5 w-max"
                isLoading={false}
                onClick={() => closeModal()}
              >
                {`${t("cancel")}`}
              </Button>
              <Button
                type="submit"
                marginLeft={12}
                size="medium"
                mode="primary"
                classNames="col-end-7"
                isLoading={isSubmitting}
              >
                {`${t("confirm")}`}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}
