import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { Header } from "components/Header/Header"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListFeasibilities } from "core/query-hooks/useFeasibilities"
import GedTree from "features/ged/GedTree"
import { StudyRequestsList } from "features/studies/StudyRequestsList"
import { STATUS_RETURNED } from "features/studies/studyRequest.resources"
import { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import {
  MdAttachMoney,
  MdConstruction,
  MdOutlineDesignServices,
} from "react-icons/md"
import { Link, useParams } from "react-router-dom"
import { PROJECT_STUDIES_VIEWTAG } from "shared/resources/view-tags.resources"

function ProjectStudies() {
  const { id } = useParams()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const { data: feasibilities } = useListFeasibilities(+id!)
  const feasibilityReturned = feasibilities?.some(
    (feasibility) => feasibility.statusId === STATUS_RETURNED,
  )

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="px-4">
        <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-4 pb-10">
          <div className="col-span-3">
            <div className="flex justify-between items-center mb-8">
              <div>
                <Header>{`${t("study-request")}`}</Header>
              </div>
              <PermissionsGuard
                requiredRoles={[
                  "create:infography",
                  "create:feasibility",
                  "create:cost-of-work",
                ]}
              >
                <Button onClick={toggleModal} size="medium" mode="primary">
                  {t("study-request-new")}
                </Button>
              </PermissionsGuard>
            </div>
            <StudyRequestsList projectId={+id!} routeName="project" />
          </div>

          <div className="pt-4 lg:pt-0 col-span-1 mb-4">
            <div className="mb-2">
              <Header>{`${t("documents")}`}</Header>
            </div>
            <Box>
              <GedTree view={PROJECT_STUDIES_VIEWTAG} projectId={+id!} />
            </Box>
          </div>
        </div>
      </div>

      <Modal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("study-request-type")}`}
        displayCloseIcon
      >
        {!feasibilityReturned && (
          <p className="mb-2 text-xs opacity-80">
            {`${t("returned-feasibility-needed-message")}`}
          </p>
        )}
        <PermissionsGuard requiredRoles={["create:feasibility"]}>
          <Box classNames="p-0">
            <Link
              className="rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75"
              to={`/project/${id}/studies/feasibility/new`}
              onClick={toggleModal}
            >
              <MdConstruction opacity={0.8} fontSize={32} />
              <span className="mt-2">{`${t("feasibility-label")}`}</span>
              <span className="text-xs opacity-80 text-center">
                {`${t("design-placeholder")}`}
              </span>
            </Link>
          </Box>
        </PermissionsGuard>

        <>
          <PermissionsGuard requiredRoles={["create:cost-of-work"]}>
            <Box classNames="mt-2 p-0">
              <Link
                className="rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75"
                to={`/project/${id}/studies/costOfWork/new`}
                onClick={toggleModal}
              >
                <MdAttachMoney opacity={0.8} fontSize={32} />
                <span className="mt-2">{`${t("cost-of-work")}`}</span>
                <span className="text-xs opacity-80 text-center">
                  {`${t("building-economics-placeholder")}`}
                </span>
              </Link>
            </Box>
          </PermissionsGuard>

          <PermissionsGuard requiredRoles={["create:infography"]}>
            <Box classNames="mt-2 p-0">
              <Link
                className="rounded-lg Color__Primary flex items-center flex-col justify-center w-full p-4 transition-colors hover:bg-slate-100 ease-in-out duration-75"
                to={`/project/${id}/studies/infography/new`}
                onClick={toggleModal}
              >
                <MdOutlineDesignServices opacity={0.8} fontSize={32} />
                <span className="mt-2">{`${t("infography-label")}`}</span>
                <span className="text-xs opacity-80 text-center">
                  {`${t("infographic-placeholder")}`}
                </span>
              </Link>
            </Box>
          </PermissionsGuard>
        </>
      </Modal>
    </>
  )
}

export default ProjectStudies
