import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import Box from "components/Box/Box"
import { Header } from "components/Header/Header"
import { Modal } from "components/Modal/Modal"
import RewindButton from "components/RewindButton/RewindButton"
import Stepper from "components/Stepper/Stepper"
import {
  useGetInfography,
  useGetInfographyHistory,
} from "core/query-hooks/useInfographies"
import {
  useStudyRequestsRights,
  useUpdateStatus,
} from "core/query-hooks/useStudyRequests"
import { format } from "date-fns"
import { FollowUpForms } from "features/studies/FollowUpForms"
import FollowUpStepper from "features/studies/FollowUpStepper"
import {
  INFOGRAPHY_RESTITUTION_GED_CAT,
  STATUS_CANCELLED,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  TYPE_INFOGRAPHY,
} from "features/studies/studyRequest.resources"
import i18next, { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useState } from "react"
import { RiArrowLeftSLine } from "react-icons/ri"
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { toast } from "react-toastify"
import { TStudyRequestHistory } from "shared/types/study.type"

export interface IInfographyPage {
  routeName: string
}

export function InfographyPage(props: IInfographyPage) {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const { studyRequestId, id } = useParams()
  const navigate = useNavigate()
  const { routeName } = props
  const defaultSteps = [
    {
      name: () => i18next.t("details"),
      route: `/${routeName}/${id}/studies/infography/${studyRequestId}`,
      id: 1,
    },
    {
      name: () => i18next.t("documents"),
      route: `/${routeName}/${id}/studies/infography/${studyRequestId}/documents`,
      id: 2,
    },
  ]

  const { data: infographyData } = useGetInfography(+studyRequestId!)
  const { data: infographyHistory } = useGetInfographyHistory(+studyRequestId!)
  const { data: rights } = useStudyRequestsRights(+studyRequestId!)
  const updateStudyRequest = useUpdateStatus(+studyRequestId!)

  const cancellation = infographyHistory?.find(
    (element: TStudyRequestHistory) => element.statusId === STATUS_CANCELLED,
  )

  const queryClient = useQueryClient()
  const cancelStudyRequest = () => {
    setIsLoading(true)
    updateStudyRequest.mutate(
      {
        statusId: STATUS_CANCELLED,
      },
      {
        onSuccess: () => {
          toast.success(`${t("studyRequest-toast-cancelSuccess")}`)
          queryClient.invalidateQueries({
            queryKey: ["getInfography", +studyRequestId!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getInfographyHistory", +studyRequestId!],
          })
          setShowModal(false)
          setIsLoading(false)
        },
        onError: (err) =>
          toast.error(
            `${t("studyRequest-toast-cancelError")}, ${
              err.response?.data.error.message
            }`,
          ),
      },
    )
  }

  return (
    <>
      <DivStickyMenu />
      <div className="pb-4 md:pb-0">
        <div className="flex items-center flex-wrap justify-between px-4 pt-8 sm:px-16">
          <div className="flex items-center">
            <RewindButton
              classNames="h-12 w-12"
              size="small"
              mode="secondary"
              onClick={() => navigate(`/${routeName}/${id}/studies`)}
            >
              <RiArrowLeftSLine size="2em" />
            </RewindButton>
            <Header classNames="ml-2">
              {t("infographyTitle")} n°{studyRequestId}
            </Header>
          </div>
          <Stepper steps={defaultSteps} />
        </div>
      </div>
      <div className="lg:grid grid-rows-1 lg:grid-cols-7 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-4 sm:px-16 pb-8">
        <div className="col-span-5">
          <Outlet />
          <div className="flex items-center justify-end">
            {location.pathname ===
              `/${routeName}/${id}/studies/infography/${studyRequestId}` && (
              <Link
                className="Button primary px-2"
                to={`/${routeName}/${id}/studies/infography/${studyRequestId}/documents`}
              >
                {t("documents")}
              </Link>
            )}
          </div>

          {location.pathname ===
            `/${routeName}/${id}/studies/infography/${studyRequestId}/documents` && (
            <div className="flex justify-between items-center">
              <Link
                className="Button primary px-2"
                to={`/${routeName}/${id}/studies/infography/${studyRequestId}`}
              >
                {t("details")}
              </Link>
              {infographyData?.statusId! === STATUS_PRIORIZATION_STANDBY ||
              infographyData?.statusId! === STATUS_RESTITUTION_STANDBY ? (
                <Link
                  className="Button primary px-2"
                  to={`/${routeName}/${id}/studies/infography/edit/${studyRequestId}/documents`}
                >
                  {t("edit")}
                </Link>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="lg:pt-0 col-span-3 mb-4">
          <Box>
            <div className="flex items-center justify-between mb-4">
              <h3 className="Color__Primary font-semibold text-lg">
                {`${t("followUpStepper-title")}`}
              </h3>
              {infographyData?.statusId! !== STATUS_CANCELLED &&
                infographyData?.statusId! !== STATUS_REJECTED &&
                infographyData?.statusId! !== STATUS_RETURNED &&
                rights?.statuses.find((right) => right.id === STATUS_CANCELLED)
                  ?.edit && (
                  <Button
                    type="button"
                    size="small"
                    mode="gray"
                    onClick={() => setShowModal(true)}
                  >
                    {`${t("cancel")}`}
                  </Button>
                )}
              {infographyData?.statusId! === STATUS_CANCELLED && (
                <div className="border-red-400 border-2 px-2 py-1 rounded-lg">
                  <div>{`${t("study-step-cancelled")}`}</div>
                  {cancellation ? (
                    <div className="text-xs step-more-info">
                      {`${t("by")} ${cancellation.operator.firstName} ${
                        cancellation.operator.lastName
                      }, ${t("the")} ${format(
                        new Date(cancellation.date),
                        "dd/MM/yyyy",
                      )}`}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="pl-8">
              <FollowUpStepper
                categoryId={INFOGRAPHY_RESTITUTION_GED_CAT.id}
                currentStatusId={infographyData?.statusId!}
                studyRequest={infographyData}
                studyRequestType={TYPE_INFOGRAPHY}
                studyRequestHistory={infographyHistory}
                rights={rights}
                renderForm={() =>
                  infographyData?.statusId! !== STATUS_CANCELLED &&
                  infographyData?.statusId! !== STATUS_REJECTED && (
                    <FollowUpForms
                      statusId={infographyData?.statusId!}
                      studyRequestId={+studyRequestId!}
                      studyRequest={infographyData!}
                      studyRequestHistory={infographyHistory!}
                      studyRequestType={infographyData?.typeId!}
                    />
                  )
                }
              />
            </div>
          </Box>
        </div>
      </div>
      <Modal
        isShowing={showModal}
        closeModal={() => setShowModal(false)}
        title={`${t("study-request-cancelation-title")} n° ${studyRequestId}`}
        description={t("confirm-study-request-cancelation")}
      >
        <div className="flex justify-end">
          <Button
            size="medium"
            mode="secondary"
            onClick={() => setShowModal(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            size="medium"
            mode="danger"
            onClick={cancelStudyRequest}
            marginLeft={24}
            isLoading={isLoading}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </>
  )
}
