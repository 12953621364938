import classNames from "classnames"
import ButtonIcon from "components/Button/ButtonIcon"
import Spinner from "components/Spinner/Spinner"
import useGetSubfolders from "core/ged/query-hooks/useMetadata"
import { t } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { RiFolderAddFill } from "react-icons/ri"
import { useDebouncedCallback } from "use-debounce"

export interface ISubfolder3FieldProps {
  subfolder2: string | undefined
  onSelectSubfolder3: (selectedSubfolder: string) => void
  registrationNumbers: string[]
}

export function Subfolder3Field(props: ISubfolder3FieldProps) {
  const { subfolder2, onSelectSubfolder3, registrationNumbers } = props
  const [selectedSubfolder, setSelectedSubfolder] = useState<string>("")
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [canAddNewFolder, setCanAddNewFolder] = useState<boolean>(false)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setSelectedSubfolder(selectedValue)
    onSelectSubfolder3(selectedValue)
  }

  const {
    data: subfolders3,
    isLoading,
    error,
  } = useGetSubfolders("SOUS_DOSSIER_3TER", subfolder2!, registrationNumbers)

  useEffect(() => {
    setSelectedSubfolder("")
    onSelectSubfolder3("")
    setIsDisabled(false)
    if (error) setIsDisabled(true)
  }, [subfolder2])

  const onHandleCreate = useDebouncedCallback(
    useCallback(
      (value: string) => {
        setSelectedSubfolder(value)
        onSelectSubfolder3(value)
      },
      [selectedSubfolder],
    ),
    500,
  )

  return (
    <div>
      <label htmlFor="subfolder-3-select">
        <div className="flex items-center py-1">
          {`${t("subfolder")} 3 :`}
          {!!subfolder2 && (
            <ButtonIcon
              onClick={() => setCanAddNewFolder(!canAddNewFolder)}
              className="ml-2 -mt-1"
              type="button"
            >
              <RiFolderAddFill
                size={16}
                cursor="pointer"
                className="Text__Pierreval_Green"
              />
            </ButtonIcon>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <select
            id="subfolder-3-select"
            name="subfolder-3"
            value={selectedSubfolder}
            onChange={handleSelectChange}
            disabled={
              isDisabled || !subfolders3 || subfolders3.subfolders.length === 0
            }
            className={classNames(
              "Select__Field Text__Field flex items-center justify-center disabled:opacity-40 Select__Wrapper",
            )}
          >
            <option value="">{`${t("no-subfolder-selected")}`}</option>
            {subfolders3 && subfolders3.subfolders.length > 0
              ? subfolders3?.subfolders.map((subfolder) => (
                  <option key={subfolder} value={subfolder}>
                    {subfolder}
                  </option>
                ))
              : null}
          </select>
        )}
      </label>
      {canAddNewFolder && !!subfolder2 && (
        <div className="Text__Field_Container">
          <input
            id="sf3"
            className="Text__Field"
            onChange={(e) => onHandleCreate(e.target.value)}
            type="text"
            placeholder={`${t("add-subfoler")} 3`}
          />
        </div>
      )}
    </div>
  )
}
