import { getOperationById } from "core/api/operations" // Import de la fonction de récupération directe
import { TNextThreeMonthsInfos } from "shared/types/spo/next-three-months-infos"
import SPO_API from "../axios"

export default async function getNextThreeMonthsInfos(
  action: string,
  businessUnitId?: string,
): Promise<TNextThreeMonthsInfos[]> {
  const res = await SPO_API.get(
    `/nextThreeMonthsInfos?action=${action}${
      businessUnitId ? `&businessUnit=${businessUnitId}` : ""
    }`,
  )

  const permitsOperations: TNextThreeMonthsInfos[] = []

  await Promise.all(
    res.data.map(async (item: TNextThreeMonthsInfos) => {
      const operation = await getOperationById(item.projectId)

      if (
        operation?.operationData?.isJuristAbandonedDateDefinitive !== true &&
        operation?.operationData?.isProgramAbandonedDateDefinitive !== true
      ) {
        permitsOperations.push(item)
      }
    }),
  )

  return permitsOperations
}
