import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import { fr } from "date-fns/locale"
import { t } from "i18next"
import { useEffect } from "react"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import PERMIT_DELAY from "shared/resources/permit-delay"
import * as yup from "yup"

registerLocale("fr", fr)

const createValidationSchema = (requiredDate: boolean) =>
  yup.object().shape({
    date: requiredDate
      ? yup.date().required(() => `${t("required-date")}`)
      : yup.date().nullable(),
    isDateDefinitive: yup.boolean().required(),
    delayDepot: yup.number().required(() => `${t("required-delay")}`),
  })

export interface IEditBuildingPermitDateModalProps {
  isModalShowed: boolean
  toggleModal: () => void
  initialDate: Date | null | undefined
  isDefinitive: boolean
  onSubmit: (
    date: Date | null | undefined,
    isDefinitive: boolean,
    delayDepot: number,
  ) => void
  title: string
  labelDate: string
  delayOption: boolean
  delayDepot: number | undefined
  requiredDate?: boolean
}

export function EditBuildingPermitDateModal(
  props: IEditBuildingPermitDateModalProps,
) {
  const {
    isModalShowed,
    toggleModal,
    initialDate,
    isDefinitive,
    onSubmit,
    title,
    labelDate,
    delayOption,
    delayDepot,
    requiredDate = false,
  } = props

  const validationSchema = createValidationSchema(requiredDate)

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: initialDate,
      isDateDefinitive: isDefinitive,
      delayDepot: delayDepot ?? PERMIT_DELAY.DELAY_3MONTH,
    },
  })

  useEffect(() => {
    setValue("date", initialDate)
    setValue("isDateDefinitive", isDefinitive)
  }, [initialDate, isDefinitive, setValue])

  const handleFormSubmit = (data: {
    date?: Date | null
    isDateDefinitive: boolean
    delayDepot: number
  }) => {
    onSubmit(data.date ?? null, data.isDateDefinitive, data.delayDepot)
  }

  return (
    <Modal isShowing={isModalShowed} closeModal={toggleModal} title={title}>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className=" Text__Field_Container"
      >
        <div className="flex justify-between mt-3">
          <div className="mt-4 flex flex-col">
            <label htmlFor="date">{labelDate}</label>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  id="date"
                  locale={fr}
                  className="Text__Field"
                  placeholderText={labelDate}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
            {errors.date && (
              <p className="text-red-600 text-sm">{errors.date.message}</p>
            )}
          </div>
          <div className="mt-2 mb-6 mr-4">
            <label htmlFor="isDateDefinitive">
              {`${t("date-is-definitive")}`}
              <div className="flex items-center justify-start">
                <Controller
                  name="isDateDefinitive"
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      id="isDateDefinitive"
                      {...register("isDateDefinitive")}
                      checked={field.value}
                      onChange={field.onChange}
                      disabled={false}
                    />
                  )}
                />
              </div>
            </label>
          </div>
        </div>
        {delayOption && (
          <div className="mt-4">
            <label htmlFor="delayDepot">{t("permit-delay")}</label>
            <div className="flex flex-col">
              <Controller
                control={control}
                name="delayDepot"
                render={({ field }) => (
                  <>
                    <label className="flex items-center cursor-pointer pt-1">
                      <input
                        id="delayDepotRadio1"
                        type="radio"
                        value={PERMIT_DELAY.DELAY_3MONTH}
                        checked={field.value === PERMIT_DELAY.DELAY_3MONTH}
                        onChange={() =>
                          field.onChange(PERMIT_DELAY.DELAY_3MONTH)
                        }
                        className="mr-2"
                      />
                      {`${t("3-month")}`}
                    </label>
                    <label className="flex items-center mt-2 cursor-pointer">
                      <input
                        id="delayDepotRadio2"
                        type="radio"
                        value={PERMIT_DELAY.DELAY_4MONTH}
                        checked={field.value === PERMIT_DELAY.DELAY_4MONTH}
                        onChange={() =>
                          field.onChange(PERMIT_DELAY.DELAY_4MONTH)
                        }
                        className="mr-2"
                      />
                      {`${t("4-month")}`}
                    </label>
                    <label className="flex items-center mt-2 cursor-pointer">
                      <input
                        id="delayDepotRadio3"
                        type="radio"
                        value={PERMIT_DELAY.DELAY_5MONTH}
                        checked={field.value === PERMIT_DELAY.DELAY_5MONTH}
                        onChange={() =>
                          field.onChange(PERMIT_DELAY.DELAY_5MONTH)
                        }
                        className="mr-2"
                      />
                      {`${t("5-month")}`}
                    </label>
                  </>
                )}
              />
              {errors.delayDepot && (
                <p className="text-red-600 text-sm">
                  {errors.delayDepot.message}
                </p>
              )}
            </div>
          </div>
        )}

        <div className="Modal__Footer flex justify-between mt-3">
          <Button
            onClick={toggleModal}
            type="button"
            size="medium"
            mode="secondary"
          >
            {t("cancel")}
          </Button>
          <Button marginLeft={24} size="medium" mode="primary" type="submit">
            {t("update")}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
