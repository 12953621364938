import { useGetAgreementChildren } from "core/query-hooks/useAgreements"
import { format } from "date-fns"
import { t } from "i18next"

interface FormatAgreementChildrenProps {
  agreementId: string
}

export default function FormatAgreementChildren({
  agreementId,
}: FormatAgreementChildrenProps) {
  const { data: childRecords } = useGetAgreementChildren(agreementId)

  if (childRecords) {
    const { suspensiveConditions, amendments, tasks } = childRecords

    return (
      <div className="text-sm mb-4">
        {suspensiveConditions.length > 0 ||
        amendments.length > 0 ||
        tasks.length > 0 ? (
          <p className="pb-3">
            {t("agreements-delete-descriptionWithChildren1")}{" "}
            <b>{t("project-delete-deletion")}</b>{" "}
            {t("project-delete-descriptionWithChildren2")} :
          </p>
        ) : (
          <p className="pb-3">{t("agreements-delete-description")}</p>
        )}
        <div className="ml-4">
          {suspensiveConditions.length > 0 && (
            <ul className="pt-1">
              <b>{t("suspensive-conditions")} :</b>
              {suspensiveConditions.map((suspensiveCondition) => (
                <li key={suspensiveCondition.id}>
                  - {suspensiveCondition.label}
                  {suspensiveCondition?.date && (
                    <>
                      {" "}
                      -{" "}
                      {format(
                        new Date(suspensiveCondition.date!),
                        "dd/MM/yyyy",
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}

          {amendments.length > 0 && (
            <ul className="pt-1">
              <b>{t("amendments")} :</b>
              {amendments.map((amendment) => (
                <li>
                  - {amendment.label}
                  {amendment?.date && (
                    <> - {format(new Date(amendment.date!), "dd/MM/yyyy")}</>
                  )}
                </li>
              ))}
            </ul>
          )}

          {tasks.length > 0 && (
            <ul className="pt-1">
              <b>{t("tasks")} :</b>
              {tasks.map((task) => (
                <li>
                  - {task.label}
                  {task?.deadline && (
                    <> - {format(new Date(task.deadline!), "dd/MM/yyyy")}</>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="text-sm mb-4">
      <p className="pb-3">{t("agreements-delete-description")}</p>
    </div>
  )
}
