import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { Header } from "components/Header/Header"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useGetPlotsByAgreementId } from "core/query-hooks/usePlots"
import { PlotInput } from "features/plots/PlotInput"
import { t } from "i18next"
import { useState } from "react"
import { MdLayers } from "react-icons/md"
import { Link, useNavigate, useParams } from "react-router-dom"
import { GROUP } from "shared/resources/groups.resources"

interface IPlotBoxProps {
  routeName: string
}

export default function PlotBox(props: IPlotBoxProps) {
  const { routeName } = props
  const { id, agreementId } = useParams()
  const { data: plots, isError, error } = useGetPlotsByAgreementId(agreementId!)
  const [editable, setEditable] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <>
      <Box classNames="py-4 px-6 md:mb-4 sm:mb-4 mb-4">
        <div className="flex flex-wrap items-center justify-between">
          <Header size="h2" classNames="pt-2 mb-2">
            {`${t("plot")}`}
          </Header>
          <div className="flex justify-between items-center">
            <GroupsGuard
              requiredGroups={[
                GROUP.JURIDIQUE_JURISTE,
                GROUP.JURIDIQUE_ASSISTANT,
              ]}
            >
              {routeName === "operation" && (
                <div className="mb-2.5 md:mb-0 mr-2">
                  <Button
                    onClick={() =>
                      navigate(
                        `/${routeName}/${id}/agreements/${agreementId}/plots`,
                      )
                    }
                    size="small"
                    mode="primary"
                  >
                    {t("add-delete-plot")}
                  </Button>
                </div>
              )}
            </GroupsGuard>
            <Button
              onClick={() => setEditable(!editable)}
              size="small"
              mode="primary"
              classNames="mb-2.5 md:mb-0"
            >
              {editable ? t("confirm") : t("associate")}
            </Button>
          </div>
        </div>
        {editable ? (
          <PlotInput linkedPlots={plots!} />
        ) : (
          <>
            {plots && plots.data.length > 0 ? (
              <ul>
                {plots?.data.map((plot) => (
                  <li key={`plot-${plot.id}`}>
                    <Link
                      className="text-blue-600"
                      to={
                        routeName === "project"
                          ? `/${routeName}/${id}/plots/${plot.id}`
                          : `/${routeName}/${id}/agreements/${agreementId}/plots/${plot.id}`
                      }
                    >
                      <div className="flex items-center mb-1">
                        <MdLayers className="mr-2" />
                        <span>
                          {plot.prefix} {plot.number} {plot.section} -{" "}
                          {plot.area.val} {plot.area.unit}
                        </span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <p> {`${t("agreements-show-noPlotFoundMessage")}`}</p>
            )}
          </>
        )}
      </Box>
      {isError && <p className="text-red-500 text-xs">{error.message}</p>}
    </>
  )
}
