import { useMutation, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  SharepointItem,
  TCreateFolder,
  TSendFolderToGed,
} from "shared/types/sharepoint"
import {
  countFilesInFolder,
  createFolder,
  listFoldersFromParent,
  sendFolderToGed,
} from "../api/folder"

function useListFoldersFromParent(parentId: string) {
  return useQuery<SharepointItem[], CustomAxiosError>({
    queryKey: ["listFoldersFromParent", parentId],
    queryFn: () => listFoldersFromParent(parentId),
    ...{
      refetchOnWindowFocus: false,
    },
  })
}

function useCountFilesInFolder(parentId: string) {
  return useQuery<number, CustomAxiosError>({
    queryKey: ["countFilesInFolder", parentId],
    queryFn: () => countFilesInFolder(parentId),
    ...{
      refetchOnWindowFocus: false,
    },
  })
}

function useCreateFolder() {
  return useMutation<void, CustomAxiosError, TCreateFolder>({
    mutationFn: (folder: TCreateFolder) => createFolder(folder),
  })
}

function useSendFolderToGed() {
  return useMutation<void, CustomAxiosError, TSendFolderToGed>({
    mutationFn: (data: TSendFolderToGed) => sendFolderToGed(data),
  })
}

export {
  useCountFilesInFolder,
  useCreateFolder,
  useListFoldersFromParent,
  useSendFolderToGed,
}
