import { useMutation, useQuery } from "@tanstack/react-query"
import {
  listCallOfTendersByOperation,
  upsertCallOfTender,
} from "core/api/callOfTender"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TCallOfTender,
  TUpsertCallOfTenders,
} from "shared/types/call-of-tender.type"

function useListCallOfTendersByOperation(operationId: number) {
  return useQuery<TCallOfTender[], CustomAxiosError>({
    queryKey: ["listCallOfTendersByOperation", operationId],
    queryFn: () => listCallOfTendersByOperation(operationId),
  })
}

function useUpsertCallOfTender() {
  return useMutation<TCallOfTender, CustomAxiosError, TUpsertCallOfTenders>({
    mutationFn: (data) => upsertCallOfTender(data),
  })
}

export { useListCallOfTendersByOperation, useUpsertCallOfTender }
