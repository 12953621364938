import Box from "components/Box/Box"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import {
  useListCommitteeByOperationId,
  useListCommitteesTypes,
} from "core/query-hooks/useCommittes"
import AddCommitteeModal from "features/committees/AddCommitteeModal"
import { CommitteeRow } from "features/committees/CommitteeRow"
import GedTree from "features/ged/GedTree"
import { t } from "i18next"
import { sortBy } from "lodash"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { Fragment } from "react"
import { useParams } from "react-router-dom"
import { COMMITTEE_ENGAGEMENT_MOD } from "shared/resources/committees-types-resources"
import { PROJECT_COMMITTEES_VIEWTAG } from "shared/resources/view-tags.resources"
import { TCommitteeStep } from "shared/types/committee.type"

function ProjectCommittees() {
  const { id } = useParams()
  const { data: committees, error, isLoading } = useListCommitteesTypes()
  const {
    data: projectCommittees,
    error: errorCurrentCommittee,
    isLoading: isLoadingCurrentCommittee,
  } = useListCommitteeByOperationId(Number(id))

  if (isLoading || isLoadingCurrentCommittee) return <Spinner />

  if (error || errorCurrentCommittee)
    return <p>{t("error-loading-committees")}</p>

  function getCurrentCommittee(
    committeeId: number,
  ): TCommitteeStep | undefined {
    return projectCommittees?.find(
      (committee) => committee.type.id === committeeId,
    )
  }

  const nonMandatoryCommitteesTypes = committees?.filter(
    (committee) => !committee.mandatory,
  )

  // Suppression des types de comités déjà existants sur le projet sauf pour le comité d'engagement modificatif
  const filteredCommitteesTypes = nonMandatoryCommitteesTypes?.filter(
    (committee) =>
      !projectCommittees?.some(
        (com) =>
          com.type.id === committee.id &&
          committee.id !== COMMITTEE_ENGAGEMENT_MOD.id,
      ),
  )

  const engagementModCommittees = projectCommittees?.filter(
    (committee) => committee.type.id === COMMITTEE_ENGAGEMENT_MOD.id,
  )
  const sortedEngagementModCommittees = sortBy(engagementModCommittees, "id")

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="px-4 pb-32">
        <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-4">
          <div className="col-span-3">
            <div>
              <div className="mb-2 flex items-center justify-between">
                <Header>{`${t("committees")}`}</Header>
                <AddCommitteeModal
                  committeesTypes={filteredCommitteesTypes}
                  projectId={Number(id)}
                />
              </div>

              <Box classNames="p-0 overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-sm text-primary-light font-semibold">
                      <th className="px-2 py-4 w-64">{t("committee")}</th>
                      <th className="px-2 py-4 w-36">{t("date")}</th>
                      <th className="px-2 py-4">{t("report")}</th>
                      <th className="px-2 py-4"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {committees?.map((committee) => (
                      <Fragment key={committee.id}>
                        <GedAxiosInterceptor>
                          <CommitteeRow
                            committeeType={committee}
                            committeeData={getCurrentCommittee(committee.id)}
                            engagementModCommittees={
                              sortedEngagementModCommittees
                            }
                          />
                        </GedAxiosInterceptor>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </Box>
            </div>
          </div>
          <div className="col-span-1">
            <div className="mb-2">
              <Header>{`${t("documents")}`}</Header>
            </div>
            <Box>
              <GedTree view={PROJECT_COMMITTEES_VIEWTAG} projectId={+id!} />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectCommittees
