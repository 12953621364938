import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import ControlledRowTable from "components/Table/ControlledRowTable"
import { useGetSpoLotsByTranche } from "core/spo/query-hooks/useLots"
import { nanoid } from "nanoid"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import LOT_PROGRAMME from "shared/resources/lots-reservations.resources"
import {
  DELIVERED,
  IN_STOCK,
  OPTIONNED,
  SOLD,
} from "shared/resources/lots-status.resources"
import { TLotStatuse } from "shared/types/spo/lot.type"
import formatNumberNoZero from "utils/formatNumberNoZero"

interface IEtudeResaStockResidentielByTrancheProps {
  registrationNumber: string
  trancheCommercialeId: string
}

export default function EtudeResaStockResidentielByTranche(
  props: IEtudeResaStockResidentielByTrancheProps,
) {
  const { t } = useTranslation()
  const { registrationNumber, trancheCommercialeId } = props
  const {
    data: batches,
    isLoading,
    error,
  } = useGetSpoLotsByTranche(registrationNumber, trancheCommercialeId)

  const [isCombinedStock, setIsCombinedStock] = useState(true)
  const [isCombinedActes, setIsCombinedActes] = useState(true)

  if (isLoading) return <Spinner />

  if (error)
    return (
      <p className="text-sm text-red-500">
        {t("error-loading-lots-lotissemnts")}
      </p>
    )
  const housingTypes = batches?.lotStatuses[0]?.amount?.map(
    (item) => item.lotType,
  )

  if (!housingTypes)
    return <p className="text-sm">{t("table-noDataFoundMessage")}</p>

  const headers = ["", "", ...housingTypes, `${t("sum")}`]

  const { lotStatuses } = batches!

  const programStatus = lotStatuses.find(
    (status: TLotStatuse) => status.lotStatusName === LOT_PROGRAMME.label,
  )

  const isTableEmpty = programStatus?.totalAmount === 0

  const optionnesStatus = lotStatuses.find(
    (status) => status.lotStatusName === OPTIONNED.labelPlural,
  )

  const livresStatus = lotStatuses.find(
    (status) => status.lotStatusName === DELIVERED.label,
  )

  const enStockStatus = lotStatuses.find(
    (status) => status.lotStatusName === IN_STOCK.label,
  )

  const actesStatus = lotStatuses.find(
    (status) => status.lotStatusName === SOLD.labelPlural,
  )

  const totalLots = programStatus ? programStatus.totalAmount : null

  const combineStatuses = (
    mainStatus: TLotStatuse | undefined,
    statusToCombine: TLotStatuse | undefined,
  ): TLotStatuse | undefined => {
    if (!mainStatus) return statusToCombine
    if (!statusToCombine) return mainStatus

    const combinedStatus = {
      ...mainStatus,
      amount: mainStatus.amount.map((item, index) => ({
        ...item,
        value: item.value + (statusToCombine.amount[index]?.value || 0),
      })),
      averageArea: mainStatus.averageArea.map((item, index) => ({
        ...item,
        value: item.value + (statusToCombine.averageArea[index]?.value || 0),
      })),
      averagePrice: mainStatus.averagePrice.map((item, index) => ({
        ...item,
        value: item.value + (statusToCombine.averagePrice[index]?.value || 0),
      })),
      averagePricePerSquareMeter: mainStatus.averagePricePerSquareMeter.map(
        (item, index) => ({
          ...item,
          value:
            item.value +
            (statusToCombine.averagePricePerSquareMeter[index]?.value || 0),
        }),
      ),
      totalAmount: mainStatus.totalAmount + (statusToCombine.totalAmount || 0),
      globalAverageArea:
        mainStatus.globalAverageArea + (statusToCombine.globalAverageArea || 0),
      totalPrice: mainStatus.totalPrice + (statusToCombine.totalPrice || 0),
      totalPricePerSqareMeter:
        mainStatus.totalPricePerSqareMeter +
        (statusToCombine.totalPricePerSqareMeter || 0),
    }
    // Calcul du pourcentage des lots
    if (totalLots != null) {
      combinedStatus.percentageOfLots = combinedStatus.totalAmount / totalLots
    }
    return combinedStatus
  }

  const combinedStatus = isCombinedStock
    ? combineStatuses(enStockStatus, optionnesStatus)
    : enStockStatus

  const combinedActes = isCombinedActes
    ? combineStatuses(actesStatus, livresStatus)
    : actesStatus

  const isLineEmpty = (line: TLotStatuse | undefined): boolean => {
    if (!line) return true

    return (
      line.amount.every((item) => item.value === 0) &&
      line.averageArea.every((item) => item.value === null) &&
      line.averagePrice.every((item) => item.value === null) &&
      line.averagePricePerSquareMeter.every((item) => item.value === null) &&
      line.totalAmount === 0 &&
      line.globalAverageArea === null &&
      line.totalPrice === null &&
      line.totalPricePerSqareMeter === null
    )
  }

  return isTableEmpty ? (
    <p className="p-2 text-sm Color__Primary flex">
      {t("no-data-found-message")}
    </p>
  ) : (
    <div className="Light_Table__Wrapper Table__Wrapper_NoTop overflow-hidden">
      <ControlledRowTable
        headerPosition="text-right"
        className="ColorfullHeader"
        showTotal={false}
        hasBorders
        isTight
        items={[
          // Gestion de "En stock / Optionnés"
          ...(isCombinedStock
            ? [combinedStatus] // Si combiné, affiche le statut combiné "En stock / Optionnés"
            : [combinedStatus, optionnesStatus]), // Sinon, affiche séparément "En stock" et "Optionnés"

          // Gestion de "Livrés / Actes"
          ...(isCombinedActes
            ? [combinedActes] // Si combiné, affiche le statut combiné "Livrés / Actes"
            : [combinedActes, livresStatus]), // Sinon, affiche séparément "Actes" et "Livrés"

          // Filtre et ajoute les autres statuts
          ...lotStatuses.filter(
            (status) =>
              status.lotStatusName !== SOLD.labelPlural &&
              status.lotStatusName !== OPTIONNED.labelPlural &&
              status.lotStatusName !== IN_STOCK.label &&
              status.lotStatusName !== DELIVERED.label,
          ),
        ]}
        headers={headers}
        renderRow={(item, index) => (
          <>
            {item && (
              <>
                <tr
                  className="Table__Body_Row hover:bg-gray-100 transition-colors bg-gray-100"
                  key={`title-table-${index}`}
                >
                  <td
                    rowSpan={5}
                    className={classNames(
                      "Table__Head Table__Top_Border border-r border-grey-200",
                      "border-t border-gray-300",
                    )}
                  >
                    {item.lotStatusName}
                    {item.lotStatusName === IN_STOCK.label &&
                      !isLineEmpty(optionnesStatus) && (
                        <button
                          className="ml-2 p-1 bg-primary-dark text-white text-xs rounded"
                          type="button"
                          onClick={() => setIsCombinedStock(!isCombinedStock)}
                        >
                          {isCombinedStock
                            ? `▼ ${t("with-options")}`
                            : `▲ ${t("collapse")}`}
                        </button>
                      )}
                    {item.lotStatusName === SOLD.labelPlural &&
                      !isLineEmpty(livresStatus) && (
                        <button
                          className="ml-2 p-1 bg-primary-dark text-white text-xs rounded"
                          type="button"
                          onClick={() => setIsCombinedActes(!isCombinedActes)}
                        >
                          {isCombinedActes
                            ? `▼ ${t("with-delivered")}`
                            : `▲ ${t("collapse")}`}
                        </button>
                      )}
                  </td>
                </tr>

                <tr
                  className={`Table__Body_Row border-r border-grey-200 ${
                    index % 2 === 1 ? "bg-gray-100" : ""
                  } ${item.lotStatusId === LOT_PROGRAMME.id ? "font-bold" : ""}`}
                  key={`title-table-${index}`}
                >
                  <td>{t("number")}</td>

                  {item.amount.map((amount) => (
                    <td className="text-right" key={nanoid()}>
                      {formatNumberNoZero(amount.value)}
                    </td>
                  ))}
                  <td className="text-right font-bold">
                    {`${formatNumberNoZero(item.totalAmount)} ${
                      item.percentageOfLots
                        ? `(${(item.percentageOfLots * 100).toFixed()} %)`
                        : ``
                    }`}
                  </td>
                </tr>

                <tr
                  className={`Table__Body_Row ${
                    index % 2 === 1 ? "bg-gray-100" : ""
                  } ${item.lotStatusId === LOT_PROGRAMME.id ? "font-bold" : ""}`}
                  key={`title-table-${index}`}
                >
                  <td>{t("average-surface")}</td>

                  {item.averageArea.map((surface) => (
                    <td
                      className="text-right border-r border-grey-200"
                      key={nanoid()}
                    >
                      {formatNumberNoZero(surface.value)}
                    </td>
                  ))}
                  <td className="text-right font-bold">
                    {formatNumberNoZero(item.globalAverageArea)}
                  </td>
                </tr>

                <tr
                  className={`Table__Body_Row ${
                    index % 2 === 1 ? "bg-gray-100" : ""
                  } ${item.lotStatusId === LOT_PROGRAMME.id ? "font-bold" : ""}`}
                  key={`title-table-${index}`}
                >
                  <td>{t("price")}</td>

                  {item.averagePrice.map((price) => (
                    <td
                      className="text-right border-r border-grey-200"
                      key={nanoid()}
                    >
                      {formatNumberNoZero(price.value)}
                    </td>
                  ))}
                  <td className="text-right font-bold">
                    {formatNumberNoZero(item.totalPrice)}
                  </td>
                </tr>

                <tr
                  className={`Table__Body_Row ${
                    index % 2 === 1 ? "bg-gray-100" : ""
                  } ${item.lotStatusId === LOT_PROGRAMME.id ? "font-bold" : ""}`}
                  key={`title-table-${index}`}
                >
                  <td>{t("sqm-price")}</td>

                  {item.averagePricePerSquareMeter.map((price) => (
                    <td className="text-right" key={nanoid()}>
                      {formatNumberNoZero(price.value)}
                    </td>
                  ))}
                  <td className="text-right font-bold">
                    {formatNumberNoZero(item.totalPricePerSqareMeter)}
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      />
    </div>
  )
}
