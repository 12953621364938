import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useAddBuildingPermitDateMod } from "core/query-hooks/useBuildingPermits"
import { t } from "i18next"
import { toArray } from "lodash"
import { useEffect } from "react"
import { toast } from "react-toastify"
import PERMIT_LABEL from "shared/resources/permit-label"
import PERMIT_STEP from "shared/resources/permit-step"
import {
  TBuildingPermit,
  TMutateBuildingPermitDate,
} from "shared/types/building-permit.type"
import { TIdLabel } from "shared/types/global.type"

const MAX_BUILDING_PERMITS = Object.keys(PERMIT_LABEL).length

interface IAddModifyingPermit {
  childOpId: number
  setDisplayLabel: (displayLabel: TIdLabel[]) => void
  permits: TBuildingPermit[]
}
export default function AddModifyingPermit(props: IAddModifyingPermit) {
  const { childOpId, setDisplayLabel, permits } = props
  const hasInitializedPermits = permits.length > 1
  const PERMITS_LABEL = toArray(PERMIT_LABEL)
  const numberOfPermits = Math.max(
    ...permits.map((p) => p.buildingPermitLabel.id),
    1,
  )
  const { isShowing: isShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const mutationMod = useAddBuildingPermitDateMod()

  useEffect(() => {
    const permitsLabel: TIdLabel[] = []

    for (let i = 0; i < numberOfPermits; i += 1) {
      permitsLabel.push(PERMITS_LABEL[i])
    }
    setDisplayLabel(permitsLabel)
  }, [permits])

  const handleAddModyfyingPermit = () => {
    if (hasInitializedPermits) {
      const newData: TMutateBuildingPermitDate = {
        childOperation: childOpId,
        buildingPermitLabelId: numberOfPermits + 1,
        buildingPermitStepId: PERMIT_STEP.DEPOT.id,
        isDateDefinitive: false,
        date: new Date(),
      }

      mutationMod.mutateAsync(newData, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["listBuildingPermits", childOpId],
          })
          toast.success(`${t("toast-permit-update-success")}`)
          toggleModal()
        },
        onError: (err) => {
          toast.error(
            `${t("toast-action-update-error")} ${
              err.response?.data.error.message
            }`,
          )
        },
      })
    }
  }

  return (
    <>
      <PermissionsGuard requiredRoles={["create:permit-mod"]}>
        <Button
          classNames="w-max px-4 fs-4"
          onClick={toggleModal}
          size="medium"
          mode="primary"
          isDisabled={
            !hasInitializedPermits || numberOfPermits === MAX_BUILDING_PERMITS
          }
        >
          {`${t("add-permit")}`}
        </Button>
      </PermissionsGuard>

      <Modal
        isShowing={isShowed}
        closeModal={toggleModal}
        title={`${t("edit-permitmod")}`}
        description={t("confirm-create-permit")}
      >
        <div className="flex justify-end">
          <Button size="medium" mode="secondary" onClick={toggleModal}>
            {t("cancel")}
          </Button>
          <Button
            size="medium"
            mode="danger"
            onClick={handleAddModyfyingPermit}
            marginLeft={24}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </>
  )
}
