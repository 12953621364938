import { ReactElement, useState } from "react"
import { Tab } from "./Tab"
import { TabOperationChild } from "./TabOperationChild"
import "./Tabs.scss"

type ReactElementType = (ReactElement | null | ReactElement[])[]

export interface ITabsProps {
  children: ReactElementType
  isOperationChildren?: boolean
  selectedRegistrationNumber?: string
  onTabChange?: (selectedValue: string) => void
}

export function Tabs({
  children,
  isOperationChildren,
  selectedRegistrationNumber,
  onTabChange,
}: ITabsProps) {
  const flattenedChildren = children.flat()
  const firstChild = flattenedChildren[0] !== null ? 0 : 1
  const defaultUseState = selectedRegistrationNumber
    ? flattenedChildren.findIndex((c) => c?.key === selectedRegistrationNumber)
    : firstChild

  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultUseState)

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index)
    if (onTabChange && flattenedChildren[index]?.props.value) {
      onTabChange(flattenedChildren[index].props.value)
    }
  }

  return (
    <>
      {isOperationChildren ? (
        <>
          <ul className="Tab__menu">
            {flattenedChildren.map(
              (item, index) =>
                item !== null && (
                  <TabOperationChild
                    key={item.props.title}
                    title={item.props.title}
                    index={index}
                    isActive={index === selectedTabIndex}
                    setSelectedTab={() => handleTabChange(index)}
                    registrationNumber={item.key as string}
                  />
                ),
            )}
          </ul>
          {flattenedChildren[selectedTabIndex]}
        </>
      ) : (
        <>
          <ul className="Tab__menu">
            {flattenedChildren.map(
              (item, index) =>
                item !== null && (
                  <Tab
                    icon={item.props.icon}
                    iconSize={item.props.iconSize}
                    key={item.props.title}
                    title={item.props.title}
                    index={index}
                    isActive={index === selectedTabIndex}
                    setSelectedTab={() => handleTabChange(index)}
                  />
                ),
            )}
          </ul>
          {flattenedChildren[selectedTabIndex]}
        </>
      )}
    </>
  )
}
