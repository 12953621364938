import classNames from "classnames"
import { Button } from "components"
import ButtonIcon from "components/Button/ButtonIcon"
import GedFile from "components/Ged/GedFile"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { useGetDocumentsByType } from "core/ged/query-hooks/useGed"
import { t } from "i18next"
import { Dispatch, SetStateAction, useState } from "react"
import { IoDocumentAttach } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { TGedDocument, TGedUploadResponse } from "shared/types/ged.type"

export interface ISelectFile {
  disabled?: boolean
  documentTypes: string[]
  setFiles: Dispatch<SetStateAction<TGedUploadResponse[]>>
  closeOutterModal: () => void
  files?: TGedUploadResponse[]
}

export function SelectFile(props: ISelectFile) {
  const { disabled, documentTypes, setFiles, closeOutterModal, files } = props
  const { toggle, isShowing } = useModal()
  const { id } = useParams()
  const [documentType] = documentTypes
  const { data: documents } = useGetDocumentsByType(id!, documentType)
  const [selectedDocuments, setSelectedDocuments] = useState<
    TGedUploadResponse[]
  >(files ?? [])

  const handleDocumentClick = (doc: TGedDocument): void => {
    const isSelected = (selectedDocuments ?? []).find((d) => d.id === doc.id)

    const updatedDocuments = isSelected
      ? selectedDocuments.filter((selectedDoc) => selectedDoc.id !== doc.id)
      : [
          ...selectedDocuments,
          {
            id: doc.id,
            title: doc.title,
            chapter: doc.docuwareClassification.chapter ?? "",
            subChapter: doc.docuwareClassification.subChapter ?? "",
            documentType: doc.docuwareClassification.documentType ?? "",
            projectId: `${doc.projectId}`,
          } as TGedUploadResponse,
        ]

    setSelectedDocuments(updatedDocuments)
  }

  const onSubmit = () => {
    setFiles(selectedDocuments)
    toggle()
    closeOutterModal()
  }

  return (
    <>
      <TooltipWithHover
        message={`${t("link-document-tooltip")}`}
        position="center"
      >
        <ButtonIcon
          disabled={disabled}
          className={classNames(
            "flex items-center text-blue-500 hover:text-blue-600 transition-colors ease-in-out delay-75 mr-2",
            {
              "opacity-50": disabled,
            },
          )}
          type="button"
          onClick={toggle}
        >
          <IoDocumentAttach
            size={28}
            cursor="pointer"
            className="text-cyan-800"
          />
        </ButtonIcon>
      </TooltipWithHover>
      {isShowing && (
        <Modal
          isShowing={isShowing}
          closeModal={toggle}
          useOnClickOutsideAlerter={false}
          title={t("link-document")}
          displayCloseIcon
        >
          <form onSubmit={onSubmit}>
            <div className="flex items-center mb-4">
              <div className="Text__Field_Container">
                {documents?.map((doc) => {
                  return (
                    <div className="my-4 ml-1" key={doc.id}>
                      <label className="flex items-center cursor-pointer">
                        <input
                          className="mr-2"
                          type="checkbox"
                          id={doc.id.toString()}
                          onClick={() => handleDocumentClick(doc)}
                          checked={selectedDocuments.some(
                            (f) => f.id === doc.id,
                          )}
                          aria-label="Select document"
                        />
                        <span className="text-sm text-primary-dark">
                          <GedFile
                            id={doc.id}
                            title={`${doc.title}${doc.type}`}
                          />
                        </span>
                      </label>
                    </div>
                  )
                })}
                {(!documents || documents.length === 0) && (
                  <p className="Color__Primary text-sm flex items-center justify-center">
                    {t("ged-noDocumentsFound")}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4">
              <Button
                size="medium"
                mode="secondary"
                isLoading={false}
                onClick={toggle}
              >
                {`${t("cancel")}`}
              </Button>

              <Button
                type="submit"
                size="medium"
                mode="primary"
                classNames="col-end-7"
                isLoading={false}
                isDisabled={!documents || documents.length === 0}
              >
                {`${t("link")}`}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}
