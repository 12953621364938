import Box from "components/Box/Box"
import TooltipWithHover from "components/Tooltip/TooltipWithHover"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useListCommitteeByOperationId } from "core/query-hooks/useCommittes"
import { ProgressList } from "features/progress/ProgressList"
import { t } from "i18next"
import { MdDateRange } from "react-icons/md"
import { Link } from "react-router-dom"
import { COMMITTEE_TRANSFERT } from "shared/resources/committees-types-resources"
import { GROUP } from "shared/resources/groups.resources"
import { HOUSING_ESTATE_TYPE } from "shared/resources/operation.resources"
import { TChildOperation } from "shared/types/operation.type"
import { DceAoDates } from "./DceAoDates"
import { TechnicalProductionCollectiveDatesTable } from "./production/collective/technical/TechnicalProductionCollectiveDatesTable"
import { TechnicalProductionHousingDatesTable } from "./production/housing/technical/TechnicalProductionHousingDatesTable"
import { ProductionTracking } from "./production/technical/ProductionTracking"

interface TOperationChildItemTechnicalProps {
  operationChild: TChildOperation
  operationId: number
}

export function OperationChildItemTechnical(
  props: TOperationChildItemTechnicalProps,
) {
  const { operationChild, operationId } = props

  const { data: committees } = useListCommitteeByOperationId(operationId)
  const transfertDate = committees?.find(
    (c) => c.type.id === COMMITTEE_TRANSFERT.id && c.isDefinitive,
  )?.date

  return (
    <>
      <div className="mb-4 mt-4">
        <ProgressList childOperationId={operationChild.id} />
      </div>

      <DceAoDates
        operationId={operationId}
        childOperations={[operationChild]}
        technical
      />

      <GroupsGuard requiredGroups={[GROUP.ADMIN]}>
        <ProductionTracking childOperation={operationChild} />
      </GroupsGuard>

      <Box classNames="mt-4 p-0">
        <div className="flex items-center justify-between border-gray-200 px-4 pt-2">
          <div className="flex items-center text-cyan-900 font-medium">
            <MdDateRange />
            <h3 className="text-xs uppercase pl-2">
              {t("production-follow-up")}
            </h3>
          </div>
          <div className=" text-primary-dark">
            <TooltipWithHover
              position="left"
              message={`${t("spo-informations")}`}
            >
              {transfertDate ? (
                <Link
                  className="text-cyan-900 underline uppercase text-xs"
                  to={`${process.env.REACT_APP_SPO_URL}#/planning/gantt?operation=${operationChild.registrationNumber}`}
                  target="_blank"
                >
                  {`${t("spo")}`}
                </Link>
              ) : (
                <p className="text-cyan-900 uppercase text-xs">
                  {`${t("spo")}`}
                </p>
              )}
            </TooltipWithHover>
          </div>
        </div>
        <div className="mt-2">
          {operationChild.type.id === HOUSING_ESTATE_TYPE.id ? (
            <TechnicalProductionHousingDatesTable
              operationsChildren={[operationChild]}
            />
          ) : (
            <TechnicalProductionCollectiveDatesTable
              operationsChildren={[operationChild]}
            />
          )}
        </div>
      </Box>
    </>
  )
}

export default OperationChildItemTechnical
