import { API } from "core/api/axios"
import {
  TBuildingPermit,
  TBuildingPermitDate,
  TBuildingPermitDoc,
  TBuildingPermitLabel,
  TBuildingPermitStep,
  TMutateBuildingPermitDate,
  TMutateBuildingPermitDoc,
  TPermitsByOperation,
  TUpdateBuildingPermitDate,
} from "shared/types/building-permit.type"

async function listBuildingPermits(
  childOperationId: number,
): Promise<TBuildingPermit[]> {
  const res = await API.get(`/permits?childOperation=${childOperationId}`)
  return res.data
}

async function listBuildingPermitsByOperation(
  operationId: number,
): Promise<TPermitsByOperation[]> {
  const res = await API.get(`/permits/operation/${operationId}`)
  return res.data
}

async function listBuildingPermitStep(): Promise<TBuildingPermitStep[]> {
  const res = await API.get(`/permit-steps`)
  return res.data
}

async function listBuildingPermitLabel(): Promise<TBuildingPermitLabel[]> {
  const res = await API.get(`/permit-labels`)
  return res.data
}

async function listBuildingPermitDocuments(
  childOperationId: number,
): Promise<TBuildingPermitDoc[]> {
  const res = await API.get(
    `/permit-documents?childOperation=${childOperationId}`,
  )
  return res.data
}

async function addBuildingPermitDocument(
  buildingPermitDoc: TMutateBuildingPermitDoc,
): Promise<TBuildingPermitDoc> {
  const res = await API.post("/permit-document", buildingPermitDoc).then(
    (response) => response.data,
  )
  return res.data
}

async function addBuildingPermitDate(
  buildingPermitDate: TMutateBuildingPermitDate,
): Promise<TBuildingPermitDate> {
  const res = await API.post("/permit", buildingPermitDate).then(
    (response) => response.data,
  )
  return res.data
}

async function addBuildingPermitDateMod(
  buildingPermitDate: TMutateBuildingPermitDate,
): Promise<TBuildingPermitDate> {
  const res = await API.post("/permit-mod", buildingPermitDate).then(
    (response) => response.data,
  )
  return res.data
}

async function updateBuildingPermitDate(
  buildingPermitDateId: number,
  data: TUpdateBuildingPermitDate,
): Promise<TBuildingPermitDate> {
  const res = await API.patch(`/permit/${buildingPermitDateId}`, data)
  return res.data
}

export {
  addBuildingPermitDate,
  addBuildingPermitDateMod,
  addBuildingPermitDocument,
  listBuildingPermitDocuments,
  listBuildingPermitLabel,
  listBuildingPermits,
  listBuildingPermitsByOperation,
  listBuildingPermitStep,
  updateBuildingPermitDate,
}
