import {
  FaFile,
  FaFileAlt,
  FaFileArchive,
  FaFileAudio,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaImage,
  FaVideo,
} from "react-icons/fa"

export default function getFileIcon(extension: string) {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "svg":
      return <FaImage color="14a3fc" />
    case "mp4":
    case "avi":
    case "mov":
    case "wmv":
    case "flv":
      return <FaVideo color="9801c6" />
    case "doc":
    case "docx":
    case "txt":
    case "rtf":
      return <FaFileAlt color="#0850B6" />
    case "mp3":
    case "wav":
    case "ogg":
    case "flac":
      return <FaFileAudio color="f9139d" />
    case "pdf":
      return <FaFilePdf color="#A10808" />
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gz":
      return <FaFileArchive color="#ffcf0f" />
    case "xls":
    case "xlsx":
    case "xlsm":
      return <FaFileExcel color="#0A7641" />
    case "csv":
      return <FaFileCsv color="#0A7641" />
    default:
      return <FaFile />
  }
}
