import GedFile from "components/Ged/GedFile"
import { AddFile } from "features/studies/AddFile"
import { t } from "i18next"
import { useEffect, useState } from "react"
import {
  TBuildingPermitDoc,
  TBuildingPermitStep,
  TMutateBuildingPermitDoc,
} from "shared/types/building-permit.type"

import { useQueryClient } from "@tanstack/react-query"
import Spinner from "components/Spinner/Spinner"
import { useGetMultiplesDocuments } from "core/ged/query-hooks/useGed"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useAddBuildingPermitDocument } from "core/query-hooks/useBuildingPermits"
import { uniq } from "lodash"
import { MdDelete } from "react-icons/md"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TChildOperation } from "shared/types/operation.type"

export interface IBuildingPermitRowProps {
  buildingPermitStep: TBuildingPermitStep
  childOperation: TChildOperation
  buildingPermitDocuments?: TBuildingPermitDoc
}

export function BuildingPermitRow(props: IBuildingPermitRowProps) {
  const { buildingPermitStep, childOperation, buildingPermitDocuments } = props

  const mutation = useAddBuildingPermitDocument()

  const {
    data: currentDocuments,
    isLoading,
    isError,
  } = useGetMultiplesDocuments(buildingPermitDocuments?.documents ?? [])

  const queryClient = useQueryClient()

  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])

  function handleEditFiles(data: TMutateBuildingPermitDoc) {
    mutation.mutateAsync(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["listBuildingPermitDocuments", childOperation.id],
        })
        queryClient.invalidateQueries({
          queryKey: [
            "getTreeByRegistrationNumbers",
            [childOperation.registrationNumber],
          ],
        })

        setFormFiles([])
      },
    })
  }

  function handleDelete(documentId: number) {
    const updatedDocuments = uniq(
      (buildingPermitDocuments?.documents || []).filter(
        (docId) => docId !== documentId,
      ),
    )

    handleEditFiles({
      childOperation: childOperation.id,
      buildingPermitStepId: buildingPermitStep.id,
      documents: updatedDocuments,
    })
  }

  useEffect(() => {
    if (formFiles.length > 0) {
      const documents = uniq([
        ...formFiles.map((file) => file.id),
        ...(buildingPermitDocuments?.documents || []),
      ])
      handleEditFiles({
        childOperation: childOperation.id,
        buildingPermitStepId: buildingPermitStep.id,
        documents,
      })
    }
  }, [formFiles])

  return (
    <td className="p-2">
      <div className="flex items-center">
        <div className="flex flex-col">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {isError ? (
                <p className="text-red-600">{t("ged-unavailable")}</p>
              ) : (
                currentDocuments &&
                currentDocuments.length > 0 &&
                currentDocuments.map((existingFile) =>
                  existingFile && existingFile ? (
                    <div className="flex items-center" key={existingFile.id}>
                      <GedFile
                        id={existingFile.id}
                        title={existingFile?.title}
                      />
                      <MdDelete
                        title={`${t("delete")}`}
                        fontSize={18}
                        className="transition cursor-pointer opacity-70 hover:opacity-100 duration-150 ease-out hover:ease-in"
                        onClick={() => handleDelete(existingFile.id)}
                      />
                    </div>
                  ) : null,
                )
              )}
            </>
          )}
        </div>
        <PermissionsGuard requiredRoles={["create:permit-document"]}>
          {!buildingPermitDocuments ||
          (buildingPermitDocuments &&
            buildingPermitDocuments.documents &&
            buildingPermitDocuments.documents.length < 3) ? (
            <AddFile
              setFiles={setFormFiles}
              files={formFiles}
              hasIcon
              linkedFiles
              documentTypes={[buildingPermitStep?.documentType].map(
                (docType) => docType.label,
              )}
              maxUploadFiles={1}
              registrationNumber={childOperation.registrationNumber}
            />
          ) : null}
        </PermissionsGuard>
      </div>
    </td>
  )
}
