import { Button } from "components/Button/Button"
import { useState } from "react"
import { t } from "i18next"

interface CopyLinkButtonProps {
  url: string
}

function CopyLinkButton({ url }: CopyLinkButtonProps) {
  const [isCopied, setIsCopied] = useState(false)

  function unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand("copy")
    setIsCopied(true)
    document.body.removeChild(textArea)
  }

  const handleCopy = async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(url)
      setIsCopied(true)
    } else {
      unsecuredCopyToClipboard(url)
    }
  }

  return (
    <div className="flex justify-center items-center h-full">
      <Button type="button" onClick={handleCopy} size="medium" mode="secondary">
        {isCopied ? t("link-copied") : t("copy-link")}
      </Button>
    </div>
  )
}

export default CopyLinkButton
