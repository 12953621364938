import { useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TTranchesCommerciales,
  TTranchesCommercialesMinified,
  TTranchesTravaux,
  TTranchesTravauxWithBailleurs,
} from "shared/types/spo/tranche.type"
import {
  getTranchesCommerciales,
  getTranchesCommercialesByTrancheTravaux,
  getTranchesTravaux,
  listTranchesTravauxWithBailleurs,
} from "../api/tranches"

function useGetTranchesCommerciales(registrationId: string) {
  return useQuery<TTranchesCommerciales, CustomAxiosError>({
    queryKey: ["getTranchesCommerciales", registrationId],
    queryFn: () => getTranchesCommerciales(registrationId),
  })
}

function useGetTranchesCommercialesByTrancheTravaux(
  registrationId: string,
  trancheTravauxId: string,
) {
  return useQuery<TTranchesCommercialesMinified, CustomAxiosError>({
    queryKey: [
      "getTranchesCommercialesByTrancheTravaux",
      trancheTravauxId,
      registrationId,
    ],
    queryFn: () =>
      getTranchesCommercialesByTrancheTravaux(registrationId, trancheTravauxId),
  })
}

function useGetTranchesTravaux(registrationId: string) {
  return useQuery<TTranchesTravaux, CustomAxiosError>({
    queryKey: ["getTranchesTravaux", registrationId],
    queryFn: () => getTranchesTravaux(registrationId),
  })
}

function useListTranchesTravauxWithBailleurs(registrationId: string) {
  return useQuery<TTranchesTravauxWithBailleurs[], CustomAxiosError>({
    queryKey: ["listTranchesTravauxWithBailleurs", registrationId],
    queryFn: () => listTranchesTravauxWithBailleurs(registrationId),
  })
}

export {
  useGetTranchesCommerciales,
  useGetTranchesCommercialesByTrancheTravaux,
  useGetTranchesTravaux,
  useListTranchesTravauxWithBailleurs,
}
