import Box from "components/Box/Box"
import Committee from "components/Icons/Committee"
import { t } from "i18next"
import { NavLink } from "react-router-dom"
import AgreementDates from "../agreement-dates/AgreementDates"
import CountSuspensiveConditions from "../supensive-conditions/CountSuspensiveConditions"

interface IAgreementOperation {
  hasRedirection?: boolean
}

export default function AgreementOperation(props: IAgreementOperation) {
  const { hasRedirection } = props

  return (
    <Box classNames="p-0 h-full">
      {hasRedirection ? (
        <NavLink
          className="flex items-center border-b border-gray-200 px-3.5 py-2 h-10"
          to="agreements"
          type="button"
        >
          <Committee width={20} height={20} color="#164E63" />
          <h3 className="text-xs uppercase text-cyan-900 font-medium pl-2 pt-1">
            {t("agreements")}
          </h3>
        </NavLink>
      ) : (
        <div className="flex items-center border-b border-gray-200 px-3.5 py-2 h-10">
          <Committee width={20} height={20} color="#164E63" />
          <h3 className="text-xs uppercase text-cyan-900 font-medium pl-2 pt-1">
            {t("agreements")}
          </h3>
        </div>
      )}
      <div className="py-4 px-4">
        <AgreementDates />
        <CountSuspensiveConditions />
      </div>
    </Box>
  )
}
