const PERMIT_LABEL = {
  PERMIS: { id: 1, label: "Permis" },
  MODIFICATIF_1: { id: 2, label: "Modificatif 1" },
  MODIFICATIF_2: { id: 3, label: "Modificatif 2" },
  MODIFICATIF_3: { id: 4, label: "Modificatif 3" },
  MODIFICATIF_4: { id: 5, label: "Modificatif 4" },
  MODIFICATIF_5: { id: 6, label: "Modificatif 5" },
  MODIFICATIF_6: { id: 7, label: "Modificatif 6" },
  MODIFICATIF_7: { id: 8, label: "Modificatif 7" },
}

export default PERMIT_LABEL
