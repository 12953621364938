import { useQueryClient } from "@tanstack/react-query"
import classnames from "classnames"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import GedFile from "components/Ged/GedFile"
import Spinner from "components/Spinner/Spinner"
import { useGetMultiplesDocuments } from "core/ged/query-hooks/useGed"
import {
  useCreateCommittee,
  useUpdateCommittee,
} from "core/query-hooks/useCommittes"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import { AddFile } from "features/studies/AddFile"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { MdCheckCircle, MdOutlineFiberManualRecord } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TCommittee, TCommitteeStep } from "shared/types/committee.type"
import { TGedUploadResponse } from "shared/types/ged.type"
import { useDebouncedCallback } from "use-debounce"

export interface ICommitteeModRowProps {
  committeeType: TCommittee
  committeeData: TCommitteeStep | undefined
  index: number
}

export function CommitteeModRow(props: ICommitteeModRowProps) {
  const { id } = useParams()

  const { committeeType, committeeData, index } = props
  const [committeeDate, setCommitteeDate] = useState<Date | null>(
    committeeData?.date ? new Date(committeeData?.date) : null,
  )
  const {
    data: currentDocuments,
    isLoading,
    isError,
  } = useGetMultiplesDocuments(committeeData?.reportDocuments! ?? [])

  useEffect(() => {
    setCommitteeDate(committeeData?.date ? new Date(committeeData?.date) : null)
  }, [committeeData])

  const mutateCommitte = useUpdateCommittee(committeeData?.id!)
  const createCommitte = useCreateCommittee()

  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])

  const queryClient = useQueryClient()

  const handleDateChangeDebounced = useDebouncedCallback(
    (date: Date | null) => {
      setCommitteeDate(date)

      if (committeeData) {
        const data = {
          date: date!,
          isDefinitive: false,
        }

        mutateCommitte.mutateAsync(data, {
          onSuccess() {
            queryClient.invalidateQueries({
              queryKey: ["listCommitteesByOperation", Number(id)],
            })
            toast.success(t("toast-committee-date-success"))
          },
          onError() {
            toast.error(t("toast-committee-date-error"))
          },
        })
      } else {
        const newData = {
          typeId: committeeType.id,
          operationId: Number(id),
          date: date!,
          isDefinitive: false,
        }

        createCommitte.mutateAsync(newData, {
          onSuccess() {
            queryClient.invalidateQueries({
              queryKey: ["listCommitteesByOperation", Number(id)],
            })
            toast.success(t("toast-committee-date-success"))
          },
          onError() {
            toast.error(t("toast-committee-date-error"))
          },
        })
      }
    },
    700,
  )

  function handleDateChange(date: Date | null) {
    handleDateChangeDebounced(date)
  }

  function handleSubmit() {
    if (committeeData) {
      const existingData = {
        isDefinitive: true,
        reportDocuments:
          committeeData.reportDocuments.length > 0
            ? committeeData.reportDocuments.map((docId) => docId)
            : formFiles.map((file) => file.id),
      }

      const { reportDocuments, ...sanitizedValues } = existingData

      return mutateCommitte.mutateAsync(
        reportDocuments.length > 0 ? existingData : sanitizedValues,
        {
          onSuccess() {
            setFormFiles([])
            queryClient.invalidateQueries({
              queryKey: ["listCommitteesByOperation", Number(id)],
            })
            toast.success(t("success-committee-step"))
          },
          onError() {
            toast.error(t("error-committee-step"))
          },
        },
      )
    }
    toast.error(t("error-committee-step"))
    return false
  }

  return (
    <tr
      className={classnames({
        hidden: committeeType.mandatory === false && !committeeData,
      })}
    >
      <td className="py-2 pr-2 pl-4">
        <div className="flex items-center gap-x-2">
          <div>
            {committeeData?.date && committeeData?.isDefinitive ? (
              <MdCheckCircle size={20} color="#0CB28B" />
            ) : (
              <MdOutlineFiberManualRecord
                className="text-slate-200"
                size={26}
              />
            )}
          </div>
          <div>
            {committeeType.label} {index}
          </div>
        </div>
      </td>
      <td className="p-2">
        <div className="Text__Field_Container">
          {committeeData?.date && committeeData?.isDefinitive ? (
            <div className="text-primary-dark px-2">
              {format(new Date(committeeData.date), "dd/MM/yyyy")}
            </div>
          ) : (
            <CustomDatePicker
              id="date"
              key={committeeData?.id}
              locale={fr}
              className="Text__Field"
              selected={
                committeeData?.date ? new Date(committeeData?.date) : null
              }
              placeholderText={`${t("select-date")}`}
              onChange={(e) => handleDateChange(e)}
            />
          )}
        </div>
      </td>
      <td className="p-2">
        <div>
          <div className="flex items-center">
            <div>
              {formFiles.length > 0 &&
                formFiles.map((file) => (
                  <GedFile id={file.id} title={file.title} key={file.id} />
                ))}
            </div>
            {!currentDocuments.length && (
              <AddFile
                setFiles={setFormFiles}
                documentTypes={[committeeType.documentType].map(
                  (docType) => docType.label,
                )}
                hasIcon
              />
            )}
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {isError ? (
                <p className="text-red-600">{t("ged-unavailable")}</p>
              ) : (
                currentDocuments &&
                currentDocuments.length > 0 &&
                currentDocuments.map((existingFile) => (
                  <GedFile
                    id={existingFile?.id!}
                    title={existingFile?.title!}
                    key={existingFile?.id}
                  />
                ))
              )}
            </>
          )}
        </div>
      </td>
      <td className="py-2 pl-2 pr-4">
        <div className="flex justify-end items-center">
          <Button
            onClick={() => handleSubmit()}
            isDisabled={!committeeDate || formFiles.length <= 0}
            isLoading={mutateCommitte.isPending || createCommitte.isPending}
            type="submit"
            size="small"
            mode="primary"
          >
            {t("submit")}
          </Button>
        </div>
      </td>
    </tr>
  )
}
