import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import ButtonIcon from "components/Button/ButtonIcon"
import DateFormat from "components/Date/DateFormat"
import EditDateModal from "components/Modal/EditDateModal"
import useModal from "components/Modal/useModal"
import Spinner from "components/Spinner/Spinner"
import {
  useListCallOfTendersByOperation,
  useUpsertCallOfTender,
} from "core/query-hooks/useCallOfTenders"
import { nanoid } from "nanoid"
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { BsFolderFill } from "react-icons/bs"
import { MdEdit } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { toast } from "react-toastify"
import { TUpsertCallOfTenders } from "shared/types/call-of-tender.type"
import { TIdLabel } from "shared/types/global.type"
import { TChildOperation } from "shared/types/operation.type"

export interface IConsultationDateProps {
  operationId: number
  childOperations: TChildOperation[]
  withOperationNameColumn?: boolean
  technical?: boolean
}

export function DceAoDates(props: IConsultationDateProps) {
  const { operationId, childOperations, withOperationNameColumn, technical } =
    props

  const { t } = useTranslation()
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()

  const LANCEMENT_DCE = { id: 1, label: t("dce-launch-no-caps") }
  const SYNTHESE_DCE = { id: 2, label: t("dce-synthesis-no-caps") }
  const LANCEMENT_AO = { id: 3, label: t("ao-launch-no-caps") }

  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>()
  const [selectedDateDefinitive, setSelectedDateDefinitive] =
    useState<boolean>(false)
  const [selectedChildOperationId, setSelectedChildOperationId] =
    useState<number>()
  const [selectedDateType, setSelectedDateType] =
    useState<TIdLabel>(LANCEMENT_DCE)

  const queryClient = useQueryClient()

  const upsertCallOfTender = useUpsertCallOfTender()
  const { data: consultationDates, isLoading } =
    useListCallOfTendersByOperation(operationId)

  function displayDate(date: Date | null | undefined, isDefinitive: boolean) {
    return date ? (
      <DateFormat date={new Date(date)} isDefinitive={isDefinitive} />
    ) : (
      "-"
    )
  }

  const handleShowModal = (
    dateModal: Date | null | undefined,
    dateIsDefinitiveModal: boolean,
    operationIdModal: number,
    typeDateModal: TIdLabel,
  ) => {
    setSelectedDate(dateModal ? new Date(dateModal) : null)
    setSelectedDateDefinitive(dateIsDefinitiveModal)
    setSelectedChildOperationId(operationIdModal)
    setSelectedDateType(typeDateModal)
    toggleModal()
  }

  const handleSubmitDate = (
    dateModal: Date | null | undefined,
    dateIsDefinitiveModal: boolean,
    operationIdModal: number | undefined,
    typeDateModal: TIdLabel,
  ) => {
    let upsertData: TUpsertCallOfTenders = {
      childOperationId: operationIdModal ?? null,
    }

    switch (typeDateModal.id) {
      case LANCEMENT_DCE.id:
        upsertData = {
          ...upsertData,
          lancementDCE: dateModal ?? null,
          lancementDCEisDefinitive: dateIsDefinitiveModal,
        }
        break
      case SYNTHESE_DCE.id:
        upsertData = {
          ...upsertData,
          syntheseDCE: dateModal ?? null,
          syntheseDCEisDefinitive: dateIsDefinitiveModal,
        }
        break
      case LANCEMENT_AO.id:
        upsertData = {
          ...upsertData,
          lancementAO: dateModal ?? null,
          lancementAOisDefinitive: dateIsDefinitiveModal,
        }
        break
      default:
        break
    }

    upsertCallOfTender.mutate(upsertData, {
      onSuccess() {
        toast.success(t("operation-updated"))
        queryClient.invalidateQueries()
        toggleModal()
      },
      onError() {
        toast.error(t("error-loading-date"))
      },
    })
    return { dateModal, dateIsDefinitiveModal }
  }

  return (
    <Box classNames="p-0">
      {technical ? (
        <div className="flex items-center justify-between border-gray-200 px-4 pt-2">
          <div className="flex items-center text-cyan-900 font-medium">
            <BsFolderFill />
            <h3 className="text-xs uppercase pl-2">
              {t("consultation-file-call-for-tender")}
            </h3>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between border-gray-200 px-4 pt-2">
          <NavLink to="technical" type="button">
            <div className="flex items-center text-cyan-900 font-medium">
              <BsFolderFill />
              <h3 className="text-xs uppercase pl-2">
                {t("consultation-file-call-for-tender")}
              </h3>
            </div>
          </NavLink>
        </div>
      )}

      <div>
        <div className="Light_Table__Wrapper overflow-auto mt-2">
          <table className="Table table-fixed">
            <thead>
              <tr>
                {withOperationNameColumn && (
                  <th className="Table__Head text-left">{t("operations")}</th>
                )}
                <th className="Table__Head text-left">{t("dce-launch")}</th>
                <th className="Table__Head text-left">{t("dce-synthesis")}</th>
                <th className="Table__Head text-left">{t("ao-launch")}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={4}>
                    <div className="flex items-center justify-center p-4">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
              {consultationDates &&
                childOperations.map((childOp) => {
                  const consultationDate = consultationDates?.find(
                    (cd) => cd.childOperationId === childOp.id,
                  )
                  return (
                    <Fragment key={nanoid()}>
                      <tr>
                        {withOperationNameColumn && (
                          <td className="font-semibold">
                            {childOp.registrationNumber}
                          </td>
                        )}
                        <td className="group">
                          <div className="flex items-center justify-start mx-4">
                            {consultationDate?.lancementDCE
                              ? displayDate(
                                  consultationDate.lancementDCE,
                                  consultationDate.lancementDCEisDefinitive,
                                )
                              : "-"}

                            <ButtonIcon
                              className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              type="button"
                              onClick={() =>
                                handleShowModal(
                                  consultationDate?.lancementDCE,
                                  consultationDate?.lancementDCEisDefinitive ||
                                    false,
                                  childOp.id,
                                  LANCEMENT_DCE,
                                )
                              }
                            >
                              <MdEdit
                                size={22}
                                cursor="pointer"
                                className="text-cyan-800"
                              />
                            </ButtonIcon>
                          </div>
                        </td>
                        <td className="group">
                          <div className="flex items-center justify-start mx-4">
                            {consultationDate?.syntheseDCE
                              ? displayDate(
                                  consultationDate.syntheseDCE,
                                  consultationDate.syntheseDCEisDefinitive,
                                )
                              : "-"}

                            <ButtonIcon
                              className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              type="button"
                              onClick={() =>
                                handleShowModal(
                                  consultationDate?.syntheseDCE,
                                  consultationDate?.syntheseDCEisDefinitive ||
                                    false,
                                  childOp.id,
                                  SYNTHESE_DCE,
                                )
                              }
                            >
                              <MdEdit
                                size={22}
                                cursor="pointer"
                                className="text-cyan-800"
                              />
                            </ButtonIcon>
                          </div>
                        </td>
                        <td className="group">
                          <div className="flex items-center justify-start mx-4">
                            {consultationDate?.lancementAO
                              ? displayDate(
                                  consultationDate.lancementAO,
                                  consultationDate.lancementAOisDefinitive,
                                )
                              : "-"}

                            <ButtonIcon
                              className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              type="button"
                              onClick={() =>
                                handleShowModal(
                                  consultationDate?.lancementAO,
                                  consultationDate?.lancementAOisDefinitive ||
                                    false,
                                  childOp.id,
                                  LANCEMENT_AO,
                                )
                              }
                            >
                              <MdEdit
                                size={22}
                                cursor="pointer"
                                className="text-cyan-800"
                              />
                            </ButtonIcon>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      {isModalShowed && (
        <EditDateModal
          isModalShowed={isModalShowed}
          toggleModal={toggleModal}
          initialDate={selectedDate}
          isDefinitive={selectedDateDefinitive}
          onSubmit={(DateModal, dateIsDefinitiveModal) =>
            handleSubmitDate(
              DateModal,
              dateIsDefinitiveModal,
              selectedChildOperationId,
              selectedDateType,
            )
          }
          isPending={upsertCallOfTender.isPending}
          title={`${t("edit-date-var")} ${selectedDateType!.label}`}
          labelDate={`${t("date")}`}
          requiredDate
        />
      )}
    </Box>
  )
}
