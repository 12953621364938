import { Button } from "components"
import Box from "components/Box/Box"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useListChildOperations } from "core/query-hooks/useOperations"
import AgreeementOperationList from "features/agreements/AgreementOperationList"
import GedTree from "features/ged/GedTree"
import AgreementOperation from "features/operations/agreement/AgreementOperation"
import SuspensiveConditionsContainer from "features/operations/supensive-conditions/list/SuspensiveConditionsListContainer"
import TaskListContainer from "features/operations/tasks/TaskListContainer"
import { t } from "i18next"
import { useNavigate, useParams } from "react-router-dom"
import { OPERATION_AGREEMENTS_VIEWTAG } from "shared/resources/view-tags.resources"
import DivStickyMenu from "./DivStickyMenu"

export default function OperationAgreements() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: operationsChildren } = useListChildOperations(Number(id!))
  const registrationNumbers = operationsChildren?.map(
    (oc) => oc.registrationNumber,
  )

  return (
    <>
      <DivStickyMenu />
      <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 pt-4 pb-10 px-4">
        <div className="col-span-3">
          <div className="col-span-6">
            <div className="flex justify-between items-center mb-2">
              <div>
                <Header>{`${t("agreements")}`}</Header>
              </div>
              <QueryBoundaries loadingComponent={<Spinner />}>
                <PermissionsGuard requiredRoles={["create:agreement"]}>
                  <Button
                    onClick={() => navigate(`/operation/${id}/agreements/add`)}
                    size="medium"
                    mode="primary"
                    isLoading={false}
                  >
                    {t("add")}
                  </Button>
                </PermissionsGuard>
              </QueryBoundaries>
            </div>
          </div>
          <QueryBoundaries loadingComponent={<Spinner />}>
            <div className="col-span-6">
              <PermissionsGuard requiredRoles={["read:agreement"]}>
                <AgreeementOperationList />
              </PermissionsGuard>

              <div className="col-span-1 pt-4">
                <PermissionsGuard requiredRoles={["read:operation"]}>
                  <AgreementOperation />
                </PermissionsGuard>
              </div>

              <div className="py-4">
                <PermissionsGuard requiredRoles={["read:suspensive-condition"]}>
                  <SuspensiveConditionsContainer />
                </PermissionsGuard>
              </div>

              <PermissionsGuard requiredRoles={["read:task"]}>
                <TaskListContainer />
              </PermissionsGuard>
            </div>
          </QueryBoundaries>
        </div>

        {registrationNumbers ? (
          <div className="pt-4 lg:pt-0 col-span-1 mb-4">
            <div className="mb-2">
              <Header>{`${t("documents")}`}</Header>
            </div>
            <Box>
              <GedTree
                view={OPERATION_AGREEMENTS_VIEWTAG}
                registrationNumbers={registrationNumbers}
              />
            </Box>
          </div>
        ) : null}
      </div>
    </>
  )
}
