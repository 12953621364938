import { useQueryClient } from "@tanstack/react-query"
import { DeleteModal } from "components/DeleteModal/DeleteModal"
import useDeleteModal from "components/DeleteModal/useDeleteModal"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useTranslation } from "react-i18next"
import { Link, useMatches, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Box from "../../components/Box/Box"
import { Button } from "../../components/Button/Button"
import { useDeletePlot, useGetPlot } from "../../core/query-hooks/usePlots"

interface IPlotShowProps {
  projectContext: boolean
}

export default function PlotShow(props: IPlotShowProps) {
  const matches = useMatches()
  const { plotId, id, agreementId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { projectContext } = props

  const fetchPlot = useGetPlot(plotId!)
  const deletePlot = useDeletePlot(plotId!)
  const queryClient = useQueryClient()

  const { isShowing: isModalShowed, toggle: toggleModal } = useDeleteModal()
  const onDeletePlot = async () => {
    await deletePlot.mutateAsync(plotId!, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["plots", +id!] })
        toast.success(`${t("plots-delete-toast")}`)
      },
      onError: (err) => {
        toast.error(`${err.response?.data.error.message}, ${id}`)
      },
    })
    toggleModal()
    projectContext
      ? navigate(`/project/${id}/plots`)
      : navigate(`/operation/${id}/agreements/${agreementId}/plots`)
  }

  return (
    <>
      <Box classNames="m-4">
        <div className="Border__Bottom_LightGrey Padding__Bottom_8 flex justify-between items-center">
          <div className="ml-2">
            {projectContext ? (
              matches
                .filter((match, index) => index !== 0)
                .map((match, index) =>
                  index === 0 ? (
                    <Link
                      className="Breadcrumb__Link"
                      id={`breadcrumb${-index}`}
                      to={`${match.pathname}/plots`}
                      key={`${match.pathname}`}
                    >
                      {`${t("plots-show-breadCrumb")}`}
                    </Link>
                  ) : (
                    <span
                      className="Breadcrumb__Active pl-2"
                      id={`breadcrumb${-index}`}
                      key={`${fetchPlot.data?.prefix} ${fetchPlot.data?.section} ${fetchPlot.data?.number}`}
                    >
                      {" "}
                      / {fetchPlot.data?.prefix} {fetchPlot.data?.section}{" "}
                      {fetchPlot.data?.number}
                    </span>
                  ),
                )
            ) : (
              <>
                <Link
                  className="Breadcrumb__Link"
                  id="back"
                  to={`/operation/${id}/agreements/${agreementId}/plots`}
                >
                  {`${t("back")}`}
                </Link>{" "}
                /{" "}
                {`${fetchPlot.data?.prefix} ${fetchPlot.data?.section} ${fetchPlot.data?.number}`}
              </>
            )}
          </div>
          <div>
            <PermissionsGuard requiredRoles={["delete:plot"]}>
              <Button
                onClick={toggleModal}
                size="small"
                mode="secondary"
                classNames="Margin__Right_16"
              >
                {`${t("delete")}`}
              </Button>
            </PermissionsGuard>
            {projectContext ? (
              <Button
                onClick={() => navigate(`/project/${id}/plots/${plotId}/edit`)}
                size="small"
                mode="primary"
              >
                {`${t("edit")}`}
              </Button>
            ) : null}
          </div>
        </div>
        <ul className="ListStyle__None Padding__0">
          <li className="Flex Items__Center Justify__Between Padding__md_16">
            <p className="Text__sm Color__Primary Opacity__70">
              {`${t("denomination")}`}
            </p>{" "}
            <p className="Text__sm Color__Primary">
              {fetchPlot.data?.prefix} {fetchPlot.data?.section}{" "}
              {fetchPlot.data?.number}
            </p>
          </li>
          <li className="Flex Items__Center Justify__Between Padding__md_16">
            <p className="Text__sm Color__Primary Opacity__70">
              {" "}
              {`${t("surface")}`}
            </p>{" "}
            <p className="Text__sm Color__Primary">
              {typeof fetchPlot.data?.area.val === "number"
                ? fetchPlot.data?.area.val.toLocaleString("fr-FR")
                : fetchPlot.data?.area.val}{" "}
              m²
            </p>
          </li>
          <li className="Flex Items__Center Justify__Between Padding__md_16">
            <p className="Text__sm Color__Primary Opacity__70">
              {" "}
              {`${t("price")}`}
            </p>{" "}
            <p className="Text__sm Color__Primary">
              {fetchPlot.data?.price?.val
                ? parseFloat(fetchPlot.data.price.val).toLocaleString("fr-FR")
                : "-"}
              {fetchPlot.data?.price?.unit === "EUR" ? "€" : undefined}
              {fetchPlot.data?.price?.unit === "VND" ? "₫" : undefined}
            </p>
          </li>
          <li className="Flex Items__Center Justify__Between Padding__md_16">
            <p className="Text__sm Color__Primary Opacity__70">
              {" "}
              {`${t("owner")}`}
            </p>{" "}
            <p className="Text__sm Color__Primary">
              {fetchPlot.data?.owner ? fetchPlot.data?.owner : "-"}
            </p>
          </li>
        </ul>
      </Box>
      <DeleteModal
        isShowing={isModalShowed}
        closeModal={toggleModal}
        title={`${t("plots-delete-title")}`}
        description={`${t("plots-delete-description")}`}
        deleteItem={() => onDeletePlot()}
        loading={deletePlot.isPending}
      />
    </>
  )
}
