import { t } from "i18next"
import { FaCheck } from "react-icons/fa"
import { TFundRaisingAppeal } from "shared/types/spo/appelDeFond.type"
import { Certificate } from "./Certificate"
import { JalonDate } from "./JalonDate"
import { SupportingDocuments } from "./SupportingDocuments"

export interface IProductionTrackingRowProps {
  fundRaisingAppeal: TFundRaisingAppeal
  trancheTravauxId: string
  lessor?: string | null
}

export function ProductionTrackingRow(props: IProductionTrackingRowProps) {
  const { fundRaisingAppeal, trancheTravauxId, lessor } = props

  function displayCapitalCall(status: string) {
    const baseClass =
      "inline-flex items-center justify-center rounded-full px-3 py-1 w-32"

    switch (status) {
      case "APPELE":
        return (
          <span className={`${baseClass} bg-orange-300 text-orange-800`}>
            {t("called")}
          </span>
        )
      case "ENCAISSE":
        return (
          <span className={`${baseClass} bg-green-300 text-green-800`}>
            <FaCheck className="w-4 h-4 mr-2" />
            {t("cashed")}
          </span>
        )
      case "REALISE":
        return (
          <span className={`${baseClass} bg-yellow-200 text-yellow-800`}>
            {t("realized-masc")}
          </span>
        )
      default:
        return (
          <span className={`${baseClass} bg-gray-300 text-gray-800`}>
            {t("pending")}
          </span>
        )
    }
  }

  return (
    <tr key={fundRaisingAppeal.termCode}>
      <td className="Table__Cell w-max">{fundRaisingAppeal.termLabel}</td>
      <td className="group" style={{ width: "200px" }}>
        <div className="flex items-center justify-between">
          <JalonDate
            fundRaisingAppeal={fundRaisingAppeal}
            trancheTravaux={trancheTravauxId}
            lessor={lessor}
          />
        </div>
      </td>
      <td className="Table__Cell w-46">
        <Certificate
          fundRaisingAppeal={fundRaisingAppeal}
          trancheTravauxId={trancheTravauxId}
          lessor={lessor}
        />
      </td>
      <td className="Table__Cell w-max">
        <SupportingDocuments
          fundRaisingAppeal={fundRaisingAppeal}
          trancheTravauxId={trancheTravauxId}
          lessor={lessor}
        />
      </td>
      <td>{displayCapitalCall(fundRaisingAppeal.termStatus)}</td>
    </tr>
  )
}
