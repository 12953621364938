import classNames from "classnames"
import Avatar from "components/Avatar/Avatar"
import Spinner from "components/Spinner/Spinner"
import { useGetUserByAzureId } from "core/query-hooks/useUsers"
import { format } from "date-fns"
import { t } from "i18next"
import { ReactNode } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { TStudyRequest, TStudyRequestHistory } from "shared/types/study.type"
import "./follow-up-stepper.scss"
import {
  STATUS_CREATED,
  STATUS_DRAFT,
  STATUS_PRIORIZATION_STANDBY,
  STATUS_PRIORIZED_AND_ASSIGNED,
  STATUS_REJECTED,
  STATUS_RESTITUTION_STANDBY,
  STATUS_RETURNED,
  STATUS_VALIDATED,
} from "./studyRequest.resources"

interface FollowUpCancelledProps {
  studyRequest: TStudyRequest | undefined
  studyRequestHistory: TStudyRequestHistory[] | undefined
}

export default function FollowUpCancelled(props: FollowUpCancelledProps) {
  const { studyRequest, studyRequestHistory } = props
  const creation = studyRequestHistory?.find(
    (element: TStudyRequestHistory) => element.statusId === STATUS_CREATED,
  )
  const validation = studyRequestHistory?.find(
    (element: TStudyRequestHistory) => element.statusId === STATUS_VALIDATED,
  )
  const rejection = studyRequestHistory?.find(
    (element: TStudyRequestHistory) => element.statusId === STATUS_REJECTED,
  )
  const restitution = studyRequestHistory?.find(
    (element: TStudyRequestHistory) => element.statusId === STATUS_RETURNED,
  )
  const statusBeforeCancel: TStudyRequestHistory | undefined =
    studyRequestHistory?.[1]

  let attributed: ReactNode = null
  if (studyRequest?.assignedUsers) {
    attributed = studyRequest.assignedUsers.map((azureId: string) => {
      const { data: user } = useGetUserByAzureId(azureId)
      if (user) {
        return (
          <div className="inline-flex items-center ml-2" key={azureId}>
            <Avatar size="small" user={user} />{" "}
            <div className="ml-1">
              {user?.firstName} {user?.lastName}
            </div>
          </div>
        )
      }
      return false
    })
  }

  if (statusBeforeCancel) {
    return (
      <ul className="follow-up-stepper">
        {/* Création */}
        <li
          className={classNames("pb-4 follow-up-stepper-li", {
            "step-done": statusBeforeCancel.statusId >= STATUS_CREATED,
            "step-cancelled": statusBeforeCancel.statusId < STATUS_CREATED,
          })}
        >
          {statusBeforeCancel.statusId > STATUS_DRAFT && (
            <RiCheckboxCircleFill
              fontSize={23}
              className="step-done-icon z-10"
            />
          )}
          <div>
            <h5 className="Color__Primary -mb-1 text-sm">{`${t(
              "studyRequest-projectCreation",
            )}`}</h5>
          </div>
          <span className="text-xs step-more-info">
            {creation ? (
              <>
                {`${t("by")} ${creation.operator.firstName}  ${
                  creation.operator.lastName
                }, ${t("the")} ${format(
                  new Date(creation.date),
                  "dd/MM/yyyy",
                )}`}
              </>
            ) : null}
          </span>
        </li>

        {/* Demande de validation */}
        <li
          className={classNames("pb-4 follow-up-stepper-li", {
            "step-done":
              statusBeforeCancel.statusId >= STATUS_VALIDATED &&
              statusBeforeCancel.statusId !== STATUS_REJECTED,
            "step-cancelled": statusBeforeCancel.statusId < STATUS_VALIDATED,
          })}
        >
          {statusBeforeCancel.statusId >= STATUS_VALIDATED &&
            statusBeforeCancel.statusId !== STATUS_REJECTED && (
              <RiCheckboxCircleFill
                fontSize={23}
                className="step-done-icon z-10"
              />
            )}
          <div>
            <h5 className="Color__Primary -mb-1 text-sm">
              {`${t("studyRequest-validationRequest")}`}
            </h5>
          </div>
          <span className="text-xs step-more-info">
            {validation ? (
              <>
                {`${t("by")} ${validation.operator.firstName}  ${
                  validation.operator.lastName
                }, ${t("the")} ${format(
                  new Date(validation.date),
                  "dd/MM/yyyy",
                )}`}
              </>
            ) : null}
            {rejection ? (
              <>
                {`${t("by")} ${rejection.operator.firstName}  ${
                  rejection.operator.lastName
                }, ${t("the")} ${format(
                  new Date(rejection.date),
                  "dd/MM/yyyy",
                )}`}
              </>
            ) : null}
          </span>
          <div>
            {validation && (
              <span
                className={classNames(
                  "Color__Primary text-xs font-semibold inline-block py-1 px-2 rounded-lg last:mr-0 mr-1 mt-2",
                )}
              >
                {studyRequest?.validationComment}
              </span>
            )}
            {rejection && (
              <span
                className={classNames(
                  "Color__Primary text-xs font-semibold inline-block py-1 px-2 rounded-lg last:mr-0 mr-1 mt-2 bg-red-200",
                )}
              >
                {studyRequest?.validationComment}
              </span>
            )}
          </div>
        </li>

        {/* Priorisation et attribution */}
        <li
          className={classNames("pb-4 follow-up-stepper-li", {
            "step-done":
              statusBeforeCancel.statusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
              statusBeforeCancel.statusId !== STATUS_PRIORIZATION_STANDBY,
            "step-cancelled":
              statusBeforeCancel.statusId < STATUS_PRIORIZED_AND_ASSIGNED ||
              statusBeforeCancel.statusId === STATUS_PRIORIZATION_STANDBY,
          })}
        >
          {statusBeforeCancel.statusId >= STATUS_PRIORIZED_AND_ASSIGNED &&
            statusBeforeCancel.statusId !== STATUS_PRIORIZATION_STANDBY && (
              <RiCheckboxCircleFill
                fontSize={23}
                className="step-done-icon z-10"
              />
            )}
          <div>
            <h5 className="Color__Primary -mb-1 text-sm">
              {`${t("study-step-priorise-next")}`}
            </h5>
          </div>
          {statusBeforeCancel.statusId >= STATUS_PRIORIZED_AND_ASSIGNED && (
            <div className="flex flex-col mt-1">
              <span className="text-xs step-more-info">
                {restitution ? (
                  <>
                    {`${t("by")} ${restitution.operator.firstName}  ${
                      restitution.operator.lastName
                    }, ${t("the")} ${format(
                      new Date(restitution.date),
                      "dd/MM/yyyy",
                    )}`}
                  </>
                ) : null}
              </span>
              <span className="text-xs step-more-info">
                {studyRequest?.assignmentComment ? (
                  <>
                    {`${t("comment")}`}: {studyRequest.assignmentComment}{" "}
                  </>
                ) : null}
              </span>
              <span className="text-xs step-more-info">
                {studyRequest?.priorisation ? (
                  <>
                    {`${t("priorisation")}`}: {studyRequest.priorisation}{" "}
                  </>
                ) : null}
              </span>
              <div className="inline-flex items-center">
                <span className="text-xs step-more-info">
                  {studyRequest?.assignedUsers &&
                  studyRequest.assignedUsers.length > 0
                    ? `${t("studyRequest-attribution")} :`
                    : null}
                  {studyRequest?.assignedUsers ? attributed : null}
                </span>
              </div>
            </div>
          )}
        </li>

        {/* Restitution */}
        <li
          className={classNames("pb-4 follow-up-stepper-li", {
            "step-cancelled":
              statusBeforeCancel.statusId === STATUS_PRIORIZED_AND_ASSIGNED ||
              statusBeforeCancel.statusId === STATUS_RESTITUTION_STANDBY,
          })}
        >
          <div>
            <h5 className="Color__Primary mb-1 text-sm">{`${t(
              "study-step-restitution-next",
            )}`}</h5>
          </div>
        </li>
      </ul>
    )
  }

  return <Spinner />
}
