import classNames from "classnames"
import { fr } from "date-fns/locale/fr"
import { forwardRef } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"

registerLocale("fr", fr)

interface IToggleProps {
  placeholder: string
  name: string
  props?: any
  className?: string
  id: string
  label: string
  control: any
}

const DateField = forwardRef<ReactDatePicker, IToggleProps>(
  ({ placeholder, name, className, id, label, control, ...props }, ref) => (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <ReactDatePicker
          name={name}
          ref={ref as React.LegacyRef<ReactDatePicker>}
          dateFormat="dd/MM/yyyy"
          locale={fr}
          aria-label={label}
          className={classNames(["Text__Field", className])}
          id={id}
          {...props}
          value={field.value ? new Date(field.value).toLocaleDateString() : ""}
          placeholderText={placeholder}
          onChange={(e) => field.onChange(e)}
        />
      )}
    />
  ),
)

export default DateField
