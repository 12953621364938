import classNames from "classnames"
import { BsThreeDots } from "react-icons/bs"
import { Fragment } from "react/jsx-runtime"

interface BreadcrumbItem {
  id: string
  name: React.ReactNode | string
}

export interface ISharepointBreadcrumbProps {
  breadcrumb: BreadcrumbItem[]
  onBreadcrumbClick: (index: number) => void
}

export function SharepointBreadcrumb(props: ISharepointBreadcrumbProps) {
  const { breadcrumb, onBreadcrumbClick } = props
  return (
    <div className="flex items-center text-blue-600">
      {breadcrumb.length > 3 ? (
        <>
          <span className="cursor-pointer" onClick={() => onBreadcrumbClick(0)}>
            {breadcrumb[0].name}
          </span>
          <span className="mx-2">&gt;</span>
          <span
            className="cursor-pointer mx-2 px-2 py-1 hover:bg-blue-100 rounded"
            onClick={() => onBreadcrumbClick(breadcrumb.length - 2)} // Retourne à l'avant-dernier
          >
            <BsThreeDots />
          </span>
          <span className="mx-2">&gt;</span>
          <span
            className={classNames(`cursor-pointer`, "font-bold")}
            onClick={() => onBreadcrumbClick(breadcrumb.length - 1)}
          >
            {breadcrumb[breadcrumb.length - 1].name}
          </span>
        </>
      ) : (
        breadcrumb.map((item, index) => (
          <Fragment key={item.id}>
            <span
              className={classNames(`cursor-pointer`, {
                "font-bold": index === breadcrumb.length - 1,
              })}
              onClick={() => onBreadcrumbClick(index)}
            >
              {item.name}
            </span>
            {index < breadcrumb.length - 1 && (
              <span className="mx-2">&gt;</span>
            )}
          </Fragment>
        ))
      )}
    </div>
  )
}
