import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Pagination } from "components/Pagination/Pagination"
import { SearchInput } from "components/SearchInput/SearchInput"
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch"
import {
  useGetActionsByProjectId,
  useValidateAction,
} from "core/query-hooks/useActions"
import ActionRow from "features/actions/ActionRow"
import IHOCLoader from "hoc/HocLoader"
import { t } from "i18next"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { MdCheckCircle, MdOutlineFiberManualRecord } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useDebouncedCallback } from "use-debounce"
import "./ActionTable.scss"

export default function ActionList() {
  const { id } = useParams()
  const [filterDone, setFilterDone] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resultsLength] = useState(8)
  const [searchText, setSearchText] = useState<string>()
  const offset = (currentPage ? currentPage - 1 : 0) * resultsLength
  const queryClient = useQueryClient()
  const validate = useValidateAction()
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")
  const [sortColumn, setSortColumn] = useState<string>()
  // Le back attend un parametre indiquant si une l'action est isDefinitive ou pas
  // On renvoit !filterDone car si on veut masquer les actions réalisées on veut isDefinitive à false : soit l'inverse du toggle
  // On renvoit undefined sinon, car on veut les actions avec isDefinitive = true & isDefinitive = true et pas l'un ou l'autre
  const {
    data: actions,
    error,
    isLoading,
    isError,
    refetch,
  } = useGetActionsByProjectId(
    +id!,
    searchText,
    offset,
    resultsLength,
    sortOrder,
    sortColumn,
    filterDone ? !filterDone : undefined,
  )

  useEffect(() => {
    refetch()
  }, [filterDone, sortColumn, sortOrder, searchText, offset, resultsLength])

  const handleSort = (field: string) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    setSortColumn(field)
  }

  const handleFilterActions = () => {
    setFilterDone(!filterDone)
    setCurrentPage(1)
  }

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage)
  }

  const toggleValidateAction = (actionId: number, isDefinitive: boolean) => {
    validate.mutate(
      { id: actionId, isDefinitive },
      {
        onSuccess: (data) => {
          data.isDefinitive
            ? toast.success(`${t("toast-action-validated")}`)
            : toast.success(`${t("toast-action-invalidated")}`)
          queryClient.invalidateQueries({
            queryKey: ["getActionsByProjectId", +id!],
          })
          queryClient.invalidateQueries({
            queryKey: ["getActionByActionId", data.id],
          })
        },
        onError: (err) => {
          toast.error(
            `${t("toast-action-update-error")} ${
              err.response?.status === 403
                ? t("only-creator-and-target-can-validate")
                : ""
            }`,
          )
        },
      },
    )
  }

  const handleTextChange = useDebouncedCallback(
    useCallback(
      (value: string) => {
        setSearchText(value)
        setCurrentPage(1)
      },
      [searchText],
    ),
    500,
  )

  const displaySortOrder = (header: string) => {
    if (sortOrder && header === sortColumn) {
      return sortOrder === "asc" ? "↑" : "↓"
    }

    return null
  }

  return (
    <div className="flex whitespace-pre-line shadow-lg rounded-lg">
      <div className="Action__Table w-full">
        <div className="px-2 pt-2 pr-1 md:pr-4 flex justify-between items-center flex-wrap">
          <div className="w-1/3 mb-2">
            <SearchInput
              searchLabel={`${t("action-search")}`}
              handleChange={debounce(handleTextChange, 600)}
            />
          </div>
          <div className="flex items-center justify-end">
            <label
              className="mr-2 ml-1 text-sm Color__Primary"
              htmlFor="actions-toggle-btn"
            >
              {`${t("toggle-done-button")} `}

              <ToggleSwitch
                id="actions-toggle-btn"
                name="toggle-not-done-actions"
                disabled={false}
                checked={filterDone}
                onChange={handleFilterActions}
              />
            </label>
          </div>
        </div>
        <div>
          <IHOCLoader error={error} isLoading={isLoading} isError={isError}>
            <table className="w-full whitespace-pre-line">
              <thead>
                <tr>
                  <th className="text-left Table__Head Table__Sticky_Column">
                    {" "}
                  </th>
                  <th
                    className="text-left Table__Head Table__Sticky_Column"
                    onClick={() => handleSort("createdAt")}
                  >
                    {`${t("creation-date")}`}
                    {displaySortOrder("createdAt")}
                  </th>
                  <th
                    className="text-left Table__Head Table__Sticky_Column"
                    onClick={() => handleSort("description")}
                  >
                    {`${t("description")}`} {displaySortOrder("description")}
                  </th>
                  <th
                    className="text-center Table__Head"
                    onClick={() => handleSort("creator")}
                  >
                    {`${t("issuer")}`} {displaySortOrder("creator")}
                  </th>
                  <th
                    className="text-center Table__Head"
                    onClick={() => handleSort("personInCharge")}
                  >
                    {`${t("assigned-to")}`} {displaySortOrder("personInCharge")}
                  </th>
                  <th
                    className="text-left Table__Head"
                    onClick={() => handleSort("deadline")}
                  >
                    {`${t("deadline")}`} {displaySortOrder("deadline")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {actions &&
                  actions.data?.map((action, index) => (
                    <tr className="Table__Row" key={`action-item-${action.id}`}>
                      <td
                        className={classNames(
                          "Table__TD w-12 Table__Sticky_Column",
                          {
                            "border-none":
                              index === actions.data.length - 1 &&
                              index !== actions.data.length - 2,
                            Table__Border_Bottom:
                              index !== actions.data.length - 1 &&
                              index !== actions.data.length - 2,
                          },
                        )}
                      >
                        <span
                          onClick={() =>
                            toggleValidateAction(
                              action.id,
                              !action.isDefinitive,
                            )
                          }
                          className="cursor-pointer items-center flex justify-center"
                        >
                          {action.isDefinitive ? (
                            <MdCheckCircle size={20} color="#0CB28B" />
                          ) : (
                            <MdOutlineFiberManualRecord
                              className="text-slate-200 hover:text-emerald-600 hover:opacity-40"
                              size={26}
                            />
                          )}
                        </span>
                      </td>
                      <ActionRow
                        action={action}
                        border={
                          index !== actions.data.length - 1 &&
                          index !== actions.data.length - 2
                        }
                      />
                    </tr>
                  ))}
                {searchText &&
                  actions &&
                  actions.data &&
                  actions.data?.length === 0 && (
                    <tr>
                      <td className="text-center p-2 Color__Primary text-sm">
                        {`${t("no-action-search-found-message")}`} {searchText}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </IHOCLoader>
        </div>
        <div className="Table__Page_Small_Info_Container">
          {actions && (
            <span className="Table__Page_Info_Content">
              <p>
                {offset + 1} {`${t("to")}`}{" "}
                {currentPage ===
                Math.ceil(actions.metadata.maxTotalHits / resultsLength)
                  ? actions.metadata.maxTotalHits
                  : actions.data.length * (offset + 1)}
              </p>
              <p className="pl-1">
                {`${" "}  ${t("on")} ${actions.metadata.maxTotalHits}`}
              </p>
            </span>
          )}
        </div>
        {actions && actions.metadata.maxTotalHits / resultsLength > 1 && (
          <Pagination
            previousLabel={`${t("previous")}`}
            nextLabel={`${t("next")}`}
            page={currentPage || 1}
            totalPages={Math.ceil(
              actions.metadata.maxTotalHits / resultsLength,
            )}
            handlePagination={(nextPage) => handlePageChange(nextPage)}
          />
        )}
      </div>
    </div>
  )
}
