import { useQueryClient } from "@tanstack/react-query"
import GedFile from "components/Ged/GedFile"
import Spinner from "components/Spinner/Spinner"
import { useGetMultiplesDocuments } from "core/ged/query-hooks/useGed"
import { useSendCertificateDepositMail } from "core/query-hooks/useOperations"
import { useUpdateAppelDefondDocuments } from "core/spo/query-hooks/useAppelDeFond"
import { useUpdateJalon } from "core/spo/query-hooks/useProductions"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CERTIFICATE_BET_DOCUMENT_TYPE,
  MAX_CERTIFICATES_DOCUMENTS,
} from "shared/resources/fundRaisingAppeal.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TFundRaisingAppeal } from "shared/types/spo/appelDeFond.type"
import { TDateJalon } from "shared/types/spo/datesPlanning.type"
import { AddProductionDocuments } from "../AddProductionDocuments"

export interface ICertificate {
  fundRaisingAppeal: TFundRaisingAppeal
  trancheTravauxId: string
  lessor?: string | null
}

export function Certificate(props: ICertificate) {
  const { fundRaisingAppeal, trancheTravauxId, lessor } = props
  const queryClient = useQueryClient()

  const {
    data: currentDocuments,
    isLoading,
    isError,
  } = useGetMultiplesDocuments(fundRaisingAppeal?.certificates ?? [])

  const mutation = useUpdateAppelDefondDocuments(fundRaisingAppeal.termCode)
  const updateFundRaisingAppeal = useUpdateJalon(fundRaisingAppeal.dateCode)

  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])
  const sendCertificateMail = useSendCertificateDepositMail()
  const { id: operationId } = useParams()

  const handleGetDate = async (date: TDateJalon) => {
    updateFundRaisingAppeal.mutateAsync(date, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["getAppelDeFondList", trancheTravauxId, lessor],
        })
        if (date.isDefinitive) {
          sendCertificateMail.mutateAsync({
            operationId: +operationId!,
            termLabel: fundRaisingAppeal.termLabel,
          })
        }
      },
      onError: (err) => {
        toast.error(
          `${t("fund-raising-appeal-errored")} : ${
            err.response?.data?.message
          }`,
        )
      },
    })
  }

  const updateDocuments = async (docs: number[]): Promise<void> => {
    mutation.mutateAsync(
      {
        certificates: docs,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["getAppelDeFondList", trancheTravauxId, lessor],
          })
          setFormFiles([])
        },
      },
    )
  }

  useEffect(() => {
    if (formFiles.length > 0) {
      const updateDocs = async () => {
        await updateDocuments(formFiles.map((doc) => doc.id))
      }

      updateDocs()
    }
  }, [formFiles])

  const handleDeleteDocument = async () => {
    await updateDocuments([])
  }

  return (
    <div className="flex items-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isError ? (
            <p className="text-red-600">{`${t("ged-unavailable")}`}</p>
          ) : (
            currentDocuments &&
            currentDocuments.length > 0 &&
            currentDocuments.map(
              (existingFile) =>
                existingFile && (
                  <div className="flex items-center" key={existingFile.id}>
                    <GedFile id={existingFile.id} title={existingFile?.title} />
                    <MdDelete
                      title={`${t("delete")}`}
                      fontSize={18}
                      className="transition cursor-pointer opacity-70 hover:opacity-100 duration-150 ease-out hover:ease-in"
                      onClick={() => handleDeleteDocument()}
                    />
                  </div>
                ),
            )
          )}
        </>
      )}
      <AddProductionDocuments
        setFiles={setFormFiles}
        files={formFiles}
        isCertificate
        documentTypes={[CERTIFICATE_BET_DOCUMENT_TYPE.label]}
        maxUploadFiles={MAX_CERTIFICATES_DOCUMENTS}
        titleModal={`${t("ged-certificate-title")} ${fundRaisingAppeal.termLabel}`}
        handleGetDate={fundRaisingAppeal.actualDate ? undefined : handleGetDate}
        filesToDisplay={currentDocuments}
        linkedFiles={currentDocuments.length !== MAX_CERTIFICATES_DOCUMENTS}
      />
    </div>
  )
}
