import { useQueryClient } from "@tanstack/react-query"
import Spinner from "components/Spinner/Spinner"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import {
  useGetStudyRequestDocuments,
  useUpdateStudyRequestDocuments,
} from "core/query-hooks/useStudyRequests"
import AddDocumentToStudyRequestDnd from "features/studies/AddDocumentToStudyRequestDnd"
import { FeasibilityFileFormField } from "features/studies/StudyRequestFormField"
import { TStudyRequestCreateDocumentsStepForm } from "features/studies/feasibilities/StepDocumentsFeasibility"
import { t } from "i18next"
import { Control } from "react-hook-form"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TDocumentCategory, TDocumentCategoryItem } from "shared/types/ged.type"

export interface IGedCategories {
  element: TDocumentCategory
  category: TDocumentCategoryItem
  control: Control<TStudyRequestCreateDocumentsStepForm, any>
  isLoading: boolean
  isProjectStudyRequestRelated?: boolean
}

export default function GedCategories(props: IGedCategories) {
  const queryClient = useQueryClient()
  const { studyRequestId } = useParams()
  const {
    element,
    category,
    control,
    isLoading,
    isProjectStudyRequestRelated,
  } = props
  const updateDocuments = useUpdateStudyRequestDocuments(+studyRequestId!)
  const { data: feasibilityDocuments } = useGetStudyRequestDocuments(
    +studyRequestId!,
  )
  const studyRequestsDocumentsIds: number[] = []

  feasibilityDocuments?.documents.map((doc) => {
    return doc.gedDocuments.map((gedDoc) => {
      return studyRequestsDocumentsIds.push(gedDoc.id)
    })
  })

  const handleGetDocumentsId = async (documentId: number) => {
    await updateDocuments.mutateAsync(
      {
        gedDocument: documentId,
        categoryId: element.id,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["listStudyRequestDocuments", +studyRequestId!],
          })
        },
        onError(error) {
          toast.error(error.response?.data.error.message)
        },
      },
    )
  }

  return (
    <div className="flex flex-col justify-between group " key={element.id}>
      <div className="flex items-center justify-between mb-4">
        <h2 className="Color__Primary font-semibold flex-1 md:pr-6">
          {element.frenchLabel} {element.required && "*"}
        </h2>
      </div>
      <div className="flex flex-col">
        {isLoading ? <Spinner /> : null}
        <div className="pb-2">
          <GedAxiosInterceptor>
            <AddDocumentToStudyRequestDnd
              handleGetDocumentsId={handleGetDocumentsId}
              documentTypes={element.documentTypes.map(
                (docType) => docType.label,
              )}
              isProjectStudyRequestRelated={isProjectStudyRequestRelated}
            />
          </GedAxiosInterceptor>
        </div>
        {category.gedDocuments.length > 0 ? (
          category.gedDocuments.map((file) => (
            <div key={file.id}>
              <FeasibilityFileFormField
                category={element.frenchLabel}
                categoryId={element.id}
                control={control}
                index={element.id}
                documentType={{
                  id: file.id,
                  label: file.title,
                }}
                defaultChecked={studyRequestsDocumentsIds.includes(file.id)}
              />
            </div>
          ))
        ) : (
          <p className="Color__Primary">
            {`${t("studyRequest-noDocumentFoundMsg")}`}
          </p>
        )}
      </div>
    </div>
  )
}
