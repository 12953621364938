import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Spinner from "components/Spinner/Spinner"
import Tooltip from "components/Tooltip/Tooltip"
import { useGetPlotsByAgreementId } from "core/query-hooks/usePlots"
import { t } from "i18next"
import { Link, useParams } from "react-router-dom"
import useIsMobile from "utils/isMobile"

interface PlotCellProps {
  agreementId: string
  routeName: string
}

export default function PlotCell({ agreementId, routeName }: PlotCellProps) {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({
    queryKey: ["getPlotsByAgreementId", agreementId],
  })
  const { id } = useParams()
  const { data: plots } = useGetPlotsByAgreementId(agreementId)
  const isMobile = useIsMobile()

  if (!plots) return <Spinner />

  if (plots.data && plots?.data.length < 1) return <p>-</p>

  return (
    <Tooltip
      title={`${plots && plots.data.length} ${t("agreements-list-plot", {
        count: plots.data.length,
      })}`}
    >
      <div className={classNames({ "p-3": !isMobile })}>
        {plots &&
          plots.data.map((plot) => (
            <Link
              to={
                routeName === "project"
                  ? `/${routeName}/${id}/plots/${plot.id}`
                  : `/${routeName}/${id}/agreements/${agreementId}/plots/${plot.id}`
              }
              className="flex Text__Link"
              key={`plot-cell-${plot.id}`}
            >
              <p className="text-nowrap whitespace-nowrap">
                {`${plot.prefix} ${plot.section} ${plot.number}`}{" "}
              </p>
            </Link>
          ))}
      </div>
    </Tooltip>
  )
}
