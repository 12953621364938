import classNames from "classnames"
import { nanoid } from "nanoid"
import { ChangeEvent, createElement, memo, useEffect, useState } from "react"
import Search from "../Icons/Search"
import "./SearchInput.scss"

interface SearchInputProps {
  searchLabel: string
  className?: string
  handleChange: (searchText: string) => void
  result?: { label: string; value: string }[]
  onResultSelected?: (r: { label: string; value: string }) => void
  defaultValue?: string
  isWhite?: boolean
}

export function SearchInput({
  searchLabel = "Rechercher",
  handleChange,
  className,
  result,
  onResultSelected,
  defaultValue = "",
  isWhite = false,
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState<string>(defaultValue)

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    handleChange(e.target.value)
  }

  useEffect(() => {
    setSearchValue(defaultValue)
  }, [defaultValue])

  return (
    <div
      className={classNames("SearchInput", {
        className,
      })}
    >
      <div className="Search__Input_Container">
        <input
          className={classNames("Search__Input", {
            "bg-white placeholder-sky-900": isWhite,
            "bg-gray-100": !isWhite,
          })}
          onChange={handleSearchChange}
          placeholder={searchLabel}
          value={searchValue}
        />
        <div className="Search__Input_Icon">
          {createElement(Search, {
            width: 16,
            height: 16,
            color: "#00304D",
          })}
        </div>
      </div>

      {result && (
        <div className="Search__Input_Result">
          <div className="Search__Input_Result_Container">
            {result.map((e) => (
              <div
                key={nanoid()}
                onClick={() => {
                  if (onResultSelected) {
                    onResultSelected(e)
                  }
                  setSearchValue(e.value)
                }}
                className="Search__Input_Result_item"
              >
                <span>{e.value}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export const MemorizedInput = memo(SearchInput)
