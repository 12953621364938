import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import CustomDatePicker from "components/DatePicker/CustomDatePicker"
import { Modal } from "components/Modal/Modal"
import Spinner from "components/Spinner/Spinner"
import { getSimplifiedProgram } from "core/api/simplifed-programs"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import useListPierrevalCompanies from "core/query-hooks/usePierrevalCompanies"
import { useEditSimplifiedProgram } from "core/query-hooks/useSimplifiedPrograms"
import { fr } from "date-fns/locale"
import SelectCityForm from "features/project/SelectCityForm"
import i18next, { t } from "i18next"
import { FormEvent, useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TEditSimplifiedProgramForm,
  TSimplifiedProgram,
} from "shared/types/simplified-programs.type"
import * as yup from "yup"

registerLocale("fr", fr)

export interface IEditSimplifiedProgramProps {
  isEditSimplifiedProgramModalShowed: boolean
  toggleEditSimplifiedProgramModal: () => void
  simplifiedProgramId: number
}

const editSimplifiedProgramValidationSchema = yup.object({
  registrationNumber: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required(() => t("registration-number-required")),
  name: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  pierrevalCompanyId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  businessUnitId: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  numberOfLots: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  reservation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  deed: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  provision: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  pierrevalCapital: yup
    .number()
    .min(0, () => i18next.t("zero-or-greater"))
    .max(100, () => i18next.t("hundred-or-lower")),
  priceHT: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  localisation: yup
    .object()
    .shape({
      label: yup
        .string()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr)),
      value: yup
        .string()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr)),
    })
    .default(undefined),
})

export function EditSimplifiedProgram(props: IEditSimplifiedProgramProps) {
  const {
    isEditSimplifiedProgramModalShowed,
    toggleEditSimplifiedProgramModal,
    simplifiedProgramId,
  } = props

  const { data: pierrevalCompanies } = useListPierrevalCompanies()
  const { data: businessUnits } = useListBusinessUnits()
  const editSimplifiedProgram = useEditSimplifiedProgram(simplifiedProgramId)
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TEditSimplifiedProgramForm>({
    resolver: yupResolver(
      editSimplifiedProgramValidationSchema as unknown as yup.ObjectSchema<TEditSimplifiedProgramForm>,
    ),
  })

  const [simplifiedProgram, setSimplifiedProgram] =
    useState<TSimplifiedProgram>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<CustomAxiosError>()
  const [reservationDate, setReservationDate] = useState<Date | null>(
    simplifiedProgram?.reservation
      ? new Date(simplifiedProgram.reservation)
      : null,
  )
  const [deedDate, setDeedDate] = useState<Date | null>(
    simplifiedProgram?.deed ? new Date(simplifiedProgram.deed) : null,
  )
  const [provisionDate, setProvisionDate] = useState<Date | null>(
    simplifiedProgram?.provision ? new Date(simplifiedProgram.provision) : null,
  )

  useEffect(() => {
    async function retrieveData() {
      const sp = await getSimplifiedProgram(simplifiedProgramId)
      reset({
        registrationNumber: sp?.registrationNumber,
        name: sp?.name,
        pierrevalCompanyId: sp?.pierrevalCompany?.id,
        businessUnitId: sp?.businessUnit?.id,
        numberOfLots: sp?.numberOfLots,
        reservation: sp?.reservation,
        deed: sp?.deed,
        provision: sp?.provision,
        priceHT: sp?.priceHT ? parseFloat(sp?.priceHT) : undefined,
        pierrevalCapital: sp?.pierrevalCapital,
        localisation: {
          label: sp?.city,
          value: sp?.postcode,
        },
      })
      setReservationDate(sp.reservation ? new Date(sp.reservation) : null)
      setDeedDate(sp.deed ? new Date(sp.deed) : null)
      setProvisionDate(sp.provision ? new Date(sp.provision) : null)
      setSimplifiedProgram(sp)
      setIsLoading(false)
    }
    retrieveData().catch((e) => {
      setError(e)
    })
  }, [])

  async function submitForm(
    simplifiedProgrammData: TEditSimplifiedProgramForm,
  ) {
    editSimplifiedProgram.mutateAsync(
      {
        registrationNumber: simplifiedProgrammData.registrationNumber,
        name: simplifiedProgrammData.name,
        pierrevalCompanyId: simplifiedProgrammData.pierrevalCompanyId,
        businessUnitId: simplifiedProgrammData.businessUnitId,
        numberOfLots: simplifiedProgrammData.numberOfLots,
        reservation: simplifiedProgrammData.reservation,
        deed: simplifiedProgrammData.deed,
        provision: simplifiedProgrammData.provision,
        priceHT: simplifiedProgrammData.priceHT,
        pierrevalCapital: simplifiedProgrammData.pierrevalCapital,
        city: simplifiedProgrammData.localisation
          ? simplifiedProgrammData.localisation.label
          : undefined,
        postcode: simplifiedProgrammData.localisation
          ? simplifiedProgrammData.localisation.value
          : undefined,
      },
      {
        async onSuccess() {
          queryClient.invalidateQueries({
            queryKey: ["getSimplifiedPrograms"],
          })
          toast.success(t("toast-edited-simplified-program"))
          toggleEditSimplifiedProgramModal()
        },
        onError() {
          toast.error(t("toast-error-edit-simplified-program"))
        },
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <Modal
      isShowing={isEditSimplifiedProgramModalShowed}
      closeModal={toggleEditSimplifiedProgramModal}
      title={`${t("edit-simplified-program")}`}
    >
      {isLoading ? <Spinner /> : null}
      {error ? (
        <p className="text-red-500 text-sm">
          {error.response?.data.error.message}
        </p>
      ) : null}
      {simplifiedProgram ? (
        <form className="Text__Field_Container" onSubmit={onSubmit}>
          <div>
            <label htmlFor="registrationNumber">
              {t("registration-number")}
              <input
                id="registrationNumber"
                className="Text__Field"
                placeholder={`${t("registration-number-placeholder")}`}
                type="text"
                {...register("registrationNumber")}
              />
            </label>
            {errors.registrationNumber && (
              <p className="text-xs text-red-500">
                {errors.registrationNumber?.message}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="name">
              {t("name")}
              <input
                id="name"
                className="Text__Field"
                placeholder={`${t("name-placeholder")}`}
                type="text"
                {...register("name")}
              />
            </label>
          </div>

          <div className="Text__Field_Container">
            <label htmlFor="pierrevalCompany">
              {t("pierreval-company-label")}
              <select
                id="pierrevalCompany"
                defaultValue=""
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                {...register("pierrevalCompanyId")}
              >
                <option key="company-disabled" disabled value="">
                  {t("choose-pierreval-company-option")}
                </option>
                {pierrevalCompanies?.map((company) => (
                  <option
                    key={`${company.id}-${company.label}`}
                    value={company.id}
                  >
                    {company.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="Text__Field_Container">
            <label htmlFor="businessUnit">
              {t("bu")}
              <select
                id="businessUnit"
                defaultValue=""
                className="Select__Field Text__Field cursor-pointer Select__Wrapper"
                {...register("businessUnitId")}
              >
                <option key="businessUnit-disabled" disabled value="">
                  {t("choose-bu-option")}
                </option>
                {businessUnits?.map((bu) => (
                  <option key={`${bu.id}-${bu.label}`} value={bu.id}>
                    {bu.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <SelectCityForm
            noOptionsMessage={() => `${t("no-results")}`}
            placeholder={`${t("search-city")}`}
            name="localisation"
            label={`${t("city")}`}
            className="w-full"
            control={control}
          />

          <div>
            <label htmlFor="numberOfLots">
              {t("number-of-lots")}
              <input
                id="numberOfLots"
                className="Text__Field"
                placeholder={`${t("number-of-lots-placeholder")}`}
                type="number"
                {...register("numberOfLots")}
              />
            </label>
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="reservation">{t("reservation")}</label>

            <Controller
              name="reservation"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="reservation"
                  className="Text__Field"
                  selected={reservationDate}
                  locale={fr}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setReservationDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="deed">{t("deed")}</label>

            <Controller
              name="deed"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="deed"
                  className="Text__Field"
                  locale={fr}
                  selected={deedDate}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setDeedDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="provision">{t("disposal")}</label>

            <Controller
              name="provision"
              control={control}
              render={({ field: { onChange } }) => (
                <CustomDatePicker
                  id="provision"
                  className="Text__Field"
                  locale={fr}
                  selected={provisionDate}
                  placeholderText={`${t("select-date")}`}
                  onChange={(date) => {
                    if (date) setProvisionDate(date)
                    onChange(date)
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col mt-2">
            <label htmlFor="pierrevalCapital">{`${t(
              "pierreval-part-of-capital-in-percent",
            )}`}</label>
            <input
              id="label"
              className="Text__Field"
              {...register("pierrevalCapital")}
              type="number"
              step="0.01"
            />
            {errors.pierrevalCapital && (
              <span className="text-red-500 text-xs">
                {errors.pierrevalCapital.message}
              </span>
            )}
          </div>

          <div>
            <label htmlFor="priceHT">
              {t("price-ht")}
              <Controller
                name="priceHT"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CurrencyInput
                    placeholder={`${t("price-ht-placeholder")}`}
                    className="Text__Field"
                    value={value}
                    allowDecimals={false}
                    onValueChange={(e) => {
                      onChange(Number(e))
                    }}
                    intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                  />
                )}
              />
            </label>
          </div>

          <div className="flex items-center justify-between mt-2">
            <Button
              onClick={() => toggleEditSimplifiedProgramModal()}
              size="small"
              mode="secondary"
            >
              {`${t("cancel")}`}
            </Button>

            <PermissionsGuard requiredRoles={["update:simplified-project"]}>
              <Button
                isLoading={editSimplifiedProgram.isPending}
                type="submit"
                size="small"
                mode="primary"
              >
                {`${t("update")}`}
              </Button>
            </PermissionsGuard>
          </div>
        </form>
      ) : null}
    </Modal>
  )
}
