import LightTable from "components/Table/LightTable"
import { format } from "date-fns"
import { getLabelFromTypeId, headers } from "features/studies/StudyRequestsList"
import { StudyStatus } from "features/studies/StudyStatus"
import { t } from "i18next"
import { useNavigate } from "react-router-dom"
import {
  TStudyRequest,
  TStudyRequestWithProject,
} from "shared/types/study.type"
import getStudyRequestUrl from "utils/getStudyRequestUrl"
import {
  STATUS_PRIORIZATION_STANDBY,
  STATUS_RESTITUTION_STANDBY,
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export interface IOnStandByListProps {
  studyRequests: TStudyRequest[] | undefined
  projectId: number
  routeName: string
}

export function OnStandbyList(props: IOnStandByListProps) {
  const { studyRequests, projectId, routeName } = props

  const onChangeStandby: TStudyRequestWithProject[] | undefined = studyRequests
    ?.filter((studyRequest) => {
      return (
        studyRequest?.statusId === STATUS_PRIORIZATION_STANDBY ||
        studyRequest?.statusId === STATUS_RESTITUTION_STANDBY
      )
    })
    .map((s) => {
      if (s.typeId === TYPE_COST_OF_WORK) {
        return { costOfWorkVersion: s.version, ...s }
      }
      if (s.typeId === TYPE_INFOGRAPHY) {
        return { infographyVersion: s.version, ...s }
      }
      return s
    })

  const navigate = useNavigate()

  const sortableFields: { key: string; value: string[] }[] = [
    { key: `${t("studyRequest-date")}`, value: ["createdAt"] },
    { key: `${t("type")}`, value: ["typeId"] },
    {
      key: `${t("feasibility-version")}`,
      value: ["feasibilityVersion"],
    },
    {
      key: `${t("studyRequest-costOfWorkVersionRaw")}`,
      value: ["costOfWorkVersion"],
    },
    {
      key: `${t("studyRequest-infographyVersionRaw")}`,
      value: ["infographyVersion"],
    },
    { key: `${t("number-of-lots")}`, value: ["numberOfLots"] },
    { key: `${t("progress")}`, value: ["statusId"] },
  ]

  return (
    <div className="rounded-lg">
      <h2 className="mb-1 font-semibold text-md">
        {t("studyRequest-standby")}
      </h2>
      {onChangeStandby?.length ? (
        <div className="Light_Table__Wrapper Table__Wrapper_NoTop shadow-lg rounded-lg">
          <LightTable
            showTotal={false}
            className="table-auto"
            items={onChangeStandby}
            headers={headers}
            sortableFields={sortableFields}
            renderRow={(item) => {
              return (
                <>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <div className="flex items-center">
                      {format(new Date(item.createdAt), "dd/MM/yyyy")}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId ? (
                      <div className="flex items-center">
                        {getLabelFromTypeId(item.typeId)}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <div className="flex items-center">
                      {item.feasibilityVersion ?? "-"}
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_COST_OF_WORK ? (
                      <div className="flex items-center">
                        {item.costOfWorkVersion}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_INFOGRAPHY ? (
                      <div className="flex items-center">
                        {item.infographyVersion}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    {item.typeId === TYPE_FEASIBILITY ? (
                      <div className="flex items-center">
                        {item.numberOfLots}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    onClick={() =>
                      navigate(
                        getStudyRequestUrl(
                          item.id,
                          projectId,
                          routeName,
                          item.typeId,
                        ),
                      )
                    }
                    className="cursor-pointer"
                  >
                    <StudyStatus requestStatusId={item.statusId} />
                  </td>
                </>
              )
            }}
          />
        </div>
      ) : (
        <p className="text-sm ml-2">{t("no-study-request")}</p>
      )}
    </div>
  )
}
