import Spinner from "components/Spinner/Spinner"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { useGetCurrentUser } from "core/query-hooks/useUsers"
import { NextThreeMonthsBuildingPermits } from "features/home/NextThreeMonthsBuildingPermits"
import { NextThreeMonthsLandPurchaseBlock } from "features/home/NextThreeMonthsLandPurchaseBlock"
import { NextThreeMonthsSpoBlock } from "features/home/NextThreeMonthsSpoBlock"
import { NextThreeMonthsTransfertBlock } from "features/home/NextThreeMonthsTransfertBlock"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { FaBox, FaTools } from "react-icons/fa"
import { MdBusinessCenter } from "react-icons/md"
import {
  ACTION_LANCEMENT_COMMERCIAL,
  ACTION_LANCEMENT_TRAVAUX,
  ACTION_MISE_A_DISPO,
} from "shared/resources/next-three-months-resources"
import { EdvHome } from "./EdvHome"

export default function EdvAndNextThreeMonths() {
  const { data: businessUnits, isPending } = useListBusinessUnits()
  const { data: user } = useGetCurrentUser()
  const [businessUnitId, setBusinessUnitId] = useState<string | undefined>(
    user?.businessUnit ? String(user.businessUnit) : undefined,
  )

  useEffect(() => {
    setBusinessUnitId(
      user?.businessUnit ? String(user.businessUnit) : undefined,
    )
  }, [user])

  return (
    <div>
      <div className="flex items-center justify-between mb-1">
        <h2 className="col-span-6 my-1 font-semibold">{t("edv-title")}</h2>
        {isPending ? <Spinner /> : ""}
        {businessUnits ? (
          <div className="Text__Field_Container">
            <select
              id="businessUnitId"
              value={businessUnitId ?? ""}
              onChange={(e) => setBusinessUnitId(e.target.value)}
              className="Select__Field Text__Field flex items-center justify-center cursor-pointer font-semibold Select__Wrapper"
            >
              <option key="business-unit-all" value="">
                {t("national")}
              </option>

              {businessUnits.map((businessUnit) => (
                <option
                  key={`${businessUnit.id}-${businessUnit.label}`}
                  value={businessUnit.id}
                >
                  {businessUnit.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
      </div>
      <EdvHome businessUnitId={businessUnitId} />

      <div className="flex items-center justify-between mt-6 mb-4">
        <h2 className="col-span-6 font-semibold">{t("next-three-months")}</h2>
      </div>
      <div className="grid lg:grid-cols-6 lg:grid-rows-1 md:grid-cols-3 md:grid-rows-2 sm:grid-cols-1 sm:grid-rows-6 gap-4">
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsTransfertBlock businessUnitId={businessUnitId} />
        </div>
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsBuildingPermits businessUnitId={businessUnitId} />
        </div>
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsSpoBlock
            action={ACTION_LANCEMENT_COMMERCIAL.actionId}
            businessUnitId={businessUnitId}
            Icon={<MdBusinessCenter size={18} />}
            title="lancements-commerciaux"
          />
        </div>
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsLandPurchaseBlock businessUnitId={businessUnitId} />
        </div>
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsSpoBlock
            action={ACTION_LANCEMENT_TRAVAUX.actionId}
            businessUnitId={businessUnitId}
            Icon={<FaTools size={18} />}
            title="lancements-travaux"
          />
        </div>
        <div className="col-span-1 row-span-1">
          <NextThreeMonthsSpoBlock
            action={ACTION_MISE_A_DISPO.actionId}
            businessUnitId={businessUnitId}
            Icon={<FaBox size={18} />}
            title="mises-a-disposition"
          />
        </div>
      </div>
    </div>
  )
}
