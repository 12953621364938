import { useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "usehooks-ts"

interface IDropdownButtonProps {
  onOptionClick: (option: string) => void
  dropdownOptions: { code: string; label: string }[]
}

function DropdownButton(props: IDropdownButtonProps) {
  const { onOptionClick, dropdownOptions } = props
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState("top-0")

  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => setIsOpen(false))
  const ref = useRef<HTMLDivElement | null>(null)

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (e: React.MouseEvent, option: string) => {
    e.stopPropagation()
    onOptionClick(option)
    setIsOpen(false)
  }

  const handleResize = () => {
    if (ref.current) {
      const viewportHeight = window.innerHeight
      const containerRect = ref.current.getBoundingClientRect()
      const dropdownHeight = ref.current.offsetHeight

      if (containerRect.bottom + dropdownHeight > viewportHeight) {
        setDropdownPosition("bottom-0")
      } else {
        setDropdownPosition("top-0")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isOpen])

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="inline-flex justify-center w-full px-2 py-1 rounded-lg text-primary-light border border-transparent font-medium hover:border-gray-600 focus:outline-none"
        onClick={toggleDropdown}
      >
        ...
      </button>

      {isOpen && (
        <div
          ref={ref}
          className={`absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50 ${dropdownPosition}`}
        >
          <ul className="py-1">
            {dropdownOptions.map((option) => (
              <li
                key={option.code}
                className="px-4 py-2 text-primary-dark hover:bg-gray-100 cursor-pointer"
                onClick={(e) => handleOptionClick(e, option.code)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default DropdownButton
