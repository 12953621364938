import { useGetOperationsFromSpo } from "core/spo/query-hooks/useOperation"
import { t } from "i18next"
import { uniq } from "lodash"
import { TChildOperation } from "shared/types/operation.type"

export interface ISncSccvProps {
  operationsChildren: TChildOperation[]
}

export function SncSccv(props: ISncSccvProps) {
  const { operationsChildren } = props

  const registrationNumbers = operationsChildren.map(
    (operation) => operation.registrationNumber,
  )

  const spoOperations = useGetOperationsFromSpo(registrationNumbers)

  const uniqueSocieteTitles = uniq(
    spoOperations
      .map((spoOp) => spoOp?.data?.societe?.title)
      .filter((title) => title !== undefined),
  )

  return (
    <div className="text-center text-gray-400 text-xs sm:text-sm">
      {uniqueSocieteTitles.length > 0 ? (
        <div>
          {t("pierreval-company-label")} : {uniqueSocieteTitles.join(" / ")}
        </div>
      ) : (
        <div className="pt-3"> </div>
      )}
    </div>
  )
}
