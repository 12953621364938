import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Button } from "components/Button/Button"
import {
  EhancedFile,
  UploadFieldDrop,
} from "components/UploadField/UploadFieldDrop"
import { usePostDocumentToGed } from "core/ged/query-hooks/useGed"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { IoWarningOutline } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"
import {
  DOC_DEVELOPEMENT,
  MAX_SIZED_BYTES,
  UNAUTHORIZED_EXTENSIONS,
} from "../ged/ged.resources"

const schema = yup
  .object({
    documentName: yup.string(),
    documentType: yup.string(),
    workflowStatus: yup.string().nullable(),
    documentStatus: yup.string().nullable(),
    numStatus: yup.string().nullable(),
  })
  .required()

export default function AddDocumentToStudyRequestDnd({
  documentTypes,
  handleGetDocumentsId,
  isProjectStudyRequestRelated = false,
  numStatus = "0",
}: {
  documentTypes: string[]
  handleGetDocumentsId?: (docId: number) => void
  isProjectStudyRequestRelated?: boolean
  numStatus?: string
}) {
  const queryClient = useQueryClient()
  const [myFiles, setMyFiles] = useState<EhancedFile[]>([])
  const [documentName, setDocumentName] = useState<string>()
  const uploadDocumentToGed = usePostDocumentToGed()
  const [isDocumentNameEditable, setIsDocumentNameEditable] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [failedUploads, setFailedUploads] = useState<EhancedFile[]>([])
  const { id } = useParams()

  type FormValues = {
    documentName: string
    documentType: string
    projectId: string
    workflowStatus?: string
    documentStatus?: string
    numStatus?: string
  }

  const { register, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
  })

  useEffect(() => {
    return reset({
      documentName: myFiles.length
        ? myFiles[0].name.replace(/\.[^/.]+$/, "")
        : undefined,
    })
  }, [myFiles])

  const handleRemoveFile = (index: number) => {
    const updatedFiles = myFiles.filter((_, i) => i !== index)
    setMyFiles(updatedFiles)

    setIsDocumentNameEditable(updatedFiles.length === 1)
  }

  const closeModal = () => {
    reset()
    setDocumentName("")
    setMyFiles([])
  }

  async function onSubmit(values: FormValues) {
    try {
      setIsSubmitting(true)
      const successfulUploads: EhancedFile[] = []
      const failedUploadsSubmit: EhancedFile[] = []

      await Promise.all(
        myFiles.map(async (file) => {
          const formValues: Omit<FormValues, "projectId"> & {
            file: EhancedFile
          } = {
            file,
            documentType:
              documentTypes.length > 1 ? values.documentType : documentTypes[0],
            documentName:
              myFiles.length === 1
                ? values.documentName
                : file.name.replace(/\.[^/.]+$/, ""),
            ...(id && { projectId: id }),
          }

          formValues.numStatus = numStatus

          if (isProjectStudyRequestRelated) {
            formValues.documentStatus = DOC_DEVELOPEMENT
          }

          const isUploadSuccess =
            await uploadDocumentToGed.mutateAsync(formValues)

          if (isUploadSuccess) {
            successfulUploads.push(file)
            if (handleGetDocumentsId) {
              handleGetDocumentsId(isUploadSuccess.id)
            }
          } else {
            failedUploadsSubmit.push(file)
          }
        }),
      )

      setFailedUploads(failedUploadsSubmit)
      if (successfulUploads.length > 0) {
        const successMessage =
          successfulUploads.length === 1
            ? t("ged-upload-successMessage")
            : t("ged-upload-successMessagePlural")
        toast.success(successMessage)
        queryClient.invalidateQueries({
          queryKey: ["getDocumentsByStudyRequestTypeAndProjectId"],
        })
        queryClient.invalidateQueries({ queryKey: ["getCostOfWorkDocuments"] })
        queryClient.invalidateQueries({
          queryKey: ["getInfographyGedDocuments"],
        })
        queryClient.invalidateQueries({ queryKey: ["getFeasibilityDocuments"] })
        reset()
        setDocumentName("")
        setMyFiles([])
        setIsDocumentNameEditable(false)
      }
    } catch (error) {
      toast.error(`${t("ged-upload-errorMessage")}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleFileChange = (files: File[]): void => {
    const authorizedFiles: File[] = []
    files.map((file) => {
      if (file.size > MAX_SIZED_BYTES) {
        return toast.error(`${t("file-too-big-error")} : ${file.name}`)
      }

      if (UNAUTHORIZED_EXTENSIONS.includes(file.name.split(".").pop() ?? "")) {
        return toast.error(`${t("unauthorized-file-extension")} : ${file.name}`)
      }
      return authorizedFiles.push(file)
    })

    setMyFiles(
      authorizedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      }),
    )

    setIsDocumentNameEditable(authorizedFiles.length === 1)
  }

  return (
    <>
      <UploadFieldDrop
        myFiles={myFiles}
        handleChange={handleFileChange}
        handleRemove={(index: number) => handleRemoveFile(index)}
        maxFiles={10}
      />

      {failedUploads.length > 0 && (
        <div className="rounded-lg bg-red-200 mt-4 p-3 text-primary-dark">
          <div className="flex items-center mb-1">
            <div className="mr-2 text-red-400">
              <IoWarningOutline size={28} />
            </div>
            <h2>{t("ged-filesNotUploaded")}</h2>
          </div>

          <ul className="list-disc list-inside pl-6 text-sm">
            {failedUploads.map((file) => (
              <li key={`failed-upload-${file.size}`}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="Text__Field_Container mt-4"
      >
        {isDocumentNameEditable ? (
          <label htmlFor="documentName">
            {`${t("ged-documentName")}`}
            <input
              id="documentName"
              {...register("documentName")}
              className={classNames("Text__Field", {
                "disabled:opacity-75": uploadDocumentToGed.isPending,
              })}
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              disabled={uploadDocumentToGed.isPending}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                }
              }}
              type="text"
              placeholder={`${t("ged-documentName")}`}
            />
          </label>
        ) : null}

        {documentTypes.length > 1 ? (
          <label htmlFor="document-type-select">
            {`${t("ged-documentType")}`}
            <select
              {...register("documentType", { required: true })}
              disabled={uploadDocumentToGed.isPending}
              name="documentType"
              id="document-type-select"
              className={classNames(
                "Select__Field Text__Field flex items-center justify-center Select__Wrapper",
                {
                  "disabled:opacity-75": uploadDocumentToGed.isPending,
                },
              )}
              defaultValue={documentTypes[0]}
            >
              {documentTypes?.map((documentType) => (
                <option
                  key={`document-type-${documentType}`}
                  value={documentType}
                >
                  {documentType}
                </option>
              ))}
            </select>
          </label>
        ) : null}

        {myFiles.length > 0 ? (
          <div className=" flex items-center justify-end pt-2">
            <Button
              size="medium"
              mode="secondary"
              isLoading={false}
              onClick={() => closeModal()}
            >
              {`${t("cancel")}`}
            </Button>
            <Button
              type="button"
              marginLeft={24}
              onClick={handleSubmit(onSubmit)}
              size="medium"
              mode="primary"
              isLoading={isSubmitting}
            >
              {`${t("add")}`}
            </Button>
          </div>
        ) : null}
      </form>
    </>
  )
}
