export const PROJECT_PLOTS_VIEWTAG = 1
export const PROJECT_STUDIES_VIEWTAG = 2
export const PROJECT_AGREEMENTS_VIEWTAG = 3
export const PROJECT_COMMITTEES_VIEWTAG = 4
export const OPERATION_STUDIES_VIEWTAG = 6
export const OPERATION_AGREEMENTS_VIEWTAG = 7
export const OPERATION_COMMITTEES_VIEWTAG = 8
export const OPERATION_DOCUMENTS_VIEWTAG = 9
export const OPERATION_LEGAL_VIEWTAG = 10
export const OPERATION_PROGRAM_VIEWTAG = 11
