import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components"
import { Modal } from "components/Modal/Modal"
import useModal from "components/Modal/useModal"
import { useCreateFolder } from "core/sharepoint/query-hooks/useFolders"
import { t } from "i18next"
import { FormEvent } from "react"
import { useForm } from "react-hook-form"
import { RiFolderAddFill } from "react-icons/ri"
import { toast } from "react-toastify"
import useIsMobile from "utils/isMobile"
import * as yup from "yup"

export interface ICreateSharepointFolderProps {
  currentFolderId: string
}

type FormValues = {
  folderName: string
}

export function CreateSharepointFolder(props: ICreateSharepointFolderProps) {
  const { currentFolderId } = props
  const { isShowing: isModalShowed, toggle: toggleModal } = useModal()
  const queryClient = useQueryClient()
  const createFolder = useCreateFolder()

  const validationSchema = yup.object().shape({
    folderName: yup
      .string()
      .trim()
      .required(() => `${t("required-folder-name")}`)
      .matches(/^[^"*:<>?/\\|.]*$/, {
        message: t(t("invalid-characters")),
      })
      .min(1, () => `${t("required-folder-name")}`),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  })

  function handleCloseModal() {
    reset()
    toggleModal()
  }

  const submitForm = async (data: FormValues) => {
    createFolder.mutate(
      {
        folderName: data.folderName.trim(),
        parentId: currentFolderId,
      },
      {
        onSuccess: () => {
          toast.success(`${t("toast-create-folder-success")}`)
          queryClient.invalidateQueries({
            queryKey: ["listFoldersFromParent", currentFolderId],
          })
          handleCloseModal()
        },
        onError: () => toast.error(t("toast-create-folder-error")),
      },
    )
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }
  const isMobile = useIsMobile()
  return (
    <>
      <Button onClick={toggleModal} type="button" mode="primary" size="medium">
        {isMobile ? <RiFolderAddFill /> : t("create-folder")}
      </Button>
      <Modal
        isShowing={isModalShowed}
        closeModal={() => handleCloseModal()}
        title={t("create-folder")}
      >
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-1 gap-4 mt-3 mb-4">
            <div className="Text__Field_Container w-full">
              <label htmlFor="folder-name">
                {`${t("folder-name")}`}
                <input
                  id="folder-name"
                  className="Text__Field"
                  {...register("folderName")}
                />
                {errors?.folderName && (
                  <p className="text-red-600 text-sm">
                    {errors.folderName.message}
                  </p>
                )}
              </label>
            </div>
          </div>
          <div className="Modal__Footer">
            <Button
              onClick={() => handleCloseModal()}
              type="button"
              mode="secondary"
              size="medium"
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              mode="primary"
              size="medium"
              marginLeft={24}
              isDisabled={createFolder.isPending}
            >
              {t("create")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
