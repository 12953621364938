import { useListStudyRequests } from "core/query-hooks/useStudyRequests"
import { CurrentList } from "features/studies/CurrentList"
import { DraftList } from "features/studies/DraftList"
import { OnStandbyList } from "features/studies/OnStandbyList"
import { ReturnedList } from "features/studies/ReturnedList"
import { t } from "i18next"
import { ReactNode } from "react"
import {
  TYPE_COST_OF_WORK,
  TYPE_FEASIBILITY,
  TYPE_INFOGRAPHY,
} from "./studyRequest.resources"

export const headers = [
  () => t("studyRequest-date"),
  () => t("type"),
  () => t("feasibility-version"),
  () => t("studyRequest-costOfWorkVersionRaw"),
  () => t("studyRequest-infographyVersionRaw"),
  () => t("number-of-lots"),
  () => t("progress"),
]

export function getStudyRequestLabel(typeId: number): string {
  switch (typeId) {
    case TYPE_FEASIBILITY:
      return `${t("dashboard-feasibility")}`
    case TYPE_COST_OF_WORK:
      return `${t("cost-of-work-label")}`
    case TYPE_INFOGRAPHY:
      return `${t("dashboard-info")}`
    default:
      return ""
  }
}

export function getLabelFromTypeId(typeId: number): ReactNode {
  switch (typeId) {
    case TYPE_FEASIBILITY:
    case TYPE_COST_OF_WORK:
    case TYPE_INFOGRAPHY:
      return <p>{getStudyRequestLabel(typeId)}</p>
    default:
      return <p>{t("unknown")}</p>
  }
}

export interface IStudyRequestsListProps {
  projectId: number
  routeName: string
}

export function StudyRequestsList(props: IStudyRequestsListProps) {
  const { projectId, routeName } = props
  const { data: studyRequests } = useListStudyRequests(projectId)

  return (
    <>
      <div className="my-2 mb-10">
        <DraftList
          studyRequests={studyRequests}
          projectId={projectId}
          routeName={routeName}
        />
      </div>
      <div className="my-4 mb-10">
        <CurrentList
          studyRequests={studyRequests}
          projectId={projectId}
          routeName={routeName}
        />
      </div>
      <div className="my-4 mb-10">
        <OnStandbyList
          studyRequests={studyRequests}
          projectId={projectId}
          routeName={routeName}
        />
      </div>
      <div className="my-2">
        <ReturnedList
          studyRequests={studyRequests}
          projectId={projectId}
          routeName={routeName}
        />
      </div>
    </>
  )
}
