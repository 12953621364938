import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import { useUpdateProject } from "core/query-hooks/useProjects"
import PinProject from "features/project/PinProject"
import { FormEvent, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { RiPencilFill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"

type IFormProjetcSchema = {
  name: string
  address: string
}

export interface IEditProjectNameAndAddressHeader {
  name: string
  address: string
  city: string
}

function EditProjectNameAndAddressHeader(
  props: IEditProjectNameAndAddressHeader,
) {
  const { t } = useTranslation()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const updateProjectNameAndAddress = useUpdateProject(+id!)
  const { name, address, city } = props
  const projectName = `${name} / ${address} - ${city}`

  const updateProjectSchema = yup.object().shape({
    name: yup.string().required(`${t("required-name")}`),
    address: yup.string().required(`${t("required-address")}`),
  })

  const { register, handleSubmit } = useForm<IFormProjetcSchema>({
    mode: "onSubmit",
    resolver: yupResolver(updateProjectSchema),
    defaultValues: {
      name,
      address,
    },
  })

  const submitForm = (data: IFormProjetcSchema) => {
    updateProjectNameAndAddress.mutate(data, {
      onSuccess: () => {
        toast.success(t("project-successMessageEdit"))
        setIsEditMode(false)
        queryClient.invalidateQueries({
          queryKey: ["project", id],
        })
        queryClient.invalidateQueries({
          queryKey: ["getInfosByAddress"],
        })
      },
      onError: (err) => {
        toast.error(
          `t("an-error-has-occurred"), ${err.response?.data.error.message}`,
        )
      },
    })
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleSubmit(submitForm)()
  }

  return (
    <div className="pb-6 flex justify-center">
      {isEditMode ? (
        <form
          onSubmit={onSubmit}
          className="flex flex-col md:flex-row items-center gap-x-4 Text__Field_Container"
        >
          <div className="flex flex-col md:flex-row gap-x-2">
            <input
              id="name"
              type="text"
              placeholder={`${t("project-form-namePlaceholder")}`}
              className="Text__Field"
              {...register("name")}
            />
            <input
              id="address"
              type="text"
              placeholder={`${t("project-form-addressPlaceholder")}`}
              className="Text__Field"
              {...register("address")}
            />
          </div>
          <div className="md:mr-5 flex flex-row md:w-fit mb-4">
            <Button type="submit" classNames="mr-3" size="small" mode="primary">
              {`${t("save")}`}
            </Button>
            <Button
              onClick={() => setIsEditMode(false)}
              size="small"
              mode="secondary"
            >
              {`${t("cancel")}`}
            </Button>
          </div>
        </form>
      ) : (
        <div className="flex md:flex-row flex-col-reverse justify-center md:items-center items-center w-full mr-5">
          <div className="flex items-center">
            <h2 className="text-primary-font font-semibold text-md sm:text-2xl text-center relative">
              <div className="flex flex-row">
                {projectName}
                <div>
                  <button
                    type="button"
                    onClick={() => setIsEditMode(true)}
                    className="ml-4 text-cyan-800 rounded-lg flex items-center justify-center p-1 border border-slate-100 hover:bg-slate-100"
                  >
                    <RiPencilFill size={20} />
                  </button>
                </div>
                <div>
                  <PinProject />
                </div>
              </div>
              <span className="absolute bottom-0 left-1/4 w-1/2 h-1 border-b-4 border-pierreval-green mt-2 transform translate-y-3" />
            </h2>
          </div>
        </div>
      )}
    </div>
  )
}

export default EditProjectNameAndAddressHeader
