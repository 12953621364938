import { useMutation } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TMoveFile,
  TRenameFile,
  TSharepointFile,
} from "shared/types/sharepoint"
import { deleteFile, moveFile, renameFile, uploadFile } from "../api/file"

function useDeleteFile() {
  return useMutation<void, CustomAxiosError, string>({
    mutationFn: (documentId: string) => deleteFile(documentId),
  })
}

function useUploadFile() {
  return useMutation<void, CustomAxiosError, TSharepointFile>({
    mutationFn: (data: TSharepointFile) => uploadFile(data),
  })
}

function useMoveFile() {
  return useMutation<void, CustomAxiosError, TMoveFile>({
    mutationFn: (data: TMoveFile) => moveFile(data),
  })
}

function useRenameFile() {
  return useMutation<void, CustomAxiosError, TRenameFile>({
    mutationFn: (data: TRenameFile) => renameFile(data),
  })
}

export { useDeleteFile, useMoveFile, useRenameFile, useUploadFile }
