import Spinner from "components/Spinner/Spinner"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import DropdownFilters from "features/programs/DropdownFilter"
import { t } from "i18next"
import { TBusinessUnit } from "shared/types/business-unit.type"

export interface IBusinessUnitsFilterProps {
  selectedBusinessUnits: number[] | []
  onBusinessUnitsChange: (selectedBu: number[] | []) => void
}

export default function BusinessUnitsFilter(props: IBusinessUnitsFilterProps) {
  const { selectedBusinessUnits, onBusinessUnitsChange } = props

  const {
    data: businessUnitsData,
    isLoading: isLoadingBu,
    error: errorBu,
  } = useListBusinessUnits()

  if (isLoadingBu) return <Spinner />

  if (errorBu || !businessUnitsData)
    return <p>{errorBu?.response?.data.message}</p>

  const businessUnitsOptionWithAll = [
    {
      id: -1,
      label: t("select-all"),
    },
    ...businessUnitsData,
  ]

  const handleBuClick = (
    region: Omit<TBusinessUnit, "active" | "countryId">,
  ) => {
    if (region.id === -1) {
      const shouldSelectAll =
        selectedBusinessUnits.length !== businessUnitsData.length
          ? businessUnitsData.map((bu) => bu.id)
          : []
      onBusinessUnitsChange(shouldSelectAll)
    } else {
      const isBuSelected = ((selectedBusinessUnits as number[]) ?? []).includes(
        region.id,
      )
      if (isBuSelected) {
        const updatedBuList = selectedBusinessUnits?.filter(
          (regionId) => regionId !== region.id,
        )
        onBusinessUnitsChange(updatedBuList || [])
      } else {
        const updatedBuList = selectedBusinessUnits
          ? [...selectedBusinessUnits, region.id]
          : [region.id]
        onBusinessUnitsChange(updatedBuList)
      }
    }
  }
  const isSelectAllChecked =
    selectedBusinessUnits.length === businessUnitsData.length

  return (
    <div>
      <div className="relative w-full lg:max-w-sm h-full">
        <DropdownFilters
          renderLabel={() => (
            <div className="text-sky-900">
              {t("bu")}
              {selectedBusinessUnits.length > 0 && (
                <div className="ml-2 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-pink-800 bg-pink-100 rounded-full">
                  {selectedBusinessUnits.length}
                </div>
              )}
            </div>
          )}
          options={businessUnitsOptionWithAll}
          renderOption={(region, index) => (
            <div role="menu">
              <li
                onClick={() => handleBuClick(region)}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id={`menu-item-${index}`}
                key={`menu-item-${index}`}
              >
                <input
                  aria-label="Select business unit"
                  className="mr-2"
                  type="checkbox"
                  readOnly
                  checked={
                    region.id === -1
                      ? isSelectAllChecked
                      : !!selectedBusinessUnits?.find(
                          (regionId) => regionId === region.id,
                        )
                  }
                />
                {region.label}
              </li>
            </div>
          )}
          keyExtractor={(region) => region.id}
        />
      </div>
    </div>
  )
}
