import { useMutation, useQueries, useQuery } from "@tanstack/react-query"
import { CustomAxiosError } from "shared/types/axios.type"
import {
  TDocumentsList,
  TGEDTree,
  TGedDocument,
  TGedFile,
  TGedUploadResponse,
} from "shared/types/ged.type"
import {
  getDocumentsByCategoryAndProjectId,
  getDocumentsById,
  getDocumentsByStudyRequestIdAndProjectId,
  getDocumentsByType,
  getGedDocumentsByProjectId,
  getTreeByProjectId,
  getTreeByRegistrationNumbers,
  postDocumentToGed,
} from "../api/documents"
import countTasks from "../api/tasks"

function useGetTreeByProjectId(
  projectId: number,
  viewPage?: number,
  query?: string,
) {
  return useQuery<TGEDTree, CustomAxiosError>({
    queryKey: ["getTreeByProjectId", projectId, viewPage, query],
    queryFn: () => getTreeByProjectId(projectId, viewPage, query),
    ...{
      enabled: Boolean(projectId),
      gcTime: 0,
      staleTime: 60000,
    },
  })
}

function useGetTreeByRegistrationNumbers(
  registrationNumbers: string[],
  viewPage?: number,
  query?: string,
) {
  return useQuery<TGEDTree, CustomAxiosError>({
    queryKey: [
      "getTreeByRegistrationNumbers",
      registrationNumbers,
      viewPage,
      query,
    ],
    queryFn: () =>
      getTreeByRegistrationNumbers(registrationNumbers, viewPage, query),
    ...{
      enabled: Boolean(registrationNumbers),
      gcTime: 0,
      staleTime: 60000,
    },
  })
}

function usePostDocumentToGed() {
  return useMutation<TGedUploadResponse, CustomAxiosError, TGedFile>({
    mutationFn: (data: TGedFile) => postDocumentToGed(data),
  })
}

function useGetGedDocumentsByProjectId(projectId: number) {
  return useQuery<unknown, CustomAxiosError, TGEDTree>({
    queryKey: ["getGedDocumentsByProjectId", projectId],
    queryFn: () => getGedDocumentsByProjectId(projectId),
  })
}

function useGetDocumentsByStudyRequestTypeAndProjectId(
  projectId: number,
  studyRequestType: number,
) {
  return useQuery<unknown, CustomAxiosError, TDocumentsList>({
    queryKey: [
      "getDocumentsByStudyRequestTypeAndProjectId",
      projectId,
      studyRequestType,
    ],
    queryFn: () =>
      getDocumentsByStudyRequestIdAndProjectId(projectId, studyRequestType),
  })
}

function useGetDocumentsByCategoryAndProjectId(
  projectId: number,
  categoryId: number,
) {
  return useQuery<unknown, CustomAxiosError, TDocumentsList>({
    queryKey: ["getDocumentsByCategoryAndProjectId", projectId, categoryId],
    queryFn: () => getDocumentsByCategoryAndProjectId(projectId, categoryId),
    ...{
      refetchOnWindowFocus: false,
    },
  })
}

function useCountTasks() {
  return useQuery<number, CustomAxiosError>({
    queryKey: ["countTasks"],
    queryFn: () => countTasks(),
  })
}

function useGetDocumentsById(documentId: number) {
  return useQuery<unknown, CustomAxiosError, TGedDocument>({
    queryKey: ["getDocumentsById", documentId],
    queryFn: () => getDocumentsById(documentId),
  })
}

function useGetMultiplesDocuments(documents: number[]) {
  return useQueries({
    queries: documents.map((documentId) => {
      return {
        queryKey: ["getGedDocument", documentId],
        queryFn: () => getDocumentsById(documentId),
        enabled: !!documentId,
      }
    }),
    combine: (results) => {
      return {
        data: results
          .filter((item) => typeof item.data === "object")
          .map((result) => result.data),
        isLoading: results
          .map((result) => result.isLoading)
          .reduce((res, cur) => res && cur, false),
        isError: results
          .map((result) => result.isError)
          .reduce((res, cur) => res && cur, false),
      }
    },
  })
}

function useGetDocumentsByType(projectId: string, typeLabel: string) {
  return useQuery<unknown, CustomAxiosError, TGedDocument[]>({
    queryKey: ["getDocumentsByType", projectId, typeLabel],
    queryFn: () => getDocumentsByType(projectId, typeLabel),
  })
}

export {
  useCountTasks,
  useGetDocumentsByCategoryAndProjectId,
  useGetDocumentsById,
  useGetDocumentsByStudyRequestTypeAndProjectId,
  useGetDocumentsByType,
  useGetGedDocumentsByProjectId,
  useGetMultiplesDocuments,
  useGetTreeByProjectId,
  useGetTreeByRegistrationNumbers,
  usePostDocumentToGed,
}
