import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import GedTree from "features/ged/GedTree"
import { t } from "i18next"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useNavigate, useParams } from "react-router-dom"
import { PROJECT_AGREEMENTS_VIEWTAG } from "shared/resources/view-tags.resources"
import AgreeementList from "../agreements/AgreementList"

function ProjectAgreements() {
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <DivStickyMenu heightClass="pt-36" />
      <div className="px-4 pb-32">
        <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 grid-flow-row-dense pt-4">
          <div className="col-span-3">
            <div className="flex justify-between items-center mb-2">
              <div>
                <Header>{`${t("agreements")}`}</Header>
              </div>
              <QueryBoundaries loadingComponent={<Spinner />}>
                <PermissionsGuard requiredRoles={["create:agreement"]}>
                  <Button
                    onClick={() => navigate(`/project/${id}/agreements/add`)}
                    size="medium"
                    mode="primary"
                    isLoading={false}
                  >
                    {t("add")}
                  </Button>
                </PermissionsGuard>
              </QueryBoundaries>
            </div>
            <AgreeementList routeName="project" />
          </div>
          <div className="pt-4 lg:pt-0 col-span-1 mb-4">
            <div className="mb-2">
              <Header>{`${t("documents")}`}</Header>
            </div>
            <Box>
              <GedTree view={PROJECT_AGREEMENTS_VIEWTAG} projectId={+id!} />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectAgreements
