import Box from "components/Box/Box"
import { Header } from "components/Header/Header"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import GedTree from "features/ged/GedTree"
import { OperationCompany } from "features/operation-companies/OperationCompany"
import { t } from "i18next"
import { useParams } from "react-router-dom"
import { OPERATION_LEGAL_VIEWTAG } from "shared/resources/view-tags.resources"
import DivStickyMenu from "./DivStickyMenu"

export default function OperationLegal() {
  const { id } = useParams()

  const { data: operationsChildren } = useListChildOperations(Number(id!))
  const registrationNumbers = operationsChildren?.map(
    (oc) => oc.registrationNumber,
  )

  return (
    <>
      <DivStickyMenu />
      <div className="lg:grid lg:grid-cols-4 lg:grid-flow-col lg:gap-4 pt-4 pb-10 px-4">
        <div className="col-span-3">
          <div className="flex items-center pb-2 px-2">
            <Header>{`${t("legal-informations")}`}</Header>
          </div>
          <GedAxiosInterceptor>
            <OperationCompany operationId={+id!} />
          </GedAxiosInterceptor>
        </div>

        <div className="col-span-1">
          {registrationNumbers ? (
            <>
              <div className="mb-2">
                <Header>{`${t("documents")}`}</Header>
              </div>
              <Box>
                <GedTree
                  view={OPERATION_LEGAL_VIEWTAG}
                  registrationNumbers={registrationNumbers}
                />
              </Box>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
