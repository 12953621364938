import { useQueryClient } from "@tanstack/react-query"
import GedFile from "components/Ged/GedFile"
import Spinner from "components/Spinner/Spinner"
import { useGetMultiplesDocuments } from "core/ged/query-hooks/useGed"
import { useUpdateAppelDefondDocuments } from "core/spo/query-hooks/useAppelDeFond"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import {
  MAX_SUPPORTING_DOCUMENTS,
  SUPPORTING_DOCUMENTS_DOCUMENT_TYPE,
} from "shared/resources/fundRaisingAppeal.resources"
import { TGedUploadResponse } from "shared/types/ged.type"
import { TFundRaisingAppeal } from "shared/types/spo/appelDeFond.type"
import { uniq } from "lodash"
import { AddProductionDocuments } from "../AddProductionDocuments"

export interface ISupportingDocuments {
  fundRaisingAppeal: TFundRaisingAppeal
  trancheTravauxId: string
  lessor?: string | null
}

export function SupportingDocuments(props: ISupportingDocuments) {
  const { fundRaisingAppeal, trancheTravauxId, lessor } = props
  const queryClient = useQueryClient()

  const {
    data: currentDocuments,
    isLoading,
    isError,
  } = useGetMultiplesDocuments(fundRaisingAppeal?.supportingDocuments ?? [])

  const mutation = useUpdateAppelDefondDocuments(fundRaisingAppeal.termCode)

  const [formFiles, setFormFiles] = useState<TGedUploadResponse[]>([])
  const [isSupportingDocument, setIsSupportingDocument] = useState(
    fundRaisingAppeal.isSupportingDocument,
  )

  const updateDocuments = async (docs?: number[]): Promise<void> => {
    mutation.mutateAsync(
      {
        supportingDocuments: docs,
        isSupportingDocument,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["getAppelDeFondList", trancheTravauxId, lessor],
          })
        },
      },
    )
  }

  const handleDeleteDocument = async (documentId: number) => {
    const updatedDocs =
      uniq(
        fundRaisingAppeal.supportingDocuments?.filter(
          (docId) => docId !== documentId,
        ),
      ) ?? []

    setFormFiles(formFiles.filter((doc) => doc.id !== documentId))

    await updateDocuments(updatedDocs)
  }

  useEffect(() => {
    const updateIsSupportiveDocument = async () => {
      await updateDocuments()
    }

    updateIsSupportiveDocument()
  }, [isSupportingDocument])

  useEffect(() => {
    if (formFiles.length > 0) {
      const docs = uniq([
        ...currentDocuments.map((doc) => doc.id),
        ...formFiles.map((doc) => doc.id),
      ])
      const updateDocs = async () => {
        await updateDocuments(docs)
      }

      updateDocs()
    }
  }, [formFiles])

  return (
    <div className="flex items-center">
      <div className="pr-3">
        <input
          type="checkbox"
          className="secondary-checkbox-custom"
          name="isSupportingDocument"
          checked={isSupportingDocument}
          onChange={() => {
            setIsSupportingDocument(!isSupportingDocument)
          }}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isError ? (
            <p className="text-red-600">{`${t("ged-unavailable")}`}</p>
          ) : (
            <div className="flex flex-col">
              {currentDocuments.map(
                (existingFile) =>
                  existingFile && (
                    <div className="flex items-center" key={existingFile.id}>
                      <GedFile
                        id={existingFile.id}
                        title={existingFile?.title}
                      />
                      <MdDelete
                        title={`${t("delete")}`}
                        fontSize={18}
                        className="transition cursor-pointer opacity-70 hover:opacity-100 duration-150 ease-out hover:ease-in"
                        onClick={() => handleDeleteDocument(existingFile.id)}
                      />
                    </div>
                  ),
              )}
            </div>
          )}
        </>
      )}

      {currentDocuments &&
        currentDocuments.length < MAX_SUPPORTING_DOCUMENTS && (
          <div className="flex items-center">
            <AddProductionDocuments
              setFiles={setFormFiles}
              files={formFiles}
              filesToDisplay={currentDocuments}
              documentTypes={[SUPPORTING_DOCUMENTS_DOCUMENT_TYPE.label]}
              maxUploadFiles={
                MAX_SUPPORTING_DOCUMENTS - currentDocuments.length
              }
              titleModal={t("production-supporting-documents")}
              linkedFiles={currentDocuments.length !== MAX_SUPPORTING_DOCUMENTS}
            />
          </div>
        )}
    </div>
  )
}
