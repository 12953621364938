import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { Button } from "components/Button/Button"
import { useRenameFile } from "core/sharepoint/query-hooks/useFiles"
import { t } from "i18next"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { SharepointItem } from "shared/types/sharepoint"
import * as yup from "yup"

export interface TRenamedFileFormProps {
  toggle: () => void
  item: SharepointItem
}

const schema = yup
  .object({
    fileName: yup
      .string()
      .required(() => `${t("file-name-required")}`)
      .matches(/^[a-zA-Z0-9À-ÖØ-öø-ÿ_-]+$/, `${t("invalid-file-name")}`),
  })
  .required()

export default function RenameSharepointFile({
  toggle,
  item,
}: TRenamedFileFormProps) {
  type FormValues = {
    fileName: string
  }

  const { id: itemId, name, parentId } = item

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema as yup.ObjectSchema<FormValues>),
    defaultValues: {
      fileName: name.substring(0, name.lastIndexOf(".")),
    },
  })

  const mutation = useRenameFile()
  const queryClient = useQueryClient()

  const fileExtension = name.split(".").pop()

  const submitForm = async (data: FormValues) => {
    await mutation.mutateAsync(
      {
        fileName: `${data.fileName}.${fileExtension}`,
        fileId: itemId,
      },
      {
        onSuccess: () => {
          toggle()
          queryClient.invalidateQueries({
            queryKey: ["listFoldersFromParent", parentId],
          })
          toast.success(`${t("rename-file-success")}`)
        },
        onError: () => {
          toast.error(`${t("rename-file-error")}`)
        },
      },
    )
  }

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className="Text__Field_Container mt-4"
    >
      <label htmlFor="fileName">
        {`${t("file-name")}`}
        <input
          id="fileName"
          className="Text__Field"
          type="text"
          {...register("fileName")}
          placeholder={`${t("file-name-description")}`}
        />
        {errors.fileName && (
          <p className="text-red-500">
            {errors.fileName.message || `${t("file-name-required")}`}
          </p>
        )}
      </label>

      <div className="Modal__Footer mt-4">
        <div className="flex items-center justify-end">
          <Button
            size="medium"
            mode="secondary"
            isLoading={false}
            onClick={toggle}
          >
            {`${t("cancel")}`}
          </Button>
          <Button
            type="submit"
            marginLeft={24}
            size="medium"
            mode="primary"
            isLoading={mutation.isPending}
          >
            {`${t("rename")}`}
          </Button>
        </div>
      </div>
    </form>
  )
}
