import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import useListBusinessUnits from "core/query-hooks/useBusinessUnits"
import { useUpdateProject } from "core/query-hooks/useProjects"
import { t } from "i18next"
import { useState } from "react"
import { TbMap2 } from "react-icons/tb"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { addLeadingZero } from "utils/departments"

export interface IProjectBuSectorProps {
  departmentId: number
  businessUnitId: number
}

export function ProjectBuSector(props: IProjectBuSectorProps) {
  const { departmentId, businessUnitId: businessId } = props
  const params = useParams()
  const projectId = parseInt(params.id!)
  const { data: businessUnits, isPending } = useListBusinessUnits()
  const [businessUnitId, setBusinessUnitId] = useState<number>(businessId)

  const {
    isPending: isLoadingUpdate,
    error: updateErrors,
    mutate: updateProject,
  } = useUpdateProject(projectId)

  const handleBusinessUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newBusinessUnitId: number = +e.target.value
    setBusinessUnitId(newBusinessUnitId)

    if (newBusinessUnitId) {
      updateProject(
        { businessUnitId: newBusinessUnitId },
        {
          onSuccess: () => {
            toast.success(t("bu-updated"))
          },
          onError: (error) => {
            toast.error(
              `${t("bu-update-error")}: ${error.response?.data.error.message}`,
            )
          },
        },
      )
    }
  }

  return (
    <Box classNames="p-0">
      <div className="flex items-center border-b border-gray-200 px-3.5 py-2">
        <TbMap2 size={20} className="text-cyan-900 mr-1" />
        <h3 className="text-xs uppercase text-cyan-900 font-medium">
          {`${t("sector")}`}
        </h3>
      </div>
      <div className="p-4">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div className="flex items-center">
            <p className="text-md text-cyan-950">{t("bu")}</p>
          </div>

          <div className="flex items-center md:justify-end justify-start">
            {isPending ? <Spinner /> : ""}
            {businessUnits ? (
              <div className="Text__Field_Container">
                <select
                  id="businessUnitId"
                  value={businessUnitId}
                  onChange={handleBusinessUnitChange}
                  className="Select__Field Text__Field flex items-center justify-center cursor-pointer font-semibold Select__Wrapper"
                >
                  {businessUnits.map((businessUnit) => (
                    <option key={businessUnit.id} value={businessUnit.id}>
                      {businessUnit.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="flex items-center">
            <p className="text-md text-cyan-950">{t("department")}</p>
          </div>
          <span className="text-right">
            {isLoadingUpdate ? (
              <Spinner />
            ) : (
              <div className="Text__Field_Container">
                {addLeadingZero(departmentId)}
              </div>
            )}
          </span>
        </div>

        {updateErrors && (
          <p className="text-red-600">{t("update-error-message")}</p>
        )}
      </div>
    </Box>
  )
}
