import classNames from "classnames"
import { differenceInCalendarDays, format } from "date-fns"
import { fr } from "date-fns/locale/fr"
import "./DateFormat.scss"

interface IDateFormatProps {
  date: Date
  isDefinitive?: boolean
  isDarkMode?: boolean
  withHours?: boolean
}

export default function DateFormat({
  isDefinitive = false,
  ...props
}: IDateFormatProps) {
  const { date, isDarkMode, withHours } = props

  if (!date) return null

  const differenceInCalendarDaysValue = differenceInCalendarDays(
    new Date(Date.now()),
    new Date(date),
  )

  const formatedDate = withHours
    ? format(new Date(date), "EEE dd/MM/yyyy 'à' HH'h'mm", { locale: fr })
    : format(new Date(date), "dd/MM/yyyy")

  return (
    <span
      className={classNames("text-sm date-format", {
        "date-darkmode": isDarkMode,
        "date-normal": !isDefinitive && differenceInCalendarDaysValue < -30,
        "date-alert": !isDefinitive && differenceInCalendarDaysValue <= 0,
        "date-danger": !isDefinitive && differenceInCalendarDaysValue > 0,
      })}
    >
      {`${formatedDate}`}
    </span>
  )
}
