import { useQueryClient } from "@tanstack/react-query"
import { useGetBanks } from "core/query-hooks/useBanks"
import { useUpdateOperation } from "core/query-hooks/useOperations"
import { t } from "i18next"
import { ChangeEvent } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TBank } from "shared/types/bank.type"

interface IListBank {
  operationBank: TBank | undefined
}
export default function ListBank(params: IListBank) {
  const { operationBank } = params
  const { id } = useParams()
  const { data: banks } = useGetBanks()
  const mutateBank = useUpdateOperation(+id!)

  const queryClient = useQueryClient()

  function handleChangeBank(e: ChangeEvent<HTMLSelectElement>) {
    mutateBank.mutate(
      {
        bankId: Number(e.target.value),
      },
      {
        onSuccess() {
          toast.success(t("success-update-bank"))
          queryClient.invalidateQueries({ queryKey: ["operation", +id!] })
        },
        onError() {
          toast.error(t("error-update-bank"))
        },
      },
    )
  }

  return (
    <>
      {banks && banks.length > 0 && (
        <div className="w-3/4">
          <select
            className="Select__Field Text__Field cursor-pointer Select__Wrapper"
            onChange={(e) => handleChangeBank(e)}
            value={operationBank?.id || ""}
            name="select-bank"
          >
            <option key="no-option-bank" value="" disabled>
              {t("select-bank")}
            </option>
            {banks.map((bank) => (
              <option key={`${bank.id}-${bank.label}`} value={bank.id}>
                {bank.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}
