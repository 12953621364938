import {
  DndProvider,
  getBackendOptions,
  MultiBackend,
} from "@minoru/react-dnd-treeview"
import Box from "components/Box/Box"
import Spinner from "components/Spinner/Spinner"
import TabContent from "components/Tabs/TabContent"
import { Tabs } from "components/Tabs/Tabs"
import TooltipWarning from "components/Tooltip/TooltipWarning"
import GedAxiosInterceptor from "core/interceptors/GedInterceptor"
import { useListChildOperations } from "core/query-hooks/useOperations"
import GedExplorer from "features/ged/GedExplorer"
import { t } from "i18next"
import { FaLinkSlash } from "react-icons/fa6"
import { RiErrorWarningFill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import { OPERATION_DOCUMENTS_VIEWTAG } from "shared/resources/view-tags.resources"
import countSpoLotsByOperation from "utils/countsSpoLotsByOperation"
import DivStickyMenu from "./DivStickyMenu"
import OperationShareSpace from "./OperationShareSpace"

export default function OperationDocuments() {
  const { id } = useParams()
  const {
    data: operationsChildren,
    isLoading,
    error,
  } = useListChildOperations(Number(id!))

  if (isLoading) return <Spinner />

  if (error || !operationsChildren)
    return (
      <p>
        {`${t("error")}`} : {error?.message}
      </p>
    )

  const spoLots = countSpoLotsByOperation(operationsChildren)

  const tabs = []
  tabs.push({
    label: `${t("all-documents")}`,
    registrationNumbers: operationsChildren.map((oc) => oc.registrationNumber),
    isMother: true,
    sharepointFolderId: operationsChildren.map((oc) => oc.sharepointId)[0],
  })

  if (operationsChildren.length > 1) {
    operationsChildren.forEach((oc) => {
      const numberOfLots = spoLots.find(
        (item) => item?.registrationNumber === oc.registrationNumber,
      )?.numberOfLots
      tabs.push({
        label: `${oc.registrationNumber} ${
          numberOfLots ? `- ${numberOfLots} ${t("lots-caps")}` : " "
        } `,
        registrationNumbers: [oc.registrationNumber],
        sharepointFolderId: oc.sharepointId,
      })
    })
  }

  return (
    <>
      <DivStickyMenu />
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div className="flex flex-col md:flex-row justify-between p-4">
          <Box classNames="w-full order-2">
            <Tabs isOperationChildren>
              {tabs!.map(
                (tab: {
                  label: string
                  registrationNumbers: string[]
                  isMother: any
                  sharepointFolderId: string | null
                }) => (
                  <TabContent
                    title={tab.label}
                    key={`tab-${tab.registrationNumbers}`}
                  >
                    {tab.isMother && operationsChildren.length > 1 ? (
                      <GedAxiosInterceptor>
                        <div className="py-4">
                          <GedExplorer
                            registrationNumbers={tab.registrationNumbers}
                            view={OPERATION_DOCUMENTS_VIEWTAG}
                            isMother={
                              (tab.isMother && operationsChildren.length > 1) ??
                              false
                            }
                            numStatus="2"
                          />
                        </div>
                      </GedAxiosInterceptor>
                    ) : (
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-5 mt-2">
                        <Box classNames="md:col-span-3">
                          <div className="flex items-center mb-2">
                            <h2>{t("sharepoint-title")}</h2>
                            <TooltipWarning
                              message={`${t("sharepoint-documents-warning")}`}
                              position="right"
                            >
                              <RiErrorWarningFill
                                className="ml-2"
                                color="red"
                                size={20}
                              />
                            </TooltipWarning>
                          </div>
                          {tab.sharepointFolderId === null ? (
                            <div className="flex items-center mt-2 text-primary-dark">
                              <FaLinkSlash className="mr-2" size={20} />
                              {t("error-sharepoint-missing-id")}
                            </div>
                          ) : (
                            <OperationShareSpace
                              sharepointFolderId={tab.sharepointFolderId}
                            />
                          )}
                        </Box>
                        <Box classNames="md:col-span-2">
                          <GedAxiosInterceptor>
                            <GedExplorer
                              registrationNumbers={tab.registrationNumbers}
                              view={OPERATION_DOCUMENTS_VIEWTAG}
                              isMother={
                                (tab.isMother &&
                                  operationsChildren.length > 1) ??
                                false
                              }
                              numStatus="2"
                            />
                          </GedAxiosInterceptor>
                        </Box>
                      </div>
                    )}
                  </TabContent>
                ),
              )}
            </Tabs>
          </Box>
        </div>
      </DndProvider>
    </>
  )
}
