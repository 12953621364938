import { useQueryClient } from "@tanstack/react-query"
import Box from "components/Box/Box"
import { Button } from "components/Button/Button"
import { DeleteModal } from "components/DeleteModal/DeleteModal"
import useDeleteModal from "components/DeleteModal/useDeleteModal"
import { Header } from "components/Header/Header"
import Spinner from "components/Spinner/Spinner"
import QueryBoundaries from "core/QueryBoundaries"
import { PermissionsGuard } from "core/permissions/PermissionsGuard"
import { useDeleteAgreement } from "core/query-hooks/useAgreements"
import { format } from "date-fns"
import DivStickyMenu from "pages/operations/DivStickyMenu"
import { useTranslation } from "react-i18next"
import { Link, useMatches, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { TAgreement } from "shared/types/agreement.type"
import FormatAgreementChildren from "../FormatAgreementChildren"
import AmendmentsBox from "../amendments/AmendmentsBox"
import PlotBox from "../plots/PlotsBox"
import SuspensiveConditionsBox from "../suspensive-conditions/SuspensiveConditionsBox"
import TasksBox from "../tasks/TasksBox"

interface AgreementItemProps {
  agreement: TAgreement
  projectContext: boolean
}

export default function AgreementItem({
  agreement,
  projectContext,
}: AgreementItemProps) {
  const redirect = projectContext ? "project" : "operation"
  const matches = useMatches()
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const deleteAgreement = useDeleteAgreement(params.agreementId!)
  const queryClient = useQueryClient()

  const { isShowing: isModalShowed, toggle: toggleModal } = useDeleteModal()
  const onDeleteAgreement = async () => {
    await deleteAgreement.mutateAsync(params.agreementId!, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["getAgreementsByProjectId", +params.id!],
        })
        toast.success(`${t("agreements-delete-toast")}`)
      },
      onError: (err) => {
        toast.error(
          `${err.response?.data.error.message}, ${params.agreementId}`,
        )
      },
    })
    toggleModal()
    navigate(`/${redirect}/${params.id}/agreements`)
  }

  return (
    <>
      <DivStickyMenu />
      <QueryBoundaries loadingComponent={<Spinner />}>
        <div className="pb-4">
          <div className="px-6">
            <div className="flex flex-wrap items-center justify-between Padding__Bottom_24 Padding__Top_24 md:mt-0 mt-3">
              <div className="md:mb-0 mb-3">
                {matches
                  .filter((_, index) => index !== 0)
                  .map((match, index) =>
                    index === 0 ? (
                      <Link
                        className="Breadcrumb__Link"
                        id={`breadcrumb${-index}`}
                        to={`${match.pathname}/agreements`}
                        key={`${match.pathname}`}
                      >
                        {`${t("agreements-show-breadCrumb")}`}
                      </Link>
                    ) : (
                      <span
                        className="Breadcrumb__Active pl-2"
                        id={`breadcrumb${-index}`}
                        key={`${agreement.id}`}
                      >
                        {agreement?.name}
                      </span>
                    ),
                  )}
              </div>
              <div>
                <PermissionsGuard requiredRoles={["delete:agreement"]}>
                  <Button
                    onClick={toggleModal}
                    size="small"
                    mode="secondary"
                    classNames="Margin__Right_16"
                  >
                    {`${t("delete")}`}
                  </Button>
                </PermissionsGuard>
                <PermissionsGuard requiredRoles={["update:agreement"]}>
                  <Button
                    onClick={() =>
                      navigate(
                        `/${redirect}/${params.id}/agreements/${params.agreementId}/edit`,
                      )
                    }
                    size="small"
                    mode="primary"
                    isLoading={false}
                  >
                    {`${t("edit")}`}
                  </Button>
                </PermissionsGuard>
              </div>
            </div>
            <div className="lg:grid grid-rows-1 lg:grid-cols-1 gap-4 grid-flow-row-dense pb-8">
              <div className="lg:grid lg:grid-cols-3 grid-rows-1 gap-4">
                <Box classNames="py-4 px-6 lg:col-span-2 lg:row-span-1 md:mb-4 sm:mb-4 mb-4">
                  <Header classNames="pb-4 Color__Primary" size="h2">
                    {`${t("agreements-formSectionTitle-general")}`}
                  </Header>
                  <div className="grid grid-cols-3">
                    <div className="py-4 col-span-1">
                      <p className="label"> {`${t("entitled")}`} </p>
                      <p className="Color__Primary">{agreement?.name}</p>
                    </div>
                    <div className="py-4">
                      <p className="label"> {`${t("global-price")}`} </p>
                      <p className="Color__Primary">
                        {`${(
                          parseFloat(agreement?.cashPrice || "0") +
                          (agreement?.injonctionDeFaire || 0) +
                          (agreement?.dation || 0)
                        ).toLocaleString("fr-FR")}
                      €`}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-1 py-4">
                      <p className="label">{`${t("agreements-cashPrice")}`}</p>
                      <p className="Color__Primary">
                        {agreement?.cashPrice
                          ? `${parseFloat(agreement.cashPrice).toLocaleString(
                              "fr-FR",
                            )} €`
                          : "-"}
                      </p>
                    </div>
                    <div className="flex-1 py-4">
                      <p className="label">{`${t("agreements-gift")}`}</p>
                      <p className="Color__Primary">
                        {agreement?.dation
                          ? `${agreement.dation.toLocaleString("fr-FR")} €`
                          : "-"}
                      </p>
                    </div>
                    <div className="flex-1 py-4">
                      <p className="label">{`${t("agreements-mustDo")}`}</p>
                      <p className="Color__Primary">
                        {agreement?.injonctionDeFaire
                          ? `${agreement.injonctionDeFaire.toLocaleString(
                              "fr-FR",
                            )} €`
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="py-4 col-end-3">
                      <p className="label"> {`${t("details")}`} </p>
                      <p className="Color__Primary">
                        {agreement?.dationDetails}
                      </p>
                    </div>
                    <div className="py-4 col-end-4">
                      <p className="label"> {`${t("details")}`} </p>
                      <p className="Color__Primary">
                        {agreement?.injonctionDeFaireDetails}
                      </p>
                    </div>
                  </div>

                  <Header classNames="Color__Primary pb-4" size="h2">
                    {`${t("agreements-formSectionTitle-immoCompensation")}`}
                  </Header>
                  <div className="flex flex-wrap">
                    <div className="flex-1  py-4">
                      <p className="label">{`${t("amount")}`}</p>
                      <p id="immoCompensationPrice" className="Color__Primary">
                        {agreement?.immoCompensationPrice
                          ? `${parseFloat(
                              agreement.immoCompensationPrice,
                            ).toLocaleString("fr-FR")} €`
                          : "-"}
                      </p>
                    </div>
                    <div className="flex-1  py-4">
                      <p className="label">{`${t("status")}`}</p>
                      <p id="immoCompensationStatus" className="Color__Primary">
                        {agreement?.immoCompensationStatus}
                      </p>
                    </div>
                    <div className="flex-1 ml-4 md:ml-0 py-4">
                      <p className="label">{`${t("immobilisation-date")}`}</p>
                      {agreement?.immoCompensationDate ? (
                        <p id="immoCompensationDate" className="Color__Primary">
                          {format(
                            new Date(agreement.immoCompensationDate),
                            "dd/MM/yyyy",
                          )}
                        </p>
                      ) : (
                        <p className="Color__Primary">-</p>
                      )}
                    </div>
                  </div>
                </Box>

                <Box classNames="py-4 px-6 lg:col-span-1 lg:row-span-1 md:mb-4 sm:mb-4 mb-4">
                  <Header classNames="Color__Primary pb-4" size="h2">
                    {`${t("timing")}`}
                  </Header>
                  <div className="flex flex-col">
                    <div className="flex-1  py-4">
                      <p className="label">{`${t("signature-date")}`}</p>
                      {agreement?.signature ? (
                        <p id="signature" className="Color__Primary">
                          {format(new Date(agreement.signature), "dd/MM/yyyy")}
                        </p>
                      ) : (
                        <p className="Color__Primary">-</p>
                      )}
                    </div>
                    <div className="flex-1  py-4">
                      <p className="label">{`${t("term-date")}`}</p>
                      {agreement?.termDate ? (
                        <p id="termDate" className="Color__Primary">
                          {format(new Date(agreement.termDate), "dd/MM/yyyy")}
                        </p>
                      ) : (
                        <p className="Color__Primary">-</p>
                      )}
                    </div>
                  </div>
                </Box>
              </div>

              <PermissionsGuard requiredRoles={["read:plot"]}>
                <PlotBox routeName={redirect} />
              </PermissionsGuard>

              <PermissionsGuard
                requiredRoles={[
                  "read:default-suspensive-condition",
                  "read:suspensive-condition",
                ]}
              >
                <SuspensiveConditionsBox />
              </PermissionsGuard>

              <PermissionsGuard requiredRoles={["read:task"]}>
                <TasksBox />
              </PermissionsGuard>

              <PermissionsGuard requiredRoles={["read:amendment"]}>
                <AmendmentsBox />
              </PermissionsGuard>
            </div>
          </div>
          <PermissionsGuard requiredRoles={["delete:agreement"]}>
            <DeleteModal
              isShowing={isModalShowed}
              closeModal={toggleModal}
              title={`${t("agreements-delete-title")}`}
              deleteItem={() => onDeleteAgreement()}
              loading={deleteAgreement.isPending}
            >
              <FormatAgreementChildren agreementId={params.agreementId!} />
            </DeleteModal>
          </PermissionsGuard>
        </div>
      </QueryBoundaries>
    </>
  )
}
