import { Button } from "components"
import Box from "components/Box/Box"
import DisplayError from "components/Display/DisplayError"
import DisplayLoading from "components/Display/DisplayLoading"
import useModal from "components/Modal/useModal"
import { GroupsGuard } from "core/permissions/GroupsGuard"
import { useGetAdministrationData } from "core/query-hooks/useAdministrationData"
import { format } from "date-fns"
import { t } from "i18next"
import { GROUP } from "shared/resources/groups.resources"
import { EditReleaseNotes } from "./EditReleaseNotes"

export default function ReleaseNotes() {
  const { data, isLoading, error } = useGetAdministrationData()
  const { isShowing, toggle } = useModal()

  return (
    <div className="p-4">
      <div className="grid grid-cols-6">
        <h2 className="col-span-5 font-semibold md:mb-0 pb-4 Color__Primary">
          {t("last-releases")}
        </h2>
        <span className="col-span-1 text-sm text-gray-500 text-right">
          {data?.releaseDate
            ? `${t("updated-on")} : ${format(data?.releaseDate, "dd/MM/yyyy HH:mm")}`
            : null}
        </span>
      </div>
      <Box classNames="flex flex-col Container lg:mb-4">
        <span className="Color__Primary break-all whitespace-pre-line">
          {isLoading && !error ? (
            <DisplayLoading isLoading={isLoading} />
          ) : null}
          {error && !isLoading ? (
            <DisplayError errorMessage={t("data-error")} error={error} />
          ) : null}

          {data?.releaseNotes}
        </span>
      </Box>
      {data && (
        <GroupsGuard requiredGroups={[GROUP.ADMIN]}>
          <Button size="medium" mode="secondary" onClick={() => toggle()}>
            {`${t("edit")}`}
          </Button>
          <EditReleaseNotes
            isModalShowed={isShowing}
            toggleModalReleaseNotes={toggle}
            release={data}
          />
        </GroupsGuard>
      )}
    </div>
  )
}
